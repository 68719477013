import { Segment } from "semantic-ui-react";

import React from "react";
import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../authorization/hooks/useHasRight";

import ProductionArticlesView from "../views/ProductionArticlesView/ProductionArticlesView";

import ProductionAudiobook from "./ProductionAudiobook";

export default function ProductionArticles() {
	const { production } = useOutletContext();

	const hasRight = useHasRight();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!production?.articleIds) {
		return (
			<Segment padded>
				<ProductionAudiobook tab="articles" />
			</Segment>
		);
	}

	if (!hasRight("productions.articles") || !hasFlatteningFeature) {
		return (
			<Segment basic padded>
				<Unauthorized />
			</Segment>
		);
	}

	return <ProductionArticlesView production={production} />;
}
