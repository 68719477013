import { Form } from "semantic-ui-react";

import React from "react";
import { PatternFormat } from "react-number-format";

import durationToSeconds from "astrid-firestore/src/api/articles/utils/durationToSeconds";
import secondsToDuration from "astrid-firestore/src/api/articles/utils/secondsToDuration";

import useFormField from "../../hooks/useFormField";

export default function FormDurationInput(props) {
	const { locked, field } = useFormField(props);

	const value = secondsToDuration(field.value || 0);

	return (
		<Form.Input {...field} ref={undefined}>
			<PatternFormat
				value={value}
				onValueChange={({ formattedValue }) => {
					field.onChange(null, {
						value: durationToSeconds(formattedValue.replaceAll("_", "0")),
					});
				}}
				format={locked ? value : "###:##:##"}
				allowEmptyFormatting
				mask="_"
			/>
		</Form.Input>
	);
}
