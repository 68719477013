import { Menu } from "semantic-ui-react";

import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import MenuItemContent from "./MenuItemContent";

export default function MenuItems({ options }) {
	return options.filter(({ hidden }) => !hidden).map(
		(
			{ to, icon, text, content, active, options, children, loading, label, labelColor, onClick, ...option },
			index,
		) => (
			<Fragment key={index}>
				<Menu.Item
					key={index}
					as={to !== undefined ? Link : undefined}
					to={to}
					active={active}
					className="menu-item"
					onClick={onClick}
					style={{ whiteSpace: "nowrap", flexShrink: 0 }}
					{...option}
				>
					<MenuItemContent
						icon={icon}
						text={text}
						content={content}
						loading={loading}
						label={label}
						labelColor={labelColor}
					>
						{children}
					</MenuItemContent>
				</Menu.Item>

				{options && active && (
					<Menu.Menu style={{ marginBottom: 10 }}>
						<MenuItems options={options} />
					</Menu.Menu>
				)}
			</Fragment>
		),
	);
}
