import React from "react";

import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";

export default function DiscussionChannelUserImages({ users }) {
	return users.slice(0, 2).map(({ id, img }, index, users) => (
		<ImageAvatar
			src={img}
			key={id}
			style={{
				height: users.length === 1 ? 40 : 30,
				width: users.length === 1 ? 40 : 30,
				marginLeft: index === 0 ? 0 : -30,
				marginTop: users.length > 1 && (index === 0 ? -10 : 10),
				border: "2px solid #fff",
			}}
		/>
	));
}
