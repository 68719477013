import { useNavigate } from "react-router-dom";

import api from "../../../../api";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useImprintsTableColumns from "../../hooks/useImprintsTableColumns";

export default function ImprintsCollectionDataTable({ publisher, ...props }) {
	const navigate = useNavigate();

	const columns = useImprintsTableColumns();

	const query = api.imprints.getQuery({
		publisher,
	});

	return (
		<CollectionDataTable
			filterable={false}
			query={query}
			columns={columns}
			onClick={({ id }) => navigate(id)}
			sortBy={{
				id: "created",
				desc: true,
			}}
			searchableKeys={["name"]}
			{...props}
		/>
	);
}
