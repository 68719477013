import useEvent from "react-use/lib/useEvent";
import { useState, useEffect, useCallback } from "react";

export default function useBoundingClientRect(ref) {
	const [rect, setRect] = useState({ top: 0, left: 0, width: 0, height: 0 });

	const onResize = useCallback(() => {
		if (ref && ref.current) {
			const { top, left, width, height } = ref.current.getBoundingClientRect();

			setRect({ top, left, width, height });
		}
	}, [ref]);

	useEffect(() => {
		onResize();
	}, [onResize]);

	useEvent("resize", onResize);

	return rect;
}
