import { Header, Icon } from "semantic-ui-react";

import React from "react";

import Flex from "../../../../../components/Flex/Flex";

import DiscussionChannelDropdownMenu from "../../../components/DiscussionChannelDropdownMenu/DiscussionChannelDropdownMenu";
import { setDiscussion } from "../../../state/discussion";

import DiscussionChannelParticipants from "./DiscussionChannelParticipiants";

export default function DiscussionChannelHeader({ channel }) {
	return (
		<Flex style={{ padding: 15 }}>
			<Flex justifyContent="flex-start" gap={10}>
				<div>
					<Icon
						name="list ul"
						link
						disabled={channel === null}
						style={{ margin: 0 }}
						onClick={() => {
							setDiscussion(({ productionId }) => ({ isOpen: true, channelId: null, productionId }));
						}}
					/>
				</div>

				<Header as="h4" style={{ margin: 0 }}>
					{channel.name}
				</Header>

				<DiscussionChannelParticipants channel={channel} />
			</Flex>

			<DiscussionChannelDropdownMenu channel={channel} />
		</Flex>
	);
}
