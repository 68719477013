import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import User from "../pages/User";
import Users from "../pages/Users";

export const users = (
	<Route path="users" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("users", "Users") }}>
		<Route index element={<Users />} />
		<Route path=":id" element={<User />} handle={{ crumb: ({ user }) => `${user?.firstName} ${user?.lastName}` }} />
	</Route>
);
