import { Menu as SemanticMenu } from "semantic-ui-react";

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import useWindowSize from "react-use/lib/useWindowSize";

import Flex from "../../../../components/Flex/Flex";

import DropdownMenu from "../DropdownMenu/DropdownMenu";

import MenuItems from "./components/MenuItems";

export default function Menu({ options, dropdown, dropdownDirection = "left", children, style, ...props }) {
	const ref = useRef();
	const { width } = useWindowSize();

	const filteredOptions = useMemo(() => options.filter(({ hidden, disabled }) => !hidden && !disabled), [options]);

	const [mainOptions, setMainOptions] = useState(filteredOptions);
	const [secondaryOptions, setSecondaryOptions] = useState([]);

	useEffect(() => {
		setMainOptions(filteredOptions);
		setSecondaryOptions([]);
	}, [filteredOptions, width]);

	useLayoutEffect(() => {
		if (dropdown) {
			const nodes = [...ref.current.querySelectorAll(".menu-item")];
			const { width } = ref.current.getBoundingClientRect();

			let remainingWidth = width - 15;
			let max = 0;

			nodes.forEach((node) => {
				const { width } = node.getBoundingClientRect();

				remainingWidth -= width + 5;

				if (remainingWidth > 0) {
					max = nodes.indexOf(node) + 1;
				}
			});

			if (mainOptions.length !== max) {
				setMainOptions(filteredOptions.slice(0, max));
				setSecondaryOptions(filteredOptions.slice(max));
			}
		}
	}, [dropdown, filteredOptions, mainOptions.length, width]);

	return (
		<div ref={ref} style={style}>
			<Flex justifyContent="flex-start" alignItems="flex-start" style={{ height: "100%" }}>
				<SemanticMenu {...props} icon={!!options?.[0]?.icon} style={{ margin: 0 }}>
					{children || <MenuItems options={mainOptions} />}
				</SemanticMenu>

				{secondaryOptions.length > 0 && (
					<DropdownMenu
						fitted
						direction={dropdownDirection}
						style={{ padding: 10 }}
						options={secondaryOptions}
					/>
				)}
			</Flex>
		</div>
	);
}

Menu.Item = SemanticMenu.Item;
