import CreateArticleBundleFromArticlesButton from "../CreateArticleBundleForm/CreateArticleBundleFromArticlesButton";
import DistributeArticlesButton from "../DistributeArticles/DistributeArticlesButton";

export default function ArticlesTableFooter({
	publisher,
	selectedFlatRows,
	toggleAllRowsSelected,
	showDistributeButton,
	showArticleBundleButton,
}) {
	const selectedArticles = selectedFlatRows?.map(({ original }) => original);

	const onSuccess = () => {
		toggleAllRowsSelected(false);
	};

	return (
		<>
			{showArticleBundleButton && <CreateArticleBundleFromArticlesButton articles={selectedArticles} />}

			{showDistributeButton && (
				<DistributeArticlesButton articles={selectedArticles} publisher={publisher} onSuccess={onSuccess} />
			)}
		</>
	);
}
