import { Segment } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import ProductionComments from "../../../../../components/production/parts/ProductionComments";
import { useProfile } from "../../../../authentication/state/profile";
import Menu from "../../../../ui/components/Menu/Menu";

export default function ProductionDiscussionOld({ production, permissions }) {
	const { t } = useTranslation();

	const user = useProfile();
	const location = useLocation();

	const { pathname } = location;

	const internalPublisher = pathname.endsWith("internal");

	return (
		<>
			<Menu
				secondary
				options={[
					{
						to: "",
						active: pathname.endsWith("discussions"),
						hidden: !production.producer,
						text: t("discussion"),
					},
					{
						to: "internal",
						active: pathname.endsWith("internal"),
						hidden: !user?.permissions?.publisher?.[production.publisher],
						text: t("internal"),
					},
				]}
			/>

			<Segment style={{ padding: 0 }}>
				<div className="scroll-fade">
					<ProductionComments
						key={internalPublisher}
						production={production}
						subcontractor={production.subcontractor}
						internalPublisher={internalPublisher}
						productionId={production.id}
						isPublisher={permissions?.isPublisher}
						productionFields={[
							"title",
							"producer",
							"publisher",
							"publisherManagerIds",
							"language",
							"reader",
							"readerStatus",
							"bookedRecorders",
							"manager",
							"proofer",
							"editor",
							"series",
							"lastCommentPublisher",
							"adminAttention",
						]}
					/>
				</div>
			</Segment>
		</>
	);
}
