import { StorageFile } from "../../../types/StorageFile";
import { array, boolean, int, isbn, nullable, number, object, record, string } from "../../../types/_types";

import { ImprintRef } from "../../imprints/types/ImprintRef";
import { Season } from "../../series/types/Season";
import { SerieRef } from "../../series/types/Serie";

import { ArticleChannelMetaData } from "./ArticleChannel";
import { ArticleContributor } from "./ArticleContributor";
import { ArticleDistribution } from "./ArticleDistribution";
import { ArticlePages } from "./ArticlePages";
import { ArticleRef } from "./ArticleRef";

export const ArticleMetaData = object({
	abridged: boolean().default(false),
	additionalLanguages: array(string()),
	author: nullable(string()),
	channels: record(string(20), ArticleChannelMetaData.partial()).default({}),
	contributors: array(ArticleContributor),
	cover: nullable(StorageFile.extend({ thumbUrl: nullable(string()) })),
	duration: number().default(0),
	distribution: ArticleDistribution,
	imprint: nullable(ImprintRef),
	isbn: isbn(),
	language: string(),
	linkedArticle: nullable(ArticleRef),
	name: string(3),
	subtitle: nullable(string()),
	narrator: nullable(string()),
	originalLanguage: nullable(string()),
	originalName: nullable(string()),
	pages: nullable(ArticlePages),
	serie: nullable(SerieRef),
	season: nullable(Season),
	serieNumber: nullable(int()),
	synopsis: nullable(string()),
	tags: array(string()),
	themaCodes: array(string()),
	translator: nullable(string()),
});
