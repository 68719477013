import { Loader, Modal } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

export default function UploadingArtifactModal() {
	const { t } = useTranslation();
	return (
		<Modal open size="tiny">
			<Modal.Header content={t("pleaseWaitWhileWeUpload", "Please wait while we upload the files...")} />

			<Modal.Content>
				<Loader active inline="centered" />
			</Modal.Content>
		</Modal>
	);
}
