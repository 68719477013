import { useTranslation } from "react-i18next";

import audioBitrates from "astrid-distribution/src/constants/audioBitrates";
import audioFormats from "astrid-distribution/src/constants/audioFormats";
import { firebase } from "astrid-firebase";
import { Export } from "astrid-firestore/src/api/exports/types/Export";

import api from "../../../../api";

import audioSplit from "../../../articles/views/AdminArticleView/utils/constants/audioSplit";
import rename from "../../../articles/views/AdminArticleView/utils/constants/rename";
import getExportPipeline from "../../../articles/views/AdminArticleView/utils/getExportPipeline";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

const audioFormatOptions = Object.values(audioFormats).map((audioFormat) => ({
	key: audioFormat,
	value: audioFormat,
	text: audioFormat,
}));

const bitrateOptions = Object.values(audioBitrates).map((bitrate) => ({
	key: bitrate,
	value: bitrate,
	text: bitrate,
}));

const splitOptions = Object.values(audioSplit).map((split) => ({
	key: split,
	value: split,
	text: split,
}));

const renameOptions = Object.values(rename).map((split) => ({
	key: split,
	value: split,
	text: split,
}));

export default function CreateExportForm({ article, onClose }) {
	const { t } = useTranslation();
	const { form, onSubmit, error } = useSimpleForm({
		schema: Export,
		defaultValues: {
			audioFormat: audioFormats.WAV,
			bitrate: audioBitrates["192K"], // only used for mp3.
			splitAudio: audioSplit.AS_IS,
			rename: rename.ISBN_SEQ,
			zip: false,
			includeCover: true,
		},
		onSubmit: async (data) => {
			const now = new Date();
			const oneMonthLater = new Date(now.setMonth(now.getMonth() + 1));
			await api.exports.create({
				article,
				expires: firebase.firestore.Timestamp.fromDate(oneMonthLater),
				pipeline: getExportPipeline({ data }),
			});
			onClose();
		},
	});

	const { watch } = form;
	const audioFormat = watch("audioFormat");

	return (
		<Form.Modal
			size="tiny"
			header={t("createExport", "Create export")}
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			<Form.Select name="audioFormat" label={t("audioFormat", "Audio format")} options={audioFormatOptions} />
			{audioFormat === audioFormats.MP3 && (
				<Form.Select name="bitrate" label={t("bitrate", "Bitrate")} options={bitrateOptions} />
			)}
			<Form.Select name="splitAudio" label={t("splitAudio", "Split audio")} options={splitOptions} />
			<Form.Select name="rename" label={t("nameAudio", "Name audio")} options={renameOptions} />
			<Form.Checkbox name="zip" label={t("zipAudio", "Zip audio")} />
			<Form.Checkbox name="includeCover" label={t("includeCover", "Include cover")} />
		</Form.Modal>
	);
}
