import firebase from "firebase/app";

import { Label } from "semantic-ui-react";

import { useEffect } from "react";

import { useProfile } from "../../../../authentication/state/profile";

export default function CommentUnseen({ comment, inViewport }) {
	const user = useProfile();

	const { seenByUserIds, ref } = comment;

	const seen = seenByUserIds?.includes(user.id);

	useEffect(() => {
		if (!seen && inViewport) {
			const timeout = setTimeout(() => {
				ref.update({
					seenByUserIds: firebase.firestore.FieldValue.arrayUnion(user.id),
				});
			}, 1500);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [inViewport, ref, seen, user.id]);

	if (seen) {
		return null;
	}

	return (
		<Label
			style={{
				position: "absolute",
				left: 28,
				boxShadow: "0 0 0 2px white",
			}}
			circular
			empty
			color="blue"
		/>
	);
}
