import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import featureFlags from "../../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import shouldEnableInvoices from "../../../../invoices/utils/shouldEnableInvoices";

export default function useAdminMenuOptions({ organization }) {
	const { t } = useTranslation();

	const hasRight = useHasRight();
	const location = useLocation();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const { pathname } = location;

	if (organization?.type === organizationTypes.PRODUCER) {
		return [
			// {
			// 	path: "productions",
			// 	name: t("productions", "Productions"),
			// 	hidden: !hasRight("productions.admin"),
			// 	options: [
			// 		{
			// 			path: "productions/all",
			// 			name: t("allProductions", "All Productions"),
			// 		},
			// 		{
			// 			path: "productions/drafts",
			// 			name: t("drafts", "Drafts"),
			// 		},
			// 		{
			// 			path: "productions/requests",
			// 			name: t("myProductionsRequests", "Requests"),
			// 		},
			// 		{
			// 			path: "productions/ongoing",
			// 			name: t("ongoing", "Ongoing"),
			// 		},
			// 		{
			// 			path: "productions/completed",
			// 			name: t("completedPlural", "Completed"),
			// 		},
			// 		{
			// 			path: "productions/unfinalized",
			// 			name: t("unfinalized", "Unfinalized"),
			// 		},
			// 	],
			// },
			{
				path: "publishers",
				name: t("publishers", "Publishers"),
				hidden: !hasRight("publishers.view"),
			},
			// {
			// 	path: "readers",
			// 	name: t("readers", "Narrators"),
			// 	hidden: !hasRight("users.view"),
			// },
			{
				path: "expenses",
				name: t("expenses", "Expenses"),
				hidden: !hasRight("costs.view"),
				options: [
					{
						path: "expenses",
						name: t("unprocessedExpenses", "Unprocessed expenses"),
					},
					{
						path: "purchases",
						name: t("POs"),
						hidden: !hasRight("orders.view"),
					},
					{
						path: "orders/PO",
						hidden: true,
					},
				],
			},
			{
				path: "revenues",
				name: t("revenues", "Revenues"),
				hidden: !hasRight("costs.view"),
				options: [
					{
						path: "revenues",
						name: t("unprocessedRevenues", "Unprocessed revenues"),
					},
					{
						path: "sales",
						name: t("salesOrders", "Sales orders"),
						hidden: !hasRight("orders.view"),
					},
					{
						path: "orders/SO",
						hidden: true,
					},
				],
			},
			{
				path: "invoices",
				name: t("invoices", "Invoices"),
				hidden: !hasRight("producerBilling.createPublisherInvoice") || !shouldEnableInvoices(organization.id),
			},
			{
				path: "prices",
				name: t("globalPrices", "Global prices"),
				hidden: !hasRight("prices.view"),
			},
			{
				path: "agreements",
				name: t("agreements", "Agreements"),
				hidden: !hasRight("agreements.view"),
				options: [
					{
						path: "agreements",
						name: t("pendingAgreements", "Pending agreements"),
					},
					{
						path: "agreements-expiring",
						name: t("expiringAgreements", "Expiring agreements"),
					},
					{
						path: "agreements-templates",
						name: t("agreementTemplates", "Agreement templates"),
					},
					{
						path: "agreements-prepare",
						hidden: !hasRight("agreements.create"),
						name: t("prepareAgreements", "Prepare agreements"),
					},
				],
			},
			{
				path: "settings/language",
				name: t("settings", "Settings"),
				hidden: !hasRight("settings.view"),
				options: [
					{
						path: "settings/vendors",
						name: t("vendorSettings"),
					},
					{
						path: "settings/language",
						name: t("language", "Language"),
					},
					{
						path: "settings/finance",
						name: t("finance", "Finance"),
					},
					{
						path: "settings/studio",
						name: t("studio", "Studio"),
					},
				],
			},
		];
	}

	if (organization?.type === organizationTypes.PUBLISHER) {
		return [
			{
				path: "articles",
				name: t("articles", "Articles"),
				hidden: !hasRight("articles.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
				active: pathname.includes("admin/articles"),
				options: [
					{
						path: "articles/all",
						active: pathname.endsWith("admin/articles/all"),
						name: t("allArticles", "All articles"),
					},
					{
						path: "articles/drafts",
						name: t("drafts", "Drafts"),
					},
					{
						path: "articles/deliveryNeeded",
						name: t("deliveryNeeded", "Delivery needed"),
					},
					{
						path: "articles/delivered",
						name: t("deliveredPlural", "Delivered"),
					},
					{
						path: "articles/scheduled",
						name: t("scheduledPlural", "Scheduled"),
					},
					{
						path: "articles/notDelivered",
						name: t("notDeliveredPlural", "Not delivered"),
					},
					{
						path: "articles/bundles",
						name: t("bundles", "Bundles"),
					},
					{
						path: "articles/artifacts",
						name: t("artifacts", "Artifacts"),
						active: pathname.includes("admin/articles/artifacts"),
						hidden: !hasRight("artifacts.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
					},
				],
			},
			{
				path: "series",
				name: t("serie", "Series"),
				active: pathname.includes("admin/series"),
				hidden: !hasFlatteningFeature, // TODO: Remove feature flag check
			},
			{
				path: "imprints",
				name: t("imprints", "Imprints"),
				active: pathname.includes("admin/imprints"),
				hidden: !hasFlatteningFeature, // TODO: Remove feature flag check
			},
			{
				path: "scripts",
				name: t("scripts", "Scripts"),
				active: pathname.includes("admin/scripts"),
				hidden: !hasRight("scripts.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
			},
			{
				path: "articles/artifacts",
				name: t("artifacts", "Artifacts"),
				active: pathname.includes("admin/articles/artifacts"),
				hidden: !hasRight("artifacts.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
			},
			{
				path: "settings",
				name: t("settings", "Settings"),
				hidden: !hasRight("settings.view"),
				options: [
					{
						path: "settings/vendors",
						name: t("vendorSettings"),
					},
					{
						path: "settings/distribution",
						name: t("distribution", "Distribution"),
						hidden: !hasFlatteningFeature,
					},
					{
						path: "settings/finance",
						name: t("finance", "Finance"),
					},
					{
						path: "settings/language",
						name: t("language", "Language"),
					},
				],
			},
		];
	}

	return [];
}
