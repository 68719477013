import { useMemo } from "react";

import distributionPriceTypes from "astrid-firestore/src/api/distribution/constants/distributionPriceTypes";

import useDistributionPriceTypeTranslations from "./useDistributionPriceTypeTranslations";

export default function useDistributionPriceTypeOptions() {
	const priceTypeTranslations = useDistributionPriceTypeTranslations();

	return useMemo(() => {
		return Object.values(distributionPriceTypes).map((priceType) => ({
			key: priceType,
			value: priceType,
			text: priceTypeTranslations[priceType],
		}));
	}, [priceTypeTranslations]);
}
