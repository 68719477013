import React from "react";

import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";
import Popup from "../../../../ui/components/Popup/Popup";

export default function DiscussionChannelParticipants({ channel }) {
	return (
		<div style={{ marginTop: "-2px" }}>
			{channel.users?.map(({ id, name, img }, index) => {
				return (
					<Popup
						direction="bottom"
						key={id}
						content={name}
						trigger={
							<ImageAvatar
								src={img}
								style={{
									height: 22,
									width: 22,
									marginLeft: index === 0 ? 0 : -10,
									border: "2px solid #fff",
								}}
							/>
						}
					/>
				);
			})}
		</div>
	);
}
