import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import PageView from "../../../ui/views/PageView/PageView";

import useOrganizationSettingsMenuOptions from "./hooks/useOrganizationSettingsMenuOptions";

export default function OrganizationSettingsView({ organization, children }) {
	const options = useOrganizationSettingsMenuOptions({ organization });

	return (
		<PageView header={organization.name} sidebar={<VerticalMenu options={options} />}>
			{children}
		</PageView>
	);
}
