import React from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import { db } from "astrid-firebase";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import featureFlags from "../../../authorization/constants/featureFlags";

import AdminSerieView from "../../views/AdminSerieView/AdminSerieView";

export default function AdminSerie() {
	const params = useParams();
	const { organization } = useOutletContext();

	const [serie, loading, error] = useDocumentData(db.collection("series").doc(params.serie));

	return (
		<LoadingContext data={serie} loading={loading} error={error} featureFlag={featureFlags.FLATTENING}>
			<AdminSerieView organization={organization} serie={serie}>
				<Outlet context={{ organization, serie }} />
			</AdminSerieView>
		</LoadingContext>
	);
}
