import { Icon } from "semantic-ui-react";

import React from "react";

import stopPropagation from "astrid-helpers/src/stopPropagation";

import Flex from "../../../../../components/Flex/Flex";
import Form from "../../../../forms/components/Form/Form";
import Accordion from "../../../../ui/components/Accordion/Accordion";

export default function PipelineStepTitle({
	name,
	index,
	active,
	error,
	onClick,
	onRemove,
	onReorder,
	onDisabled,
	onDuplicate,
}) {
	const { watch } = Form.useFormContext();

	const errorMessage = error && {
		content: error.name?.message || error.root?.message || error.message || "Invalid",
		pointing: "left",
	};

	const onClickDisabled = (e) => {
		stopPropagation(e);
		onDisabled(index);
	};

	const onClickRemove = (e) => {
		stopPropagation(e);
		onRemove(index);
	};

	const onClickMoveUp = (e) => {
		stopPropagation(e);
		onReorder(index, index - 1);
	};

	const onClickMoveDown = (e) => {
		stopPropagation(e);
		onReorder(index, index + 1);
	};

	const onClickDuplicate = (e) => {
		stopPropagation(e);
		onDuplicate(index);
	};

	const disabled = watch(`${name}.disabled`);
	const steps = watch(`${name}.steps`);

	return (
		<Accordion.Title active={active} onClick={() => onClick(index)}>
			<Flex>
				<div>
					<Form.Input
						inline
						transparent
						name={`${name}.name`}
						shouldUnregister={false}
						onClick={stopPropagation}
						style={{ minWidth: "500px", marginTop: -5 }}
						error={errorMessage}
					/>
				</div>

				<div>
					<Flex gap={10}>
						<div onClick={onClickDisabled}>
							<Icon link name="plug" color={disabled ? "red" : "green"} />
						</div>
						<div onClick={onClickDuplicate}>
							<Icon link name="copy outline" />
						</div>

						<div onClick={onClickMoveUp}>
							<Icon link name="arrow up" />
						</div>

						<div onClick={onClickMoveDown}>
							<Icon link name="arrow down" />
						</div>

						<div onClick={onClickRemove}>
							<Icon link name="trash" />
						</div>
					</Flex>
				</div>
			</Flex>
		</Accordion.Title>
	);
}
