import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import { useProfile } from "../../../../authentication/state/profile";
import featureFlags from "../../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import { useOrganizationId } from "../../../../organizations/state/organizationId";
import { useOrganizationType } from "../../../../organizations/state/organizationType";

export default function useMainMenuOptions() {
	const { t } = useTranslation();

	const user = useProfile();
	const orgId = useOrganizationId();
	const orgType = useOrganizationType();

	const hasRight = useHasRight();
	const location = useLocation();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const { pathname } = location;

	if (pathname.startsWith("/account")) {
		return [
			{
				to: `/account/${user?.id}/settings`,
				text: t("settings", "Settings"),
				active: pathname.includes("settings"),
			},
			{
				to: `/account/${user?.id}/agreements`,
				text: t("agreements", "Agreements"),
				active: pathname.includes("agreements"),
			},
			{
				to: `/account/${user?.id}/revenues`,
				text: t("revenues", "Revenues"),
				active: pathname.includes("revenues"),
			},
			{
				to: `/account/${user?.id}/orders`,
				text: t("POs"),
				active: pathname.includes("orders"),
			},
		];
	}

	if (orgType === organizationTypes.ADMIN) {
		return [
			{
				to: "/admin",
				text: t("overview", "Overview"),
				active: pathname === "/admin",
			},
			{
				to: "/admin/approve/costs",
				text: t("needsApproval", "Needs approval"),
				active: pathname.startsWith("/admin/approve"),
			},
			{
				to: "/admin/producers",
				text: t("producers", "Producers"),
				active: pathname.startsWith("/admin/producers"),
			},
			{
				to: "/admin/publishers",
				text: t("publishers", "Publishers"),
				active: pathname.startsWith("/admin/publishers"),
			},
			{
				to: "/admin/users",
				text: t("users", "Users"),
				active: pathname.startsWith("/admin/users"),
			},
			{
				to: "/admin/articles",
				text: t("articles", "Articles"),
				active: pathname.startsWith("/admin/articles"),
				hidden: !hasFlatteningFeature,
			},
			{
				to: `/admin/distributions`,
				text: t("distributions", "Distributions"),
				active: pathname.startsWith("/admin/distributions"),
				hidden: !hasFlatteningFeature,
			},
			{
				to: "/admin/settings/channels",
				text: t("settings", "Settings"),
				active: pathname.startsWith("/admin/settings"),
			},
		];
	}

	if (orgType === organizationTypes.PRODUCER) {
		return [
			{
				to: `/${orgType}/${orgId}`,
				text: t("overview", "Overview"),
				active: pathname.endsWith(`/${orgType}/${orgId}`),
			},
			{
				to: `/${orgType}/${orgId}/create-production`,
				text: t("createProduction", "Create production"),
				active: pathname.includes("create-production"),
				hidden: !hasRight("createProduction"),
			},
			{
				to: `/${orgType}/${orgId}/my-productions/current`,
				text: t("myProductions", "My productions"),
				active: pathname.includes("my-productions"),
			},
			{
				to: `/${orgType}/${orgId}/list-productions`,
				text: t("productions", "Productions"),
				active: pathname.includes("list-productions"),
				hidden: !hasRight("list-productions.beta"),
			},
			{
				to: `/${orgType}/${orgId}/calendar`,
				text: t("calendar", "Calendar"),
				active: pathname.includes("/calendar"),
			},
			{
				to: `/${orgType}/${orgId}/documents`,
				text: t("documents", "Documents"),
				active: pathname.includes("/documents"),
			},
			{
				to: `/${orgType}/${orgId}/statistics`,
				text: t("statistics"),
				active: pathname.includes("/statistics"),
				hidden: !hasRight("viewProductionStatistics"),
			},
			{
				to: `/${orgType}/${orgId}/users`,
				text: t("users", "Users"),
				active: pathname.includes("/users"),
			},
			{
				to: `/${orgType}/${orgId}/studios`,
				text: t("studios", "Studios"),
				active: pathname.includes("/studios"),
			},
			{
				to: `/${orgType}/${orgId}/admin/settings/vendors`,
				text: t("admin", "Admin"),
				hidden: !hasRight("admin"),
				active: pathname.includes("admin"),
			},
		];
	}

	if (orgType === organizationTypes.PUBLISHER) {
		return [
			{
				to: `/${orgType}/${orgId}`,
				text: t("overview", "Overview"),
				active: pathname.endsWith(`/${orgType}/${orgId}`),
			},
			{
				to: `/${orgType}/${orgId}/create-production`,
				text: t("createProduction", "Create production"),
				active: pathname.includes("create-production"),
			},
			{
				to: `/${orgType}/${orgId}/my-productions/current`,
				text: t("myProductions", "My productions"),
				active: pathname.includes("my-productions"),
			},
			{
				to: `/${orgType}/${orgId}/articles`,
				text: t("articles", "Articles"),
				active: pathname.includes("/articles"),
				hidden: !hasFlatteningFeature,
			},
			{
				to: `/${orgType}/${orgId}/list-productions`,
				text: t("productions", "Productions"),
				active: pathname.includes("list-productions"),
			},
			{
				to: `/${orgType}/${orgId}/distributions`,
				text: t("distributions", "Distributions"),
				active: pathname.includes(`/${orgType}/${orgId}/distributions`),
				hidden: !hasFlatteningFeature,
			},
			{
				to: `/${orgType}/${orgId}/users`,
				text: t("users", "Users"),
				active: pathname.includes("/users"),
			},
			{
				to: `/${orgType}/${orgId}/imprints`,
				text: t("imprints", "Imprints"),
				active: pathname.startsWith(`/${orgType}/${orgId}/imprints`),
			},
			{
				to: `/${orgType}/${orgId}/series`,
				text: t("series", "Series"),
				active: pathname.startsWith(`/${orgType}/${orgId}/series`),
			},
			{
				to: `/${orgType}/${orgId}/settings/vendors`,
				text: t("settings", "Settings"),
				hidden: !hasRight("admin"),
				active: pathname.startsWith(`/${orgType}/${orgId}/settings`),
			},
		];
	}

	if (orgType === organizationTypes.NARRATOR) {
		return [
			{
				to: `/${orgType}/${orgId}`,
				text: t("overview", "Overview"),
				active: pathname.endsWith(`/${orgType}/${orgId}`),
			},
			{
				to: `/${orgType}/${orgId}/my-productions/current`,
				text: t("myProductions", "My productions"),
				active: pathname.includes("my-productions"),
			},
			{
				to: `/${orgType}/${orgId}/calendar`,
				text: t("calendar", "Calendar"),
				active: pathname.includes("/calendar"),
			},
		];
	}

	return [];
}
