import { useParams } from "react-router-dom";

import db from "astrid-firebase/src/db";
import { useDocumentData } from "astrid-firestore/src/hooks";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminStudioView from "../views/AdminStudioView/AdminStudioView";

export default function AdminStudio() {
	const { id } = useParams();

	const hasRight = useHasRight();

	const [studio, loading, error] = useDocumentData(db.collection("studios").doc(id));

	return (
		<LoadingContext data={studio} loading={loading} error={error} authorized={hasRight("studios.view")}>
			<AdminStudioView studio={studio} />
		</LoadingContext>
	);
}
