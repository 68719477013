import React from "react";
import { useTranslation } from "react-i18next";

import Trash from "astrid-components/lib/components/Assets/Icons/Trash";
import List from "astrid-components/lib/components/Data/List";
import Button from "astrid-components/lib/components/Inputs/Button";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Text from "astrid-components/lib/components/Text/Text";
import msToTime from "astrid-helpers/src/msToTime";

export default function ArtifactAudioPlayerChapter({ marker, active, index, first, last, onClick, onRemove }) {
	const { t } = useTranslation();

	return (
		<List.Item active={active} first={first} last={last} onClick={onClick}>
			<Flex cursor="pointer" padding={10}>
				<div>
					<Text fontSize={12} lineHeight={1.2} color="positive">
						{msToTime(marker.position, false)}
					</Text>

					<Text fontSize={14} fontWeight={600}>
						{t("chapter", "Chapter")} {index + 1}
					</Text>
				</div>

				<Button
					transparent
					size="small"
					color="negative"
					onClick={(e) => {
						e.stopPropagation();
						onRemove();
					}}
				>
					<Trash size={12} />
				</Button>
			</Flex>
		</List.Item>
	);
}
