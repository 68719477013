import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useUserOrganizationTypeTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			unassigned: t("unassigned", "Unassigned"),
			narrator: t("narrators", "Narrators"),
			producer: t("producers", "Producers"),
			publisher: t("publishers", "Publishers"),
		}),
		[t],
	);
}
