import SuperAdminDistributionChannelForm from "./components/SuperAdminDistributionChannelForm";
import SuperAdminDistributionChannelMetaDataTemplates from "./components/SuperAdminDistributionChannelMetaDataTemplates";
import SuperAdminDistributionSubChannelsForm from "./components/SuperAdminDistributionSubChannelsForm";

export default function SuperAdminDistributionChannelSettingsView({ channel }) {
	return (
		<>
			<SuperAdminDistributionChannelForm channel={channel} />
			<SuperAdminDistributionChannelMetaDataTemplates channel={channel} />
			<SuperAdminDistributionSubChannelsForm channel={channel} />
		</>
	);
}
