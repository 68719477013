import { useTranslation } from "react-i18next";

import usePipelineStatusTranslations from "./usePipelineStatusTranslations";

export default function useDistributionsRefinements({ publisher }) {
	const { t } = useTranslation();

	const pipelineStatusTranslations = usePipelineStatusTranslations();

	return [
		{
			header: t("status", "Status"),
			attribute: "status",
			renderItem: ({ label }) => pipelineStatusTranslations[label],
		},
		{
			header: t("channel", "Channel"),
			attribute: "channel.name",
			searchable: true,
		},
		!publisher && {
			header: t("publisher", "Publisher"),
			attribute: "publisher.name",
			searchable: true,
		},
	].filter(Boolean);
}
