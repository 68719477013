import useBreadcrumbs from "../../../ui/hooks/useBreadcrumbs";
import PageView from "../../../ui/views/PageView/PageView";

export default function AdminImprintView({ imprint, children }) {
	const breadcrumbs = useBreadcrumbs({ imprint });

	return (
		<PageView breadcrumbs={breadcrumbs} header={imprint.name}>
			{children}
		</PageView>
	);
}
