import { Form, Menu } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { useRange } from "react-instantsearch";

import DateInput from "../DateInput/DateInput";

export default function InstantSearchRefinementDateRange({ attribute }) {
	const { t } = useTranslation();

	const { refine, canRefine, start, range } = useRange({ attribute });

	const [from, to] = start;
	const { min, max } = range;

	return (
		<>
			<Menu.Item disabled={!canRefine}>
				<Form>
					<DateInput
						size="small"
						value={from > -Infinity ? new Date(from * 1000) : null}
						range={[new Date(min * 1000), new Date(max * 1000)]}
						placeholder={t("fromDate", "From date")}
						closeOnSelect
						onChange={(e, { value }) => {
							refine([value ? value.getTime() / 1000 : undefined, to < Infinity ? to : undefined]);
						}}
					/>
				</Form>
			</Menu.Item>

			<Menu.Item disabled={!canRefine}>
				<Form>
					<DateInput
						value={to < Infinity ? new Date(to * 1000) : null}
						range={[new Date(min * 1000), new Date(max * 1000)]}
						placeholder={t("toDate", "To date")}
						closeOnSelect
						onChange={(e, { value }) => {
							refine([from > -Infinity ? from : undefined, value ? value.getTime() / 1000 : undefined]);
						}}
					/>
				</Form>
			</Menu.Item>
		</>
	);
}
