import { Header } from "semantic-ui-react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import ArticlesCollectionDataTable from "../../../articles/components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";
import ArtifactsCollectionDataTable from "../../../artifacts/components/ArtifactsCollectionDataTable/ArtifactsCollectionDataTable";
import CreateArtifactButton from "../../../artifacts/components/CreateArtifactForm/CreateArtifactButton";
import ProductionScriptsDataTable from "../../../scripts/components/ProductionScriptsDataTable/ProductionScriptsDataTable";

import ProductionViewContext from "../ProductionView/context/ProductionViewContext";

import ProductionArticlesAddArticleButton from "./components/ProductionArticlesAddArticleButton";
import ProductionArticlesAddScriptButton from "./components/ProductionArticlesAddScriptButton";

export default function ProductionArticlesView({ production }) {
	const { t } = useTranslation();

	const { publisher } = useContext(ProductionViewContext);

	return (
		<>
			<Header as="h4" content={t("articles", "Articles")} />
			<ArticlesCollectionDataTable
				allowEmpty
				production={production}
				visibleColumns={["name", "deliveryDate", "isbn", "type", "created", "menu"]}
				selectable
				footer={
					<>
						<ProductionArticlesAddArticleButton production={production} publisher={publisher} />
						{/*<ProductionArticlesEditArticlesButton production={production} />*/}
					</>
				}
			/>

			<Header as="h4" content={t("scripts", "Scripts")} />

			<ProductionScriptsDataTable
				allowEmpty
				production={production}
				// footer={<CreateArticleButton text={t("addArticle", "Add article")} />}
				footer={<ProductionArticlesAddScriptButton production={production} publisher={publisher} />}
			/>

			<Header as="h4" content={t("artifacts", "Artifacts")} />
			<ArtifactsCollectionDataTable
				allowEmpty
				production={production}
				footer={
					<>
						<CreateArtifactButton
							publisher={publisher}
							production={production}
							articles={production?.articles}
							disabled={!production?.articles}
						/>
					</>
				}
			/>
		</>
	);
}
