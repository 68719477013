import { Header } from "semantic-ui-react";

import Flex from "../../../../components/Flex/Flex";

export default function AdminHeader({ text, children, ...props }) {
	return (
		<>
			<Flex gap={10} wrap style={{ marginBottom: 15 }}>
				<div>
					<Header as="h2" {...props}>
						{text || children}
					</Header>
				</div>

				<div style={{ whiteSpace: "nowrap" }}>
					{text && children && (
						<Flex gap={10} justifyContent="flex-start">
							{children}
						</Flex>
					)}
				</div>
			</Flex>
		</>
	);
}
