import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import InstantSearch from "../../../ui/components/InstantSearch/InstantSearch";
import InstantSearchBox from "../../../ui/components/InstantSearch/InstantSearchBox";
import InstantSearchHitsDataTable from "../../../ui/components/InstantSearch/InstantSearchHitsDataTable";
import InstantSearchPagination from "../../../ui/components/InstantSearch/InstantSearchPagination";
import InstantSearchRefinements from "../../../ui/components/InstantSearch/InstantSearchRefinements";
import PageView from "../../../ui/views/PageView/PageView";

import CreateArticleBundleFromArticlesButton from "../../components/CreateArticleBundleForm/CreateArticleBundleFromArticlesButton";
import CreateArticleButton from "../../components/CreateArticleForm/CreateArticleButton";
import DistributeArticlesButton from "../../components/DistributeArticles/DistributeArticlesButton";
import useArticlesRefinements from "../../hooks/useArticlesRefinements";
import useArticlesTableColumns from "../../hooks/useArticlesTableColumns";

export default function ArticlesView({ organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const columns = useArticlesTableColumns({ organization });
	const refinements = useArticlesRefinements({ organization });

	const configure = {
		filters: organization && `publisher.id:${organization.id}`,
	};

	return (
		<InstantSearch routing indexName="articles" configure={configure}>
			<PageView
				header={t("articles", "Articles")}
				extra={<CreateArticleButton publisher={organization} />}
				sidebar={
					<>
						<InstantSearchBox />
						<InstantSearchRefinements refinements={refinements} />
					</>
				}
			>
				<InstantSearchHitsDataTable
					selectable
					columns={columns}
					onClick={({ id }) => navigate(id)}
					footer={({ toggleAllRowsSelected, selectedRows }) => (
						<>
							<CreateArticleBundleFromArticlesButton articles={selectedRows} />
							<DistributeArticlesButton
								articles={selectedRows}
								publisher={organization}
								onSuccess={() => toggleAllRowsSelected(false)}
							/>
						</>
					)}
				/>

				<InstantSearchPagination />
			</PageView>
		</InstantSearch>
	);
}
