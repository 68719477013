import uniqueArray from "astrid-helpers/src/uniqueArray";

import useProductionTeam from "../../productions/hooks/useProductionTeam";

export default function useDiscussionChannelUserOptions({ production }) {
	const team = useProductionTeam(production);

	const { publisherManagers } = production;

	const teamUserIds = uniqueArray(team?.map(({ user }) => user.id));

	const teamUsers = teamUserIds.map((id) => {
		return team.find(({ user }) => user.id === id)?.user;
	});

	const users = [...teamUsers, ...publisherManagers];

	return users.map((user) => ({ key: user.id, value: user.id, text: user.name, data: user }));
}
