import createDocumentIdAndRef from "./createDocumentIdAndRef";

export default function createSeries({ api, env }, { name, season, publisher }) {
	const { id, ref } = createDocumentIdAndRef({ api, env }, { collection: "series" });
	return {
		id,
		ref,
		name,
		seasons: season ? [season] : [],
		publisher,
	};
}
