import React from "react";

import Section from "../../ui/components/Section/Section";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import Dashboard from "../../dashboard/components/Dashboard/Dashboard";
import useProducerDashboardStatistics from "../../organizations/hooks/useProducerDashboardStatistics";

import AlertsWidget from "../components/AlertsWidget/AlertsWidget";
import FavoriteProductionsWidget from "../components/FavoriteProductionsWidget/FavoriteProductionsWidget";
import MyProductionsWidget from "../components/MyProductionsWidget/MyProductionsWidget";
import NotificationsWidget from "../components/NotificationsWidget/NotificationsWidget";
import OffersWidget from "../components/OffersWidget/OffersWidget";
import TodosWidget from "../components/TodosWidget/TodosWidget";
import HomeLayout from "../layouts/HomeLayout/HomeLayout";

const maxWidth = 1300;

export default function HomeProducerView({ organization }) {
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const statistics = useProducerDashboardStatistics({ organization });

	return (
		<>
			{hasFlatteningFeature && (
				<Section maxWidth={maxWidth}>
					<Dashboard statistics={statistics} />
				</Section>
			)}

			<Section maxWidth={maxWidth}>
				<HomeLayout>
					<HomeLayout.LeftColumn>
						<NotificationsWidget organization={organization} />
						<TodosWidget organization={organization} />
						<OffersWidget organization={organization} />
					</HomeLayout.LeftColumn>

					<HomeLayout.RightColumn>
						<AlertsWidget organization={organization} />
						<FavoriteProductionsWidget organization={organization} />
						<MyProductionsWidget organization={organization} />
					</HomeLayout.RightColumn>
				</HomeLayout>
			</Section>
		</>
	);
}
