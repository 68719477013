import { Menu } from "semantic-ui-react";

import InstantSearchRefinement from "./InstantSearchRefinement";

export default function InstantSearchRefinements({ refinements }) {
	return (
		<Menu fluid vertical text>
			{refinements
				.filter(({ hidden }) => !hidden)
				.map((refinement, index) => (
					<InstantSearchRefinement key={index} {...refinement} />
				))}
		</Menu>
	);
}
