import getCollectionData from "../../utils/getCollectionData";

import getArticlePreset from "../distribution/utils/getArticlePreset";

function getEligibleDistributionChannels({ channels, article }) {
	return channels.filter(
		({ active, acceptedLanguages, acceptedArticleTypes }) =>
			active &&
			(acceptedArticleTypes?.length === 0 || acceptedArticleTypes?.includes(article.type)) &&
			(acceptedLanguages?.length === 0 || acceptedLanguages?.includes(article.language)),
	);
}

export default async function applyArticlePresets(firebase, { article, publisher }) {
	const channels = await getCollectionData(
		firebase.firestore().collection("distributionChannels").where("deleted", "==", false),
	);

	const eligibleDistributionChannels = getEligibleDistributionChannels({ channels, article });
	const channelPresets = eligibleDistributionChannels
		.map((channel) => channel.id)
		.reduce((acc, channelId) => {
			const preset = getArticlePreset({ channelId, article, publisher });
			if (preset) {
				return {
					...acc,
					[channelId]: {
						...(preset?.targetData || {}),
					},
				};
			}
			return acc;
		}, {});

	return {
		...article,
		channels: channelPresets,
		channelIds: Object.keys(channelPresets),
	};
}
