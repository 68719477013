import { useTranslation } from "react-i18next";

import useUserOrganizationTypeTranslations from "./useUserOrganizationTypeTranslations";

export default function useUsersRefinements({ organization }) {
	const { t } = useTranslation();

	const organizationTypes = useUserOrganizationTypeTranslations();

	return [
		{
			header: t("type", "Type"),
			attribute: "organizationTypes",
			renderItem: ({ label }) => organizationTypes[label],
			hidden: !!organization,
		},
	];
}
