import FirestoreCollection from "../../firestore/FirestoreCollection";

import { Studio } from "./types/Studio";

export default class StudiosCollection extends FirestoreCollection {
	static collectionName = "studios";
	static Schema = Studio;

	add({ id, organization, name }) {
		return this.create({ id, gen: 2, name, producerId: organization.id });
	}
}
