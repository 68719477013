import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import UsersView from "../views/UsersView/UsersView";

export default function Users() {
	const hasRight = useHasRight();

	const { organization } = useOutletContext();

	if (!hasRight("users.view")) {
		return <Unauthorized />;
	}

	return <UsersView organization={organization} />;
}
