import React, { useContext } from "react";
import { useOutletContext } from "react-router-dom";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";

import ProductionDiscussionsView from "../views/ProductionDiscussionsView/ProductionDiscussionsView";
import ProductionDiscussionOld from "../views/ProductionView/components/ProductionDiscussionOld";
import ProductionViewContext from "../views/ProductionView/context/ProductionViewContext";

export default function ProductionDiscussions() {
	const { production } = useOutletContext();
	const { permissions } = useContext(ProductionViewContext);

	const hasFeature = useFeatureFlag(featureFlags.DISCUSSION);

	if (hasFeature) {
		return <ProductionDiscussionsView production={production} />;
	}

	return <ProductionDiscussionOld production={production} permissions={permissions} />;
}
