import { Segment } from "semantic-ui-react";

import React, { useContext } from "react";
import { useOutletContext } from "react-router-dom";

import ProductionOverviewView from "../views/ProductionOverviewView/ProductionOverviewView";
import ProductionOverviewViewOld from "../views/ProductionOverviewView/ProductionOverviewViewOld";
import ProductionDiscussionOld from "../views/ProductionView/components/ProductionDiscussionOld";
import ProductionViewContext from "../views/ProductionView/context/ProductionViewContext";

export default function ProductionOverview({ discussion }) {
	const outletContext = useOutletContext();
	const context = useContext(ProductionViewContext);

	const { isDesktop, permissions, fullProduction } = context;

	if (!isDesktop && discussion && fullProduction) {
		return (
			<ProductionDiscussionOld
				production={fullProduction}
				permissions={permissions}
				internalPublisher={discussion === "internal"}
			/>
		);
	}

	if (!fullProduction?.articleIds) {
		return (
			<Segment padded>
				<ProductionOverviewViewOld {...outletContext} {...context} />
			</Segment>
		);
	}

	return <ProductionOverviewView {...outletContext} />;
}
