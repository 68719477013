import { useTranslation } from "react-i18next";

import distributionPriceTypes from "astrid-firestore/src/api/distribution/constants/distributionPriceTypes";

export default function useDistributionPriceTypeTranslations() {
	const { t } = useTranslation();

	return {
		[distributionPriceTypes.SINGLE]: t("paySingle", "Single"),
		[distributionPriceTypes.PAY_PER_LOAN]: t("payPerLoan", "Loan"),
	};
}
