import { Segment } from "semantic-ui-react";

import React from "react";

import InstantSearchData from "../../../ui/components/InstantSearch/InstantSearchData";
import useSearchInput from "../../../ui/hooks/useSearchInput";

import { useProfile } from "../../../authentication/state/profile";

import { useDiscussion } from "../../state/discussion";

import DiscussionChannel from "./components/DiscussionChannel";
import DiscussionChannelListItem from "./components/DiscussionChannelListItem";
import DiscussionProduction from "./components/DiscussionProduction";

export default function DiscussionsView() {
	const user = useProfile();
	const { channelId, productionId } = useDiscussion();

	const [searchInput, searchQuery] = useSearchInput({ style: { width: "100%" } });

	return (
		<div style={{ width: 400 }}>
			{!channelId && !productionId && (
				<>
					<Segment vertical style={{ padding: 15 }}>
						{searchInput}
					</Segment>

					<InstantSearchData
						indexName="discussionChannels"
						filters={`userIds:${user.id}`}
						searchQuery={searchQuery}
					>
						{({ data }) =>
							data?.map((channel) => {
								return <DiscussionChannelListItem key={channel.id} channel={channel} />;
							})
						}
					</InstantSearchData>
				</>
			)}

			{channelId && <DiscussionChannel channelId={channelId} />}

			{productionId && !channelId && <DiscussionProduction productionId={productionId} />}
		</div>
	);
}
