import uniqid from "uniqid";

import { Article } from "astrid-firestore/src/api/articles/types/Article";
import { ArticleBundle } from "astrid-firestore/src/api/articles/types/ArticleBundle";
import { ArticleBundleRef } from "astrid-firestore/src/api/articles/types/ArticleBundleRef";
import { Artifact } from "astrid-firestore/src/api/artifacts/types/Artifact";
import { Imprint } from "astrid-firestore/src/api/imprints/types/Imprint";
import { OrganizationRef } from "astrid-firestore/src/api/organizations/types/OrganizationRef";
import { Serie, SerieRef } from "astrid-firestore/src/api/series/types/Serie";
import { getCollectionData, getDocumentData } from "astrid-firestore/src/helpers";

import { ImprintRef } from "../../src/api/imprints/types/ImprintRef";

import createArticleBundle from "./createArticleBundle";
import createAudiobook from "./createAudiobook";
import createAudiobooksFromMulti from "./createAudiobooksFromMulti";
import createCommonArticleData from "./createCommonArticleData";
import createEbook from "./createEbook";
import createImprint from "./createImprint";
import createSeries from "./createSeries";

function hasAudioBook(production) {
	return typeof production.isbn === "string";
}

export function hasMulti(production) {
	return production.deliveryParts?.length;
}

function hasEbook(production) {
	return production.deliveryEbook?.path;
}

function hasSeries(production) {
	return production.series || production.deliveryPartsTitle;
}

function hasSeason(production) {
	if (!hasMulti(production)) {
		return false;
	}
	return production.part;
}

function hasImprint(production) {
	return production?.biblio?.imprintName;
}

function isBundle({ articles, production }) {
	return (
		articles.length > 1 &&
		((hasMulti(production) && (production.series || production.deliveryPartsTitle)) || !hasMulti(production))
	);
}

async function getSeries({ api, env }, { name }) {
	const series = await getCollectionData(api[env].db.collection("series").where("name", "==", name));
	return series.length ? series[0] : null;
}

async function getImprint({ api, env }, { production, publisher }) {
	const imprint = await getCollectionData(
		api[env].db
			.collection("imprints")
			.where("name", "==", production?.biblio?.imprintName)
			.where("publisher", "==", publisher.id),
	);
	return imprint.length ? imprint[0] : null;
}

async function getOrganization({ api, env }, { organizationId }) {
	if (api.convertFromLiveToStage) {
		const organization = await getDocumentData(api.stage.db.collection("organizations").doc(organizationId));
		return OrganizationRef.parse(organization);
	}
	const organization = await getDocumentData(api[env].db.collection("organizations").doc(organizationId));
	return OrganizationRef.parse(organization);
}

export async function migrateProductionToFlattening({ api, env }, { production }) {
	let articles = [];
	let artifacts = [];
	let ebookArticle = null;
	let newSeries = null;
	let seriesRef = null;
	let newImprint = null;
	let imprintRef = null;
	let newBundle = null;
	let bundleRef = null;
	let season = null;

	const publisher = await getOrganization(
		{ api, env },
		{
			organizationId: api.convertFromLiveToStage
				? api.convertFromLiveToStage.publisher
				: api.convertToAnotherPublisher
				? api.convertToAnotherPublisher
				: production.publisher,
		},
	);
	const producer = await getOrganization(
		{ api, env },
		{
			organizationId: api.convertFromLiveToStage ? api.convertFromLiveToStage.producer : production.producer,
		},
	);

	if (hasSeries(production)) {
		const existingSeries = await getSeries(
			{
				api,
				env,
			},
			{ name: production.series || production.deliveryPartsTitle },
		);

		if (hasSeason(production)) {
			const existingSeason = existingSeries?.seasons.find(
				(s) => s.name === production.part || s.name === production.deliveryPartsTitle,
			);
			season = existingSeason || {
				id: uniqid(),
				name: production.deliveryPartsTitle || production.part.toString(),
			};
		}

		const seriesDoc =
			existingSeries ||
			createSeries(
				{
					api,
					env,
				},
				{
					name: production.series || production.deliveryPartsTitle,
					season,
					publisher,
				},
			);

		if (!existingSeries) {
			newSeries = seriesDoc;
		}
		seriesRef = seriesDoc;
	}

	const commonArticleData = await createCommonArticleData(
		{ api, env },
		{
			production,
			season,
			producer,
			publisher,
		},
	);

	if (hasEbook(production)) {
		ebookArticle = await createEbook({ api, env }, { production, publisher, commonArticleData });
		artifacts.push(ebookArticle.artifact);
		articles.push(ebookArticle);
	}

	if (hasAudioBook(production)) {
		const audioBookArticle = await createAudiobook(
			{ api, env },
			{
				production,
				publisher,
				commonArticleData,
				ebookArticle,
			},
		);

		artifacts.push(audioBookArticle.artifact);
		articles.push(audioBookArticle);
	}
	if (hasMulti(production)) {
		const audioBookMultiParts = await createAudiobooksFromMulti(
			{ api, env },
			{
				production,
				publisher,
				commonArticleData,
			},
		);

		artifacts.push(...audioBookMultiParts.map((part) => part.artifact));
		articles.push(...audioBookMultiParts);
	}

	if (hasImprint(production)) {
		const existingImprint = await getImprint({ api, env }, { production, publisher });

		const imprintDoc = existingImprint || createImprint({ api, env }, { production, publisher });

		if (!existingImprint) {
			newImprint = imprintDoc;
		}
		imprintRef = imprintDoc;
	}

	if (isBundle({ articles, production })) {
		newBundle = createArticleBundle({ api, env }, { production, articles });
		bundleRef = newBundle;
	}

	if (articles.length === 0) {
		throw new Error(
			`Production is probably missing essential data. Migration could not be performed for ${production.title} (${production.id})`,
		);
	}

	const parsedArticles = articles.map((article) => ({
		collection: "articles",
		data: Article.parse({
			...article,
			bundle: bundleRef ? ArticleBundleRef.parse(bundleRef) : null,
			serie: seriesRef ? SerieRef.parse(seriesRef) : null,
			season: season ? season : null,
			imprint: imprintRef ? ImprintRef.parse(imprintRef) : null,
		}),
	}));

	const parsedArtifacts = artifacts.filter(Boolean).map((artifact) => ({
		collection: "artifacts",
		data: Artifact.parse(artifact),
	}));

	const parsedSeries = newSeries && {
		collection: "series",
		data: Serie.parse({ ...newSeries, articleIds: articles.map((a) => a.id) }),
	};
	const parsedImprint = newImprint && { collection: "imprints", data: Imprint.parse(newImprint) };
	const parsedArticleBundle = newBundle && { collection: "articleBundles", data: ArticleBundle.parse(newBundle) };

	const documents = [...parsedArticles, ...parsedArtifacts, parsedImprint, parsedArticleBundle, parsedSeries].filter(
		Boolean,
	);

	const db = api.convertFromLiveToStage ? api.stage.db : api[env].db;

	await db.runTransaction(async (transaction) => {
		documents.forEach((doc) => {
			const docRef = db.collection(doc.collection).doc(doc.data.id);
			transaction.set(docRef, doc.data);
		});

		const ref = db.collection("productions").doc(production.id);
		transaction.update(ref, {
			articleIds: parsedArticles.map((doc) => doc.data.id),
			"migrations.flattening": {
				migrated: true,
				timestamp: new Date(),
				createdDocuments: documents.map((doc) => ({ collection: doc.collection, id: doc.data.id })),
			},
		});
	});

	console.log(parsedArticles);
	console.log(parsedArtifacts);

	return documents;
}
