import { enrichSplitPoints } from "astrid-compute/src/scripts/createExport/helpers/splitPoints";
import analyzeSilences from "astrid-compute/src/shared/analyzeSilences";
import artifactMarkerTypes from "astrid-firestore/src/api/artifacts/constants/artifactMarkerTypes";
import { getDocumentData } from "astrid-firestore/src/helpers";
import arrayChunk from "astrid-helpers/src/arrayChunk";

async function analyzeSplitPoints({ api, env }, { production, articleType, files, metaMasterDoc }) {
	let splits = [];
	let lastSparse = 0;
	let lastDisc = 0;
	let outroSilence = 0;
	let partStart = 0;

	const settings = production.master[articleType].settings;

	const sourceFiles = files.filter((file) => metaMasterDoc[file]).map((file) => [file, metaMasterDoc[file]]);

	const duration = sourceFiles.reduce((sum, [_, info]) => sum + info.duration, 0);

	let silences = {};

	const bucketName = env === "live" ? "earselect-static" : "stage-earselect-static";

	for (const chunk of arrayChunk(sourceFiles, 10)) {
		const silencesChunks = await Promise.all(
			chunk.map(async ([file, info]) => {
				const metadataPath = info.json_master?.path || info.json.path;
				const metadataJson = await api[env].getStorageJsonFile(bucketName, metadataPath);
				return { [file]: metadataJson };
			}),
		);

		for (const chunk of silencesChunks) {
			silences = { ...silences, ...chunk };
		}
	}

	sourceFiles.forEach(([file, info], partIndex) => {
		const nextPart = sourceFiles[partIndex + 1];
		const nextPartStartsWithSilence =
			!nextPart || (silences[nextPart[0]] && silences[nextPart[0]][0] && !silences[nextPart[0]][0].start);

		const {
			analyzedSilences,
			lastSparse: newLastSparse,
			lastDisc: newLastDisc,
			outroSilence: newOutroSilence,
		} = silences[file]
			? analyzeSilences(
					lastSparse,
					lastDisc,
					false,
					outroSilence,
					silences[file],
					partStart,
					nextPartStartsWithSilence,
					file,
					info,
					settings.overrides,
					settings.sentenceSilence,
					settings.chapterSilence,
					settings.duration,
			  )
			: [];

		// filter, massage and merge
		splits = [
			...splits,
			...analyzedSilences
				.filter(
					(split) =>
						split.split &&
						(settings.part === "split" ||
							split.split === "chapter" ||
							split.split === "intro" ||
							split.split === "disc"),
				) // include all or just chapter splits?
				.map((split) => ({ ...split, start: split.start + partStart, end: split.end + partStart })),
		];

		// store last sparse split to be used in next part analysis
		lastSparse = newLastSparse;
		lastDisc = newLastDisc;
		outroSilence = newOutroSilence;
		partStart = partStart + (info.duration || 100);
	});

	if (splits.length > 0) {
		const start = splits[splits.length - 1].end - 0.2;
		splits.push({ start });

		return splits.map(enrichSplitPoints(articleType, duration, () => {})).map((split, index) => {
			return {
				id: index + 1,
				position: split.splitStart * 1000,
				text: split.title ? split.title : "",
				type: artifactMarkerTypes.CHAPTER,
			};
		});
	}
}

export default async function createMarkers({ api, env }, { production, articleType = "total" }) {
	const hasChapterSplitPoints = Object.values(production.master[articleType].exports).find(
		(exp) => exp.part === "chapter",
	);

	const hasSplitPoints = Object.values(production.master[articleType].exports).find((exp) => exp.part === "split");

	if (!hasChapterSplitPoints && !hasSplitPoints) {
		return null;
	}

	const metaMasterDoc = await getDocumentData(
		api[env].db.collection("productions").doc(production.id).collection("meta").doc("master"),
	);

	const chapters = await getDocumentData(
		api[env].db.collection("productions").doc(production.id).collection("meta").doc("chapters"),
	);
	if (chapters.exists) {
		throw new Error(
			"Found production with meta.chapters. Do not know how to handle this since they include both start and end",
		);
		// splitPoints = chaptersToSplitPoints(chapters);
	}

	return await analyzeSplitPoints(
		{ api, env },
		{
			production,
			articleType,
			files: production.master[articleType].files,
			metaMasterDoc,
		},
	);
}
