import Handlebars from "handlebars";

import bisacToThema from "astrid-config/src/genres/BisacToThema.json";
import languages from "astrid-config/src/languages";
import contributorRoles from "astrid-firestore/src/api/articles/constants/contributorRoles";
import distributionPriceTypes from "astrid-firestore/src/api/distribution/constants/distributionPriceTypes";

import getArticle from "./utils/getArticle";
import * as dateHelper from "./utils/handlebarsDateHelpers";

const mimeTypes = {
	wav: { mime: "audio/wave", description: "Broadcast Wave" },
	mp3: { mime: "audio/mpeg", description: "MPEG 1/2 Audio Layer 3" },
	jpg: { mime: "image/jpeg", description: "JPEG File Interchange Format" },
	jpeg: { mime: "image/jpeg", description: "JPEG File Interchange Format" },
	epub: { mime: "application/epub+zip", description: "ePub format" },
	zip: { mime: "application/zip", description: "ZIP archive" },
};

const contributorCodes = {
	[contributorRoles.AUTHOR]: "A01",
	[contributorRoles.NARRATOR]: "E07",
	[contributorRoles.TRANSLATOR]: "B06",
};

function base64ToHex(base64) {
	const raw = atob(base64);
	let result = "";
	for (let i = 0; i < raw.length; i++) {
		const hex = raw.charCodeAt(i).toString(16);
		result += hex.length === 2 ? hex : "0" + hex;
	}
	return result;
}

function isEbook(article) {
	return article.type === "ebook";
}

function isArticleActive(article, channel) {
	const takeDownDate = dateHelper.getDistributionDates(article, channel)?.takedownDate;
	if (takeDownDate) {
		return dateHelper.compareDistributionDateWithToday(takeDownDate);
	}
	return true;
}

function getCountriesIncluded(article, channel) {
	return article.channels?.[channel.id]?.countries;
}

function getPrices(article, channel) {
	return article.channels?.[channel.id]?.prices || [];
}

Handlebars.registerHelper("join", function (array, separator) {
	return array.join(separator);
});

Handlebars.registerHelper("getAbridged", function (article) {
	return article.abridged ? "ABR" : "UBR";
});

Handlebars.registerHelper("isEbook", (article) => {
	return isEbook(article);
});

Handlebars.registerHelper("getArticleLanguageIso", (article) => {
	return languages[article.language]?.iso6392;
});

Handlebars.registerHelper("isOnlyWorld", (article, channel) => {
	const countries = article.channels?.[channel.id]?.countries;
	return countries?.length === 1 && countries[0] === "WORLD";
});

Handlebars.registerHelper("getCountriesIncluded", (article, channel) => {
	return getCountriesIncluded(article, channel)?.join(" ");
});

Handlebars.registerHelper("getCountriesIncludedWithLinkedArticle", (articleType, article, linkedArticle, channel) => {
	const art = getArticle({ articleType, article, linkedArticle });
	return getCountriesIncluded(art, channel)?.join(", ");
});

Handlebars.registerHelper("getCreatedDate", () => {
	return new Date().toISOString();
});

Handlebars.registerHelper("isRelevantContributor", (contributor, article) => {
	if (contributor.role === contributorRoles.NARRATOR && isEbook(article)) {
		return false;
	}
	return !!contributorCodes?.[contributor.role];
});

Handlebars.registerHelper("mapContributorRole", (contributor) => {
	return contributorCodes?.[contributor.role];
});

Handlebars.registerHelper("getContributorByRole", (article, role) => {
	return article.contributors?.filter((contributor) => contributor.role === role);
});

Handlebars.registerHelper("getContributorFullNameInverted", ({ firstName, lastName }) =>
	lastName && firstName ? `${lastName}, ${firstName}` : firstName || lastName,
);

Handlebars.registerHelper("isTranslated", (article) => {
	return article.originalLanguage && article.language !== article.originalLanguage;
});

Handlebars.registerHelper("getIsbn", (articleType, article, linkedArticle) => {
	const { isbn } = getArticle({ articleType, article, linkedArticle });

	return isbn;
});

Handlebars.registerHelper("getPublisherOrganizationNumber", (publisher) => {
	if (publisher && Object.values(publisher.vendors || {}).length) {
		const primaryVendor = Object.values(publisher.vendors).find((vendor) => vendor.primary);
		if (primaryVendor && primaryVendor.organizationNumber) {
			return primaryVendor.organizationNumber.replace("-", "");
		}
	}
	return "unknown-org-number";
});

Handlebars.registerHelper("getFileId", (index) => {
	return String(index + 1).padStart(5, "0");
});

Handlebars.registerHelper("getMimeType", (file) => {
	const extensions = file.name.split(".");
	const ext = extensions.pop();
	return mimeTypes[ext]?.mime;
});

Handlebars.registerHelper("getRoyalLibraryMimeType", (file) => {
	const extensions = file.name.split(".");
	const ext = extensions.pop();
	return mimeTypes[ext]?.description;
});

Handlebars.registerHelper("getFileCreationISODate", (file) => {
	const millis = Math.floor(file.generation / 1000);
	return new Date(millis).toISOString();
});

Handlebars.registerHelper("getMD5checksum", (file) => {
	return base64ToHex(file.md5Hash);
});

Handlebars.registerHelper("getCoverId", (article, files) => {
	return String(files.length + 1).padStart(5, "0");
});

Handlebars.registerHelper("mapAudibleDEGenre", (genres) => {
	// audible only supports one genre, grab the first and run with it (comment from Emil)
	const genre = genres?.[0];

	const audibleGenre = {
		crime: "Krimi",
		biographies: "Bildung & Wissen",
		nonfiction: "Bildung & Wissen",
		fact: "Bildung & Wissen",
		thriller: "Thriller",
		kids: "Kinder-Hörbucher",
		history: "Bildung & Wissen",
		teen: "Jugend-Hörbücher",
		finance: "Wirtschaft & Karriere",
		erotica: "Romane",
		shortstories: "Romane",
		language: "Sprachkurse",
		harlequin: "Romane",
		selfhelp: "Freizeit & Leben",
		fantasy: "Fantasy",
		classics: "Klassiker",
		poetry: "Romane",
		feature: "Bildung & Wissen",
		religion: "Spiritualität & Religion",
		romance: "Romane/Liebesromane",
	};

	return audibleGenre?.[genre] || "";
});

Handlebars.registerHelper("getLanguageName", (article) => {
	return languages?.[article.language]?.name;
});

Handlebars.registerHelper("getAudibleDECopyrightHolder", (article) => {
	return `${article?.authors?.map((author) => author.name).join(",")} / ${article?.publisher?.name}`;
});

Handlebars.registerHelper("hasDuration", (article) => {
	return (isEbook(article) && article.pages?.end) || !!article.duration;
});

Handlebars.registerHelper("getDuration", (article) => {
	if (isEbook(article)) {
		return article.pages?.end;
	}

	return (article.duration / 60).toFixed(2);
});

Handlebars.registerHelper("getDurationInSeconds", (article) => {
	return Math.floor(article.duration);
});

Handlebars.registerHelper("getExtentType", (article) => {
	return isEbook(article) ? "08" : "09";
});

Handlebars.registerHelper("getExtentUnit", (article) => {
	return isEbook(article) ? "03" : "05";
});

Handlebars.registerHelper("getMarketPublishingStatus", (article, channel) => {
	const isActive = isArticleActive(article, channel);
	if (!isActive) {
		return "08";
	}

	if (dateHelper.compareDistributionDateWithToday(dateHelper.getDistributionDates(article, channel)?.releaseDate)) {
		return "02";
	}

	return "04";
});

Handlebars.registerHelper("getProductAvailability", (article, channel) => {
	return isArticleActive(article, channel) ? "20" : "01";
});

Handlebars.registerHelper("getPublishingStatus", (article, channel) => {
	return isArticleActive(article, channel) ? "04" : "01";
});

Handlebars.registerHelper("mapThemaCodesToBisacCodes", (article) => {
	const bisacEntries = Object.entries(bisacToThema);

	const themaToBisac = {};

	for (const themaCode of article.themaCodes || []) {
		let currentWeight = 0;

		const bisacs = bisacEntries.filter(([, map]) => map.includes(themaCode));

		for (const [bisacCode, map] of bisacs) {
			const weight = map.length;
			const hasAll = map.every((code) => article.themaCodes.includes(code));

			if (hasAll && weight > currentWeight) {
				currentWeight = weight;
				themaToBisac[themaCode] = bisacCode;
			}
		}
	}

	return [...new Set(Object.values(themaToBisac))];
});

Handlebars.registerHelper("getPublisherName", (article) => {
	if (article.publisher.id === "EoG8BXwJEpvWLRP4SOff" && ["no", "nb", "nn"].includes(article.language)) {
		return `${article.publisher.name} Norge`;
	}
	return article.publisher.name;
});

Handlebars.registerHelper("getSubChannels", (article, channel) => {
	return article.channels?.[channel.id]?.subChannels;
});

Handlebars.registerHelper("formatSynopsis", (article) => {
	return article?.synopsis?.split?.("\n\n")?.reduce?.((prev, curr) => prev + "<p>" + curr + "</p>", "");
});

Handlebars.registerHelper("isPayPerLoan", (price) => {
	return price.type === distributionPriceTypes.PAY_PER_LOAN;
});

Handlebars.registerHelper("getPrices", (article, channel) => {
	return getPrices(article, channel);
});

Handlebars.registerHelper("mapPriceTypeToId", (price) => {
	const mapping = {
		[distributionPriceTypes.SINGLE]: "SINGLE",
		[distributionPriceTypes.PAY_PER_LOAN]: "PAY_PER_LOAN",
	};
	return mapping[price.type];
});

Handlebars.registerHelper("formatPriceAmount", (amount) => {
	return amount?.toFixed(2);
});
