import firebase from "firebase/app";

import React from "react";
import { useTranslation } from "react-i18next";

import applySinglePresetForArticle from "astrid-firestore/src/api/articles/applySinglePresetForArticle";
import { Article } from "astrid-firestore/src/api/articles/types/Article";
import updateArticle from "astrid-firestore/src/api/articles/updateArticle";

import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import useArticleDistributionChannels from "../../../hooks/useArticleDistributionChannels";

export default function ArticleDistributionChannelsEditForm({ article, publisher, onClose }) {
	const { t } = useTranslation();

	const [presetChannels] = useArticleDistributionChannels({ article, publisher });

	const { form, onSubmit, error } = useSimpleForm({
		schema: Article,
		defaultValues: {
			channelIds: article.channelIds || [],
		},
		onSubmit: ({ channelIds }) => {
			const added = channelIds.filter((id) => !article.channelIds?.includes?.(id));
			const addedPresets = added.reduce((acc, id) => {
				const preset = applySinglePresetForArticle({ article, channelId: id, publisher });
				if (preset) {
					return {
						...acc,
						[id]: {
							...preset?.targetData,
						},
					};
				}
				return acc;
			}, {});
			return updateArticle(firebase, {
				ref: article.ref,
				channelIds,
				channels: {
					...article.channels,
					...addedPresets,
				},
			});
		},
		onSuccess: onClose,
	});

	return (
		<Form.Modal
			size="tiny"
			header={t("distributionChannels", "Distribution channels")}
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			<Form.FormCheckboxTable
				name="channelIds"
				options={presetChannels.map(({ id, name }) => ({ key: id, value: id, text: name }))}
			/>
		</Form.Modal>
	);
}
