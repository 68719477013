import { Route } from "react-router-dom";

import AdminArtifact from "../../admin/pages/AdminArtifact/AdminArtifact";
import AdminArtifactFiles from "../../admin/pages/AdminArtifact/AdminArtifactFiles";

export const artifact = (
	<Route path=":artifact" element={<AdminArtifact />} handle={{ crumb: ({ artifact }) => artifact?.name }}>
		<Route index element={<AdminArtifactFiles />} />
	</Route>
);
