import { useTranslation } from "react-i18next";

import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import PageView from "../../../ui/views/PageView/PageView";

import useAdminMenuOptions from "./hooks/useAdminMenuOptions";

export default function AdminView({ organization, children }) {
	const { t } = useTranslation();
	const options = useAdminMenuOptions({ organization });

	return (
		<PageView header={t("admin", "Admin")} sidebar={<VerticalMenu options={options} />}>
			{children}
		</PageView>
	);
}
