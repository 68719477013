import { array, nullable, object, record, string } from "../../../types/_types";

import { ArticleDelivery } from "./ArticleDelivery";
import { ArticleDistribution } from "./ArticleDistribution";
import { ArticlePrice } from "./ArticlePrice";

export const ArticleChannelMetaData = object({
	distribution: ArticleDistribution,
	countries: nullable(array(string())),
	prices: array(ArticlePrice),
	subChannels: array(object({ id: string(), name: string() })),
});

export const ArticleChannel = ArticleChannelMetaData.extend({
	delivery: ArticleDelivery,
});
