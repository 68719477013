import React from "react";
import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import { DistributionChannel } from "astrid-firestore/src/api/distribution/types/DistributionChannel";
import updateDistributionChannel from "astrid-firestore/src/api/distribution/updateDistributionChannel";

import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

export default function SuperAdminDistributionSubChannelsForm({ channel }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: DistributionChannel,
		defaultValues: {
			subChannels: channel.subChannels || [],
		},
		onSubmit: ({ subChannels }) => {
			return updateDistributionChannel(firebase, { ref: channel.ref, subChannels });
		},
	});

	return (
		<AdminForm header={t("subChannels", "Sub channels")} {...form}>
			<Form.FormFieldArrayTable name="subChannels" header={t("subChannels", "Sub channels")}>
				{({ id, name }, index) => (
					<Form.Group widths="equal" style={{ margin: 0 }}>
						<Form.Input name={`subChannels.${index}.id`} value={id} placeholder={t("id", "Id")} />
						<Form.Input name={`subChannels.${index}.name`} value={name} placeholder={t("name", "Name")} />
					</Form.Group>
				)}
			</Form.FormFieldArrayTable>
		</AdminForm>
	);
}
