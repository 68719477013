import artifactStatuses from "astrid-firestore/src/api/artifacts/constants/artifactStatuses";

const artifactStatusColors = {
	[artifactStatuses.PENDING]: "blue",
	[artifactStatuses.VALID]: "green",
	[artifactStatuses.INVALID]: "red",
	[artifactStatuses.FAILED]: "red",
};

export default artifactStatusColors;
