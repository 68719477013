import React, { useEffect } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

import useArtifactAudioPlayerTimeline from "../hooks/useArtifactAudioPlayerTimeline";

import ArtifactAudioPlayerMarker from "./ArtifactAudioPlayerMarker";
import ArtifactAudioPlayerTimelineTool from "./ArtifactAudioPlayerTimelineTool";
import ArtifactAudioPlayerTrack from "./ArtifactAudioPlayerTrack";

export default function ArtifactAudioPlayerTimeline({
	activeTool,
	setActiveTool,
	action,
	addMarker,
	audioFiles,
	play,
	markers,
	updateMarker,
}) {
	const { setPosition, setDuration, resetTimeline } = useArtifactAudioPlayerTimeline();

	const onClickTimeline = (position) => {
		setPosition(position);

		if (action === "play") {
			play();
		}
	};

	useEffect(() => {
		const duration = audioFiles[audioFiles.length - 1]?.stop;

		if (duration) {
			setDuration(duration);
		}

		return resetTimeline;
	}, [audioFiles, resetTimeline, setDuration]);

	return (
		<Timeline onClick={onClickTimeline}>
			<Timeline.Scope value={2}>
				<Timeline.Ruler />

				<Timeline.Range position={0} length={2000} />

				<ArtifactAudioPlayerTrack audioFiles={audioFiles} />

				{/*<Timeline.Range color="positive" length={2000} position={3000}>*/}
				{/*	Kapitel 1*/}
				{/*</Timeline.Range>*/}

				{markers.map((marker, index) => {
					return (
						<ArtifactAudioPlayerMarker
							key={marker.id}
							index={index}
							marker={marker}
							onChange={(position) => updateMarker({ marker, position })}
						/>
					);
				})}

				<Timeline.MouseCursor />

				{activeTool && (
					<ArtifactAudioPlayerTimelineTool
						addMarker={addMarker}
						activeTool={activeTool}
						setActiveTool={setActiveTool}
					/>
				)}

				<Timeline.PositionCursor />
			</Timeline.Scope>
		</Timeline>
	);
}
