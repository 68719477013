import { Header, Segment } from "semantic-ui-react";

import React from "react";

export default function Section({ children, maxWidth = 1350, style }) {
	return (
		<Segment padded vertical style={{ margin: "0 -15px", ...style }}>
			<div style={{ maxWidth, margin: "0 auto", padding: "0 15px" }}>{children}</div>
		</Segment>
	);
}

Section.Header = function SectionHeader({ style, ...props }) {
	return <Header as="h1" style={{ margin: 0, ...style }} {...props} />;
};

Section.SubHeader = function SectionSubHeader({ style, ...props }) {
	return <Header.Subheader as="h2" style={{ margin: 0, ...style }} {...props} />;
};

// <Header.Subheader style={{ marginBottom: 2 }}>{subheader}</Header.Subheader>
