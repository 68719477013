import firebase from "firebase";

import { useTranslation } from "react-i18next";

import getDistributionChannelsQuery from "astrid-firestore/src/api/distribution/getDistributionChannelsQuery";
import { DistributionPreset } from "astrid-firestore/src/api/distribution/types/DistributionPreset";

import api from "../../../../api";

import ArticleTypeSelect from "../../../articles/components/ArticleTypeSelect/ArticleTypeSelect";
import AdminArticleDistributionTargetDataForm from "../../../articles/views/AdminArticleView/components/AdminArticleDistributionTargetDataForm";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateDistributionPresetForm({ publisher, preset, onClose }) {
	const { t } = useTranslation();

	const { form, onSubmit, error, setValue, watch } = useSimpleForm({
		schema: DistributionPreset,
		values: {
			channel: preset?.channel || null,
			articleType: preset?.articleType || null,
			languages: preset?.languages || publisher.languageOptions,
			targetData: {
				countries: preset?.targetData?.countries || ["WORLD"],
				prices: preset?.targetData?.prices || [],
				subChannels: preset?.targetData?.subChannels || [],
			},
		},
		onSubmit: ({ channel, articleType, languages, targetData }) => {
			const data = { publisher, channel, articleType, languages, targetData };
			if (preset) {
				return api.organizations.updateDistributionPreset({ id: preset.id, ...data });
			}
			return api.organizations.addDistributionPreset(data);
		},
		onSuccess: onClose,
	});

	const { channel } = watch();

	const onChangeOptions =
		(name) =>
		({ value }) => {
			if (value.length > 1 && value.includes("all")) {
				const last = value[value.length - 1];

				setValue(name, last === "all" ? ["all"] : value.filter((v) => v !== "all"));
			}
		};

	return (
		<Form.Modal
			form={form}
			header={t("addPreset", "Add preset")}
			onSubmit={onSubmit}
			onClose={onClose}
			error={error}
		>
			<Form.CollectionSelect
				name="channel"
				label={t("channel", "Channel")}
				query={getDistributionChannelsQuery(firebase)}
			/>

			<Form.Field name="articleType">
				<ArticleTypeSelect
					channel={channel}
					label={t("articleType", "Article type")}
					clearable
					disabled={!channel}
				/>
			</Form.Field>
			<Form.LanguageSelect
				name="languages"
				label={t("languages", "Languages")}
				filterOptions={(option) =>
					!publisher?.languageOptions?.length || publisher?.languageOptions?.includes(option.value)
				}
				onChangeOptions={onChangeOptions("languages")}
				multiple
				clearable
				disabled={!channel}
			/>

			{channel && (
				<AdminArticleDistributionTargetDataForm name="targetData" channel={channel} publisher={publisher} />
			)}
		</Form.Modal>
	);
}
