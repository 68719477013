import React from "react";
import { Configure, InstantSearch as ReactInstantSearch } from "react-instantsearch";

import { useAlgoliaSearchClient } from "../../../algolia/state/algoliaSearchClient";

import LoadingContext from "../LoadingContext/LoadingContext";

export default function InstantSearch({ indexName, configure, routing, children }) {
	const searchClient = useAlgoliaSearchClient();

	return (
		<LoadingContext data loading={!searchClient}>
			<ReactInstantSearch routing={routing} indexName={indexName} searchClient={searchClient}>
				<Configure {...configure} />

				{children}
			</ReactInstantSearch>
		</LoadingContext>
	);
}
