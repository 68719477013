import firebase from "firebase";

import { useTranslation } from "react-i18next";

import { ArticleChannelMetaData } from "astrid-firestore/src/api/articles/types/ArticleChannel";
import updateArticle from "astrid-firestore/src/api/articles/updateArticle";

import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import AdminArticleDistributionTargetDataForm from "./AdminArticleDistributionTargetDataForm";

export default function AdminArticleDistributionChannelForm({ channel, article, publisher, onClose }) {
	const { t } = useTranslation();

	const { form, onSubmit, error } = useSimpleForm({
		schema: ArticleChannelMetaData,
		defaultValues: {
			ref: article.ref,
			prices: article.channels?.[channel.id]?.prices || [],
			countries: article.channels?.[channel.id]?.countries || ["WORLD"],
			subChannels: article.channels?.[channel.id]?.subChannels || [],
		},
		onSubmit: ({ ref, prices, countries, subChannels }) => {
			return updateArticle(firebase, {
				ref,
				channels: {
					[channel.id]: {
						prices,
						countries,
						subChannels,
					},
				},
			});
		},
		onSuccess: onClose,
	});

	return (
		<Form.Modal
			form={form}
			header={t("editChannel", "Edit presets")}
			onSubmit={onSubmit}
			onClose={onClose}
			error={error}
		>
			<AdminArticleDistributionTargetDataForm channel={channel} publisher={publisher} />
		</Form.Modal>
	);
}
