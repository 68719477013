import { useTranslation } from "react-i18next";

import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

import useHasRight from "../../../../authorization/hooks/useHasRight";

export default function useProductionDropdownMenuOptions({ production, setHistoryOpen, setIsDeleting }) {
	const { t } = useTranslation();

	const hasRight = useHasRight();

	return [
		{
			text: t("history", "History"),
			disabled: !hasRight("productions.admin"),
			onClick: () => {
				setHistoryOpen(true);
			},
		},
		{
			text: t("delete", "Delete"),
			disabled: !hasRight("productions.delete") || production.status !== productionStatuses.DRAFT,
			onClick: () => {
				setIsDeleting(true);
			},
		},
	];
}
