import Label from "../../../ui/components/Label/Label";

import artifactStatusColors from "../../utils/artifactStatusColors";

import ArtifactStatus from "./ArtifactStatus";

export default function ArtifactStatusLabel({ artifact, ...props }) {
	return (
		<Label color={artifactStatusColors[artifact.status]} {...props}>
			<ArtifactStatus artifact={artifact} />
		</Label>
	);
}
