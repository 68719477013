import firebase from "firebase/app";

import { Icon } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import updateArticle from "astrid-firestore/src/api/articles/updateArticle";

import Popup from "../../../ui/components/Popup/Popup";
import useLoadingFunction from "../../../ui/hooks/useLoadingFunction";

export default function ArtifactDefaultArticleArtifactIcon({ article, artifact, disabled = false }) {
	const { t } = useTranslation();

	const isDefaultArtifact = article?.artifact?.id === artifact.id;

	const [onClick, loading, error] = useLoadingFunction((e) => {
		e.stopPropagation();

		return updateArticle(firebase, { ref: article.ref, artifact });
	});

	return (
		<Popup
			content={
				error
					? t("errorMessageHeader", "Something went wrong")
					: isDefaultArtifact
					? t("defaultArtifact", "Default artifact")
					: t("setDefaultArtifact", "Set as default artifact")
			}
			trigger={
				<Icon
					color={error ? "red" : isDefaultArtifact ? "green" : undefined}
					name={
						error
							? "warning sign"
							: loading
							? "spinner"
							: isDefaultArtifact
							? "check circle outline"
							: "times circle outline"
					}
					loading={loading}
					link={!disabled && !loading}
					onClick={!disabled && onClick}
				/>
			}
		/>
	);
}
