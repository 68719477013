import { Table } from "semantic-ui-react";

import TableCell from "./TableCell";

export default function TableBody({ table, pagination, children, onClickRow }) {
	const { page, rows, prepareRow, getTableBodyProps } = table;

	const pageOrRows = pagination ? page : rows;

	return (
		<Table.Body {...getTableBodyProps()}>
			{pageOrRows.map((row) => {
				prepareRow(row);

				return (
					<Table.Row {...row.getRowProps()} style={{ backgroundColor: row.depth > 0 && "#f4fafd" }}>
						{row.cells.map((cell) => (
							<TableCell
								row={row}
								cell={cell}
								onClick={(e) => {
									if (cell.column?.onClick) {
										cell.column?.onClick?.(e, row);
									} else {
										onClickRow?.(e, row);
									}
								}}
								{...cell.getCellProps()}
							/>
						))}
					</Table.Row>
				);
			})}

			{children}
		</Table.Body>
	);
}
