import { Message } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";

import AdminArticleExpiredExportsView from "../views/AdminArticleView/AdminArticleExpiredExportsView";
import AdminArticleExportsView from "../views/AdminArticleView/AdminArticleExportsView";

export default function ArticleExports() {
	const { article } = useOutletContext();
	const { t } = useTranslation();

	if (article.type === articleTypes.EBOOK) {
		return <Message info content={t("exportsNotAvailable", "Exports are not available for ebooks")} />;
	}

	return (
		<>
			<AdminArticleExportsView article={article} />
			<AdminArticleExpiredExportsView article={article} />
		</>
	);
}
