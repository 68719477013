import { useTranslation } from "react-i18next";

import OpenButton from "../../../../ui/components/OpenButton/OpenButton";

import ArticleDistributionChannelsEditForm from "./ArticleDistributionChannelsEditForm";

export default function ArticleDistributionChannelsEditButton({ article, channels, publisher, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("edit", "Edit")} {...props}>
			{({ setOpen }) => (
				<ArticleDistributionChannelsEditForm
					article={article}
					channels={channels}
					publisher={publisher}
					onClose={() => setOpen(false)}
				/>
			)}
		</OpenButton>
	);
}
