import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";

import useArticleStatusTranslations from "./useArticleStatusTranslations";

export default function useArticleStatusOptions() {
	const translations = useArticleStatusTranslations();

	return [
		{
			key: articleStatuses.DRAFT,
			value: articleStatuses.DRAFT,
			text: translations[articleStatuses.DRAFT],
		},
		{
			key: articleStatuses.READY,
			value: articleStatuses.READY,
			text: translations[articleStatuses.READY],
		},
	];
}
