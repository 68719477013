import { Checkbox } from "semantic-ui-react";

import stopPropagation from "astrid-helpers/src/stopPropagation";

export default function checkboxes(selectable) {
	return (hooks) => {
		const isRowDisabled = (row) =>
			row.original.disabled || (typeof selectable === "function" && !selectable(row.original));

		hooks.getToggleAllPageRowsSelectedProps = (props, { instance }) => [
			props,
			{
				onChange() {
					for (const row of instance.rows) {
						if (!isRowDisabled(row)) {
							row.toggleRowSelected(!instance.isAllPageRowsSelected);
						}
					}
				},
				style: {
					cursor: "pointer",
				},
				checked: instance.isAllPageRowsSelected,
				title: "Toggle All Current Page Rows Selected",
				indeterminate: Boolean(
					!instance.isAllPageRowsSelected &&
						instance.page?.some(({ id }) => instance.state.selectedRowIds[id]),
				),
			},
		];

		hooks.visibleColumns.push((columns) => [
			{
				id: "selection",
				sticky: true,
				collapsing: true,
				groupByBoundary: true,
				onClick: (e, row) => {
					if (!isRowDisabled(row)) {
						row.toggleRowSelected();
					}
				},
				Cell: ({ row }) => {
					return (
						<Checkbox
							{...row.getToggleRowSelectedProps()}
							disabled={isRowDisabled(row)}
							onClick={stopPropagation}
							style={{ display: "block" }}
						/>
					);
				},
				Header: ({ getToggleAllPageRowsSelectedProps }) => (
					<Checkbox {...getToggleAllPageRowsSelectedProps()} style={{ display: "block" }} />
				),
			},
			...columns,
		]);
	};
}
