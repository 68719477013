import { Header } from "semantic-ui-react";

import React from "react";

import AdminPageHeader from "../../../../admin/components/AdminPage/AdminPageHeader";

import ArticleCoverImage from "../../../components/ArticleCoverImage/ArticleCoverImage";

import useAdminArticleVersionMenuOptions from "../hooks/useAdminArticleVersionMenuOptions";

export default function AdminArticleVersionHeader({ version }) {
	const menuOptions = useAdminArticleVersionMenuOptions();

	const article = version.after;

	return (
		<AdminPageHeader menuOptions={menuOptions}>
			<ArticleCoverImage thumb rounded article={article} style={{ width: 80, height: 80 }} />

			<Header style={{ margin: 0 }}>{article.name}</Header>
		</AdminPageHeader>
	);
}
