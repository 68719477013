import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { array, boolean, language, nullable, record, string } from "../../../types/_types";

import { articleType } from "../../articles/types/Article";
import { Pipeline } from "../../pipelines/types/Pipeline";

export const DistributionChannel = FirestoreDocument.extend({
	acceptedArticleTypes: array(articleType),
	acceptedDeliveryTypes: array(string()),
	acceptedLanguages: array(language()),
	pipelines: record(string(), Pipeline.partial()).default({}),
	distributionPipelineId: nullable(string()),
	active: boolean(),
	disableArtifactDeliveryWithoutMetadata: boolean(),
	customXmlTemplate: nullable(string()),
	enabledTemplates: nullable(array(string())),
	excelTemplate: nullable(string()),
	name: string(3),
	onixTemplate: nullable(string()),
	subChannels: array(record(string(), string())),
}).merge(FirestoreLifecycle);
