import { getDocumentData } from "astrid-firestore/src/helpers";

function mergeDistributionDates({ articleGlobalDates, channelSpecificDates }) {
	const result = { ...(articleGlobalDates || {}) };

	for (const key in channelSpecificDates) {
		if (channelSpecificDates[key] !== null) {
			result[key] = channelSpecificDates[key];
		}
	}

	return result;
}

function mergeChannelData({ article }) {
	return Object.entries(article.channels).reduce((acc, [channelId, articleChannelData]) => {
		return {
			...acc,
			[channelId]: {
				...articleChannelData,
				distribution: mergeDistributionDates({
					articleGlobalDates: article?.distribution,
					channelSpecificDates: articleChannelData?.distribution,
				}),
			},
		};
	}, {});
}

export default async function fetchMetadata({ document }) {
	const { article } = document;
	const linkedArticle = await getDocumentData(article.linkedArticle?.ref);

	return {
		...document,
		article: {
			...article,
			channels: mergeChannelData({ article }),
		},
		linkedArticle: linkedArticle || null,
	};
}
