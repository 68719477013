import { Header, Icon, Segment } from "semantic-ui-react";

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useCountryOptions from "astrid-hooks/src/useCountryOptions";

import Flex from "../../../../components/Flex/Flex";

import Form from "../../../forms/components/Form/Form";

export default function DistributionCountriesCheckboxTable({ name }) {
	const { t } = useTranslation();
	const [isExpanded, setIsExpanded] = useState(false);
	const [contentHeight, setContentHeight] = useState(0);
	const contentRef = useRef(null);

	const value = Form.useFormContext().watch(name);
	const countryOptions = useCountryOptions();

	const toggleExpand = () => {
		setIsExpanded((prev) => !prev);
	};

	const updateHeight = () => {
		if (contentRef.current) {
			setContentHeight(contentRef.current.scrollHeight);
		}
	};

	useEffect(() => {
		// Use MutationObserver to watch for changes in the content (e.g., filtering results)
		const observer = new MutationObserver(updateHeight);
		if (contentRef.current) {
			observer.observe(contentRef.current, { childList: true, subtree: true });
			updateHeight();
		}
		return () => observer.disconnect();
	}, [countryOptions]);

	return (
		<Segment>
			<Header
				as="h4"
				style={{
					display: "flex",
					alignItems: "center",
					marginBottom: isExpanded ? "10px" : 0,
					cursor: "pointer",
				}}
				onClick={toggleExpand}
			>
				{t("countries", "Countries")}
				<Flex justifyContent="flex-end">
					<Icon link name={isExpanded ? "angle up" : "circular angle down"} circular fitted />
				</Flex>
			</Header>

			<div
				style={{
					overflow: "hidden",
					transform: isExpanded ? "scaleY(1)" : "scaleY(0)",
					transformOrigin: "top",
					maxHeight: isExpanded ? `${contentHeight}px` : "0px",
					opacity: isExpanded ? 1 : 0,
					transition: "transform 0.3s ease, opacity 0.3s ease, max-height 0.3s ease",
					willChange: "transform, opacity, max-height",
				}}
			>
				<div ref={contentRef}>
					<Form.FormCheckboxTable
						name={name}
						value={value.includes("WORLD") ? countryOptions.map(({ value }) => value) : value}
						options={countryOptions}
						transformValue={(value) => {
							return value.length === countryOptions.length ? ["WORLD"] : value;
						}}
					/>
				</div>
			</div>
		</Segment>
	);
}
