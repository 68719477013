import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import softDeleteDocument from "astrid-firestore/src/api/documents/softDeleteDocument";

import { useProfile } from "../../authentication/state/profile";

export default function useDistributionChannelDropdownMenuOptions(data) {
	const user = useProfile();
	const { t } = useTranslation();

	return [
		{
			as: "a",
			text: t("view", "View"),
			href: `/admin/distributions/channels/${data.id}`,
		},
		{
			text: t("delete", "Delete"),
			action: async () => {
				await softDeleteDocument(firebase, data.ref, user);
			},
		},
	];
}
