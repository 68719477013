import createDocument from "../../utils/createDocument";
import createDocumentData from "../../utils/createDocumentData";

import applyArticlePresets from "./applyArticlePresets";
import validateArticleData from "./validateArticleData";

export default async function createArticle(firebase, data) {
	const articleWithPresets = await applyArticlePresets(firebase, { article: { ...data }, publisher: data.publisher });
	const article = await validateArticleData(createDocumentData(firebase, "articles", articleWithPresets));
	return createDocument(article);
}
