export function getOrganizationPath(path) {
	if (!path.startsWith("/")) {
		return path;
	}

	const { pathname } = window.location;

	const [, type, id] = pathname.split("/");

	if (type === "admin") {
		return `/admin${path}`;
	}

	if (["publisher", "producer", "narrator"].includes(type)) {
		return `/${type}/${id}${path}`;
	}

	return path;
}
