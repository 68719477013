import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { array, nullable, object, ref, string } from "../../../types/_types";

import { OrganizationRef } from "../../organizations/types/OrganizationRef";

import { Season } from "./Season";

export const Serie = FirestoreDocument.extend({
	name: string(3),
	seasons: array(Season),
	description: nullable(string()),
	publisher: OrganizationRef,
	articleIds: array(string()),
}).merge(FirestoreLifecycle);

export const SerieRef = ref(Serie, {
	name: true,
	description: true,
});
