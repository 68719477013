import firebase from "firebase/app";

import React from "react";
import { useTranslation } from "react-i18next";

import createDiscussionChannel from "astrid-firestore/src/api/discussion/createDiscussionChannel";
import { DiscussionChannel } from "astrid-firestore/src/api/discussion/types/DiscussionChannel";
import updateDiscussionChannel from "astrid-firestore/src/api/discussion/updateDiscussionChannel";

import { useProfile } from "../../../authentication/state/profile";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";
import TeamRoleSelect from "../../../team/components/TeamRoleSelect/TeamRoleSelect";

import useDiscussionChannelUserOptions from "../../hooks/useDiscussionChannelUserOptions";

export default function DiscussionChannelForm({ onClose, channel, production }) {
	const { t } = useTranslation();
	const user = useProfile();

	const userOptions = useDiscussionChannelUserOptions({
		production,
	});

	const { form, onSubmit, error } = useSimpleForm({
		schema: DiscussionChannel,
		values: {
			name: channel?.name || "",
			userIds: channel?.userIds || [],
			teamRoles: channel?.teamRoles || [],
		},
		onSubmit: ({ name, userIds, teamRoles }) => {
			const users = userOptions.map(({ data }) => data).filter(({ id }) => userIds.includes(id));

			const data = {
				name,
				userIds,
				users,
				teamRoles,
			};

			if (channel) {
				return updateDiscussionChannel(firebase, {
					ref: channel.ref,
					...data,
				});
			}

			return createDiscussionChannel(firebase, {
				subject: {
					id: production.id,
					name: production.title,
					ref: `/productions/${production.id}`,
				},
				seenByUserIds: [user.id],
				...data,
			});
		},
		onSuccess: onClose,
	});

	return (
		<Form.Modal
			header={channel ? t("editChannel", "Edit channel") : t("createChannel", "Create channel")}
			size="tiny"
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			<Form.Input name="name" label={t("channelName", "Channel name")} />

			<Form.Group widths="equal">
				<Form.Select
					name="userIds"
					multiple
					options={userOptions}
					label={t("addParticipantByUser", "Add participant by user")}
				/>

				<Form.Field name="teamRoles">
					<TeamRoleSelect
						multiple
						label={t("addParticipantByRole", "Add participant by role")}
						includeReader
					/>
				</Form.Field>
			</Form.Group>
		</Form.Modal>
	);
}
