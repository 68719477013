import { Segment, Table as SemanticTable } from "semantic-ui-react";

import { useEffect } from "react";

import TableBody from "./components/TableBody";
import TableFooter from "./components/TableFooter";
import TableHeader from "./components/TableHeader";
import TablePagination from "./components/TablePagination";
import useMappedColumns from "./hooks/useMappedColumns";
import usePersist from "./hooks/usePersist";
import usePlugins from "./hooks/usePlugins";
import useTable from "./hooks/useTable";

export default function Table({
	autoResetSelectedRows,
	children,
	columns = [],
	data,
	expandable,
	filterable = false,
	filters,
	footer,
	getSubRows,
	header = true,
	hiddenColumns,
	hoverRow = false,
	initialState,
	onClickRow,
	onRemoveRow,
	onSelectRows,
	onSelectedRowIds,
	pageSize,
	pagination = false,
	persist = false,
	removable = false,
	selectable = false,
	selectedRowIds,
	sortBy,
	sortable = false,
	...props
}) {
	const table = useTable({
		data,
		sortBy,
		filters,
		initialState,
		hiddenColumns,
		pageSize,
		getSubRows,
		selectedRowIds,
		autoResetSelectedRows,
		columns: useMappedColumns(columns),
		plugins: usePlugins({
			sortable,
			removable,
			filterable,
			selectable,
			pagination,
			expandable,
			onRemoveRow,
		}),
	});

	const { pageCount, getTableProps, selectedFlatRows, state } = table;

	usePersist({ table, persist });

	useEffect(() => {
		if (selectable && onSelectRows) {
			onSelectRows(selectedFlatRows.map(({ original }) => original));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFlatRows?.length]);

	useEffect(() => {
		if (selectable && onSelectedRowIds) {
			onSelectedRowIds(state.selectedRowIds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.selectedRowIds]);

	const showFooter = footer !== false || selectable;

	return (
		<>
			<Segment style={{ padding: 0, overflowX: "auto", scrollbarWidth: "thin" }}>
				<SemanticTable
					padded
					unstackable
					selectable={hoverRow || onClickRow}
					style={{ border: "none" }}
					{...getTableProps()}
					{...props}
				>
					{header && <TableHeader table={table} />}

					<TableBody table={table} pagination={pagination} onClickRow={onClickRow}>
						{children}
					</TableBody>

					{pagination && pageCount > 1 && <TablePagination table={table} />}
				</SemanticTable>
			</Segment>

			{showFooter && (
				<TableFooter table={table} selectable={selectable}>
					{footer}
				</TableFooter>
			)}
		</>
	);
}

Table.Row = SemanticTable.Row;
Table.Cell = SemanticTable.Cell;
