import useIntersection from "react-use/lib/useIntersection";

export default function useInViewport(ref) {
	const intersection = useIntersection(ref, {
		root: null,
		rootMargin: "0px",
		threshold: 1,
	});

	return intersection?.intersectionRatio >= 1;
}
