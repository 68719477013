import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import featureFlags from "../../authorization/constants/featureFlags";

import AdminArticleView from "../views/AdminArticleView/AdminArticleView";

export default function Article() {
	const { organization, article } = useOutletContext();

	return (
		<LoadingContext featureFlag={featureFlags.FLATTENING}>
			<AdminArticleView organization={organization} article={article}>
				<Outlet context={{ organization, article }} />
			</AdminArticleView>
		</LoadingContext>
	);
}
