import { Checkbox } from "semantic-ui-react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
	canDeliverArticlesToChannels,
	disableDeliveryWithoutMetadata,
} from "astrid-firestore/src/api/distribution/utils/deliverArticlesToChannels";

import FlexColumn from "../../../../../components/Flex/FlexColumn";
import Form from "../../../../forms/components/Form/Form";
import Popup from "../../../../ui/components/Popup/Popup";

function deliveryColumn({ articles, channels, selectOrDeselectAll, deliveryType, Header }) {
	return {
		id: deliveryType,
		Header: (
			<FlexColumn style={{ alignItems: "center", gap: 5 }}>
				{Header}
				<Checkbox
					defaultChecked={true}
					slider
					onClick={(_, { checked }) => {
						selectOrDeselectAll({ articles, channels, deliveryType, checked });
					}}
				/>
			</FlexColumn>
		),
		textAlign: "center",
		Cell: ({ row }) => {
			const { t } = useTranslation();
			const disabled = !canDeliverArticlesToChannels({
				articles,
				deliveryType,
				channel: row.original,
			});
			const disableUncheckOfMetadata = disableDeliveryWithoutMetadata({
				deliveryType,
				channel: row.original,
			});

			return disableUncheckOfMetadata ? (
				<Popup
					content={disableUncheckOfMetadata && t("mandatory", "Mandatory")}
					trigger={
						<Form.Checkbox
							name={`delivery.${row.original.id}.${deliveryType}`}
							slider
							disabled={disabled}
							readOnly={disableUncheckOfMetadata}
						/>
					}
				/>
			) : (
				<Form.Checkbox name={`delivery.${row.original.id}.${deliveryType}`} slider disabled={disabled} />
			);
		},
	};
}

export default function useDistributeArticlesChannelsTableColumns({ articles, channels, selectOrDeselectAll }) {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "name",
				Header: t("channel", "Channel"),
			},
			deliveryColumn({
				articles,
				channels,
				selectOrDeselectAll,
				deliveryType: "artifact",
				Header: t("artifact", "Artifact"),
			}),
			deliveryColumn({
				articles,
				channels,
				selectOrDeselectAll,
				deliveryType: "metadata",
				Header: t("metadata", "Metadata"),
			}),
		],
		[articles, channels, selectOrDeselectAll, t],
	);
}
