import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminStudiosView from "../views/AdminStudiosView/AdminStudiosView";

export default function AdminStudios() {
	const hasRight = useHasRight();

	const { organization } = useOutletContext();

	if (!hasRight("studios.view")) {
		return <Unauthorized />;
	}

	return <AdminStudiosView organization={organization} />;
}
