import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import get from "lodash/get";
import memoize from "lodash/memoize";
import trimStart from "lodash/trimStart";
import moment from "moment/min/moment-with-locales";

import { Button, Divider, Form, Header, Icon, Loader, Popup } from "semantic-ui-react";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import distConfig from "astrid-config/src/distributors";
import { base, db, firebase, newUser, notifyUsers } from "astrid-firebase";
import addEvent from "astrid-firebase/src/utils";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import getCollectionDataById from "astrid-firestore/src/utils/getCollectionDataById";
import nameCase from "astrid-helpers/src/nameCase";
import DeliveryDatesPopup from "astrid-web/src/components/DeliveryDatesPopup";
import SettingsPopup from "astrid-web/src/components/SettingsPopup";
import { withStore } from "astrid-web/src/helpers/context";
import { durationToSec, secToDuration, toDate } from "astrid-web/src/helpers/fnc";

import AdminCard from "../../../features/admin/components/AdminCard/AdminCard";
import { getProfile } from "../../../features/authentication/state/profile";
import hasLanguageOptions from "../../../features/language/hasLanguageOptions";
import ProductionOfferFormButton from "../../../features/productions/components/ProductionOfferForm/ProductionOfferFormButton";
import ProductionWorkGroup from "../../../features/productions/components/ProductionWorkGroup/ProductionWorkGroup";
import DateTime from "../../../features/ui/components/DateInput/DateTime";

import DocTitle from "../../DocTitle";
import PublisherBlurbs from "../../publisher/PublisherBlurbs";

import MasterExportModal from "../master/MasterExportModal";

import { withMetaGuide } from "./MetaGuide";
import MigrateProduction from "./MigrateProduction";
import ProductionArticlesOld from "./ProductionArticlesOld";
import ProductionAuthorContact from "./ProductionAuthorContact";
import ProductionAuthorContactEmail from "./ProductionAuthorContactEmail";
import ProductionCategory from "./ProductionCategory";
import ProductionDelivery from "./ProductionDelivery";
import ProductionMeta from "./ProductionMeta";
import ProductionProof from "./ProductionProof";
import ProductionProofScript from "./ProductionProofScript";
import ProductionScheduled from "./ProductionScheduled";
import ProductionSessions from "./ProductionSessions";
import ProductionStatus from "./ProductionStatus";
import ProductionTags from "./ProductionTags";
import ProductionWords from "./ProductionWords";

class ProductionDetails extends Component {
	state = {
		production: null,
		loading: false,
		publisher: {},
		producer: {},
		historyModal: false,
		productionRights: [],
		deliveryDuration: null,
		distributors: {},
		productionType: null,
		authorContact: {},
	};

	componentDidMount() {
		const { permissions } = this.props;
		const { isReader, isProducer, isPublisher, rights } = permissions;

		this.setState({ isReader, isProducer, isPublisher, productionRights: rights });

		this.getDistributors();

		if (isProducer) this.getPeople();

		// get organizations
		this.props.store.getOrganizations();

		// get users
		this.props.store.getUsers();
	}

	getDistributors = ({ publisher, producer, language } = {}) => {
		// check if publisher has access to distributor (in the cloud, for privacy)
		const { production } = this.props;

		if (producer || production.producer) {
			const publisherHasDistributors = firebase.functions().httpsCallable("publisherHasDistributors");
			this.setState({ distributors: false });
			publisherHasDistributors({
				producer: producer || production.producer,
				publisher: publisher || production.publisher,
				language: language || production.language,
			}).then(({ data }) => {
				this.setState({ distributors: data });
			});
		}
	};

	getPeople = () => {
		const { production } = this.props;
		const people = [...(production.author || []), ...(production.translator || [])];

		if (people.length) {
			getCollectionDataById(firebase, db.collection("people"), people).then((people) => {
				const data = {};

				people.forEach((person) => {
					data[person.id] = person;
				});

				this.setState({ people: data });
			});
		}
	};

	updateFirestore = (ref, data) => {
		// update database
		base.updateDoc(ref, {
			...data,
			updated: firebase.firestore.FieldValue.serverTimestamp(),
		})
			.then(() => {
				const user = getProfile();
				// store event in subcollection for blaming
				addEvent({
					productionId: this.props.production.id,
					user: user.id,
					email: user.email,
					data,
				});
			})
			.catch((err) => {
				console.log("Production data update error", err);
			});
	};

	debouncedUpdate = debounce((ref, data) => {
		// debounced method for keystrokes
		this.updateFirestore(ref, data);
	}, 1500);

	deliveryDateCheck = (oldDate, newDate) => {
		const { t, production } = this.props;
		// publishers can't change delivery dates after production is accepted
		if (
			(!production.productionType || production.productionType === "subcontract") &&
			this.state.isPublisher &&
			["accepted", "production", "done"].includes(production.status) &&
			oldDate > newDate
		) {
			window.alert(t("contactProdCompToChange"));
			return true;
		}
	};

	handleChange = (e, data, { log, metadata } = {}) => {
		// put single value in array
		if (!Array.isArray(data)) data = [data];

		// clone current data
		const oldData = cloneDeep(this.props.production);
		const newData = {};

		// loop all data fields
		data.forEach((dataPiece) => {
			const field = dataPiece.name;

			// don't store empty arrays
			if (Array.isArray(dataPiece.value) && !dataPiece.value.length) dataPiece.value = null;

			// convert to number if necessary
			if (dataPiece.type === "number" && dataPiece.value !== "") {
				dataPiece.value = +dataPiece.value;
				if (oldData[field] !== undefined) oldData[field] = +oldData[field];
			} else if (dataPiece.type === "date") {
				// convert to date if necessary
				const dateString = dataPiece.value;
				dataPiece.value = dateString ? new Date(dateString) : null;

				// publishers can't change delivery dates after production is accepted
				if (field === "deliveryDate" && this.deliveryDateCheck(toDate(oldData[field]), dataPiece.value)) return;

				// invalid, probably 30 feb or 31 april or similar, set to first of next month
				if (!Number.isInteger(+dataPiece.value)) {
					dataPiece.value = moment(oldData[field].toDate()).add(1, "M").startOf("month").toDate();
				}
			} else if (typeof dataPiece.value === "string") {
				// trim space from start for strings (and end on blur)
				dataPiece.value = e && e.type === "blur" ? dataPiece.value.trim() : trimStart(dataPiece.value);
			}

			if (field === "subcontractor" && !dataPiece.value) {
				newData.subcontractorStatus = "";
			} else if (field === "subcontractor" && dataPiece.value) {
				newData.subcontractorStatus = "assigned";
				if (!oldData.proofer || oldData.proofer.length === 0) {
					newData.proofer = ["NONE"];
				}
			}

			// if reader, add assigned production manager
			if (field === "reader") {
				let readers = dataPiece.value;

				// add single reader to array
				if (typeof readers === "string") {
					readers = [...(oldData.reader || [])];
					readers.push(dataPiece.value);
					dataPiece.value = readers;
				}

				if (Array.isArray(readers)) {
					// if array, look for managers (if it is not array, it is an array remove or union operation)
					const manager = readers.reduce((prev, curr) => {
						const readerManager = get(
							this.props.store,
							"state.users." + curr + ".readerData.producerSettings." + oldData.producer + ".manager",
						);

						if (readerManager) prev.push(readerManager);

						return prev;
					}, []);

					// if managers found, merge to unique array
					if (manager.length) newData.manager = [...new Set([...(oldData.manager || []), ...manager])];
				}
			}

			// unsnooze?
			if (oldData.statusPlanningSnooze && ["deliveryDate", "reader"].includes(field))
				newData.statusPlanningSnooze = null;

			//if adding Proofer to "no proof production", remove no proof

			if (field === "proofer" && dataPiece?.value?.length > 1 && oldData?.proofer?.includes("NONE")) {
				dataPiece.value = dataPiece.value.filter((proofer) => proofer !== "NONE");
			}

			// if value changed, update it
			if (dataPiece.value !== oldData[field] && (dataPiece.value !== "" || oldData[field] || field === "isbn")) {
				newData[field] = dataPiece.value;

				// set total article metadata update date
				if (field === "isbn") newData.metaUpdatedTotalArticle = firebase.firestore.FieldValue.serverTimestamp();

				// get distributors again?
				if (["language", "publisher"].includes(field)) this.getDistributors({ [field]: dataPiece.value });
			}
		});

		// if nothing changed, bail
		if (!Object.keys(newData).length) return;

		// metadata timestamp, used to keep track of distributor metadata expiration
		if (metadata) newData.metaUpdated = firebase.firestore.FieldValue.serverTimestamp();

		if (log) {
			// log instead?
			console.log(newData);
		} else if (e) {
			// debounce browser event updates (keystrokes)
			this.debouncedUpdate("productions/" + this.props.production.id, { ...newData });
		} else {
			// update immediately
			this.updateFirestore("productions/" + this.props.production.id, { ...newData });
		}
	};

	updateStatus = (status) => {
		const { production } = this.props;
		const producerId = production.producer || "HfvG4FQfXWpWv6dzqM5E";
		const { t } = this.props;

		// automatic delivery will be updated from server, just set other status info
		const statusData = [{ name: "status", value: status }];

		if (status === "offer" || (production.productionType && production.productionType !== "subcontract")) {
			if (production.productionType)
				statusData.push({ name: "productionType", value: production.productionType });

			// get dist now that we know what producer
			this.getDistributors({ producer: producerId });
		} else if (status === "accepted" || status === "planning") {
			// TODO: implement in new offer flow
			// check if there is a reader
			if (!production.manager && production.reader) {
				// check if that reader has a production manager
				const manager = (production.reader || []).reduce((prev, curr) => {
					const readerManager = get(
						this.props.store,
						"state.users." + curr + ".readerData.producerSettings." + production.producer + ".manager",
					);

					if (readerManager) prev.push(readerManager);

					return prev;
				}, []);

				// store it
				if (manager && manager.length) statusData.push({ name: "manager", value: manager });
			}
		} else if (status === "done") {
			// store duration
			let duration;
			if (this.state.deliveryDuration) {
				// get from state (delivery input field)
				duration = this.state.deliveryDuration;
			} else if (production.deliveryParts) {
				// get from parts
				duration = production.deliveryParts.reduce(
					(prev, curr) => (curr.duration ? durationToSec(curr.duration) + prev : prev),
					0,
				);

				duration = secToDuration(duration);
			}
			if (duration) statusData.push({ name: "deliveryDuration", value: duration });
			statusData.push({ name: "deliveryDateActual", value: firebase.firestore.FieldValue.serverTimestamp() });

			// store message
			const message = document.querySelector("#deliveryComment").value;
			if (message) statusData.push({ name: "deliveryMessage", value: message });

			if (!production.readerBillEmail) {
				// notify readers that are accepted and have a fee of anything but 0
				const readers =
					production.reader &&
					production.readerStatus &&
					production.reader.filter(
						(id) =>
							production.readerStatus[id]?.status === "accepted" &&
							(production.readerStatus[id]?.unit === "none" || production.readerStatus[id]?.fee > 0),
					);

				if (readers?.length === 1) {
					const subject = t("billingMessage") + production.title;
					const message = t("readerEmail", {
						title: production.title,
						link: `https://astrid.fm/production/${production.id}/audiobook`,
					});
					notifyUsers(readers, this.props.store.state.users, subject, message);
					statusData.push({ name: "readerBillEmail", value: true });
				}
			}
		}

		this.handleChange(null, statusData);

		/* if (readers?.length) {
          const subject = t("billingMessage") + production.title;
          const message = `Nu har ljudboksproduktionen "${production.title}" levererats och genom att följa länken nedan så hittar du speltid, summa och faktureringsuppgifter till
          ${production.readerContractedByProducer ? "Earselect AB"
              : get(this,
                "props.store.state.organizations[" + production.publisher + "].name",
                "förlaget",
                )
          }:
          https://astrid.fm/production/${this.props.match.params.productionId}/audiobook
          mvh Astrid`;
          notifyUsers(readers, this.props.store.state.users, subject, message);
          statusData.push({ name: "readerBillEmail", value: true });
        } */
		// notify publisher and producer admins
		// TODO: figure out why so many duplicates?
		let notifyRecipients = [
			...Object.values(this.props.store.state.users).filter(
				(user) =>
					user.id !== this.props.user.uid &&
					user.permissions &&
					((user.permissions.producer &&
						user.permissions.producer[production.producer] &&
						user.permissions.producer[production.producer].includes("producerAdmin") &&
						hasLanguageOptions({
							user,
							orgId: production.producer,
							orgType: organizationTypes.PRODUCER,
							languageToMatch: production.language,
						})) ||
						(user.permissions.publisher &&
							user.permissions.publisher[production.publisher] &&
							hasLanguageOptions({
								user,
								orgId: production.publisher,
								orgType: organizationTypes.PUBLISHER,
								languageToMatch: production.language,
							}))),
			),
		];

		const subject = t("newStatus") + production.title + ": " + t(status);
		const prodLink = `https://${window.ES.stage ? "stage." : ""}astrid.fm${window.location.pathname}`;
		const message = t("recipientEmail", {
			title: production.title,
			status: t(status),
			link: prodLink,
		});
		notifyUsers(notifyRecipients, this.props.store.state.users, subject, message);
	};

	updateProductionsStats = (stats) => {
		// store in database if not set or new stats
		const oldStats = this.props.production.stats;
		if (
			stats === null ||
			!oldStats ||
			!oldStats.pages ||
			oldStats?.pages?.total !== stats.pages.total ||
			oldStats?.loss?.total !== stats.loss.total ||
			oldStats?.ratio?.total !== stats.ratio.total ||
			oldStats?.tempo?.total !== stats.tempo.total
		) {
			this.handleChange(null, [{ name: "stats", value: stats }]);
		}
	};

	addReader = (e, data) => {
		// separate name, use last part as last name
		const trimmedNameString = data.value.trim();
		const nameParts = trimmedNameString.split(" ");
		const lastName = nameParts.pop().trim();
		const firstName = nameParts.join(" ").trim();
		const { t, production } = this.props;

		// check if reader exists
		const hit = Object.values(this.props.store.state.users).find(
			(user) =>
				user.firstName.toLowerCase() === firstName.toLowerCase() &&
				user.lastName.toLowerCase() === lastName.toLowerCase(),
		);

		if (hit) {
			// he/she does
			const readerProfile = this.props.store.state.users[hit.id];
			const readers = [...(production.reader || [])];

			if (!readerProfile?.languages?.[production?.language]) {
				// remove
				readers.splice(readers.indexOf(data.value), 1);
				this.handleChange(null, { name: data.name, value: readers });

				window.alert(t("readerWrongLanguage"));
				return;
			} else {
				// add hit

				// use existing index if available
				let index = readers.indexOf(data.value);
				if (index < 0) index = 0;
				readers[index] = hit.id;

				this.handleChange(null, { name: data.name, value: readers });
			}
		} else {
			// create new user
			const dummyEmail =
				"dummy-" +
				data.value
					.toString()
					.toLowerCase()
					.replace(/\s+/g, "_") // Replace spaces with -
					.replace(/[^\w-]+/g, "") + // Remove all non-word chars
				(Math.random() + "").substr(4, 3) + // some randomness
				"@earselect.se";

			newUser(this, nameCase(firstName), nameCase(lastName), dummyEmail, (user) => {
				// replace name value with id
				const readers = [...(production?.reader || []), user.uid];
				this.handleChange(null, { name: data.name, value: readers });
				this.props.store.getUsers();
			});
		}
	};

	confirmInformation = (check) => {
		const { t, production } = this.props;

		if (
			(!production.productionType || production.productionType === "subcontract") &&
			Object.values(this.state.authorContact).find(
				(person) =>
					(!person.ignore && !person.email && !person.phone) || person.phoneInvalid || person.emailInvalid,
			)
		) {
			window.alert(t("specifyOrConfirmDetails"));
		} else if (
			check !== "readerSearch" &&
			production.language &&
			production.languageExtra &&
			production.language !== production.languageOriginal &&
			!(production.translator && production.translator.length)
		) {
			window.alert(t("mustSpecifyTranslator"));
		} else if (check === "readerSearch" && !production.genre) {
			window.alert(t("mustChooseGenre"));
		} else {
			return true;
		}

		return false;
	};

	renderMeta = memoize(
		(pub, meta) =>
			pub ? (
				<ProductionMeta
					production={this.props.production}
					productionId={this.props.production.id}
					isProducer={this.state.isProducer}
					publisher={pub}
					handleChange={this.handleChange}
				/>
			) : null,
		(...args) => JSON.stringify(args),
	);

	renderWords = () => (
		<>
			<Divider />
			<ProductionWords
				production={this.props.production}
				productionId={this.props.production.id}
				isProducer={this.state.isProducer}
				isReader={this.state.isReader}
				profile={this.props.profile}
				uid={this.props.user.uid}
				userMap={this.props.store.state.users}
				handleChange={this.handleChange}
			/>
		</>
	);

	renderBlurbs = memoize(
		(pub, disabled) =>
			pub ? (
				<>
					<PublisherBlurbs
						disabled={disabled}
						pid={this.props.production.id}
						publisher={pub}
						production={this.props.production}
					/>
				</>
			) : null,
		(...args) => JSON.stringify(args),
	);

	render() {
		const {
			hasSessions,
			productionRights,
			isReader,
			isProducer,
			isPublisher,
			deliveryDuration,
			distributors,
			authorContact,
			people,
			newProducer,
		} = this.state;

		const {
			t,
			user,
			profile,
			store,
			checkMissing,
			closeMetaGuide,
			history,
			match,
			alerts,
			production,
			publisher,
			producer,
		} = this.props;

		let { tab, productionId } = match.params;

		//filter out producers available
		const availableProducers = [];
		if (publisher?.producerIds) {
			for (const producerId of publisher?.producerIds) {
				if (store.state?.organizations) {
					availableProducers.push({
						id: producerId,
						name: store.state?.organizations[producerId]?.name,
					});
				}
			}
		}

		const lockDetails = !!(
			production &&
			["accepted", "production", "done"].includes(production.status) &&
			(isPublisher || !productionRights.includes("createProduction"))
		);

		const isAdmin = isProducer?.includes?.("producerAdmin");
		const isProducerStaff = isProducer?.includes?.("producerStaff");
		const isAdminOrStaff = isAdmin || isProducerStaff;
		const isManager = production?.manager?.includes(user.uid);
		const isSubcontractor = production?.subcontractor?.includes(user.uid);

		const hasExternalUpload =
			publisher &&
			publisher.distributionOptions &&
			Object.values(publisher.distributionOptions).find(
				(opt) =>
					opt.languages &&
					opt.languages.includes(production.language || "sv") &&
					get(opt, "settings.external.upload.password"),
			);

		// parts duration
		let totalPartDuration;
		if (production && production.deliveryParts) {
			totalPartDuration = production.deliveryParts.reduce(
				(prev, curr) => (curr.duration ? durationToSec(curr.duration) + prev : prev),
				0,
			);
		}

		// extra metadata fields for distribution
		const extraMetaFields =
			distributors &&
			Object.entries(distConfig)
				.filter(([dist, { meta, disabled }]) => distributors[dist] && meta && meta.sharedFields && !disabled)
				.reduce((prev, curr) => {
					const fields = (curr[1].meta && curr[1].meta.sharedFields) || [];
					return [...prev, ...fields];
				}, []);

		return production ? (
			<>
				<DocTitle title={production.title} />
				{tab === "audiobook" &&
					(typeof production.isbn === "string" ||
					get(production, "deliveryParts.length") ||
					production.deliveryCD ||
					production.deliveryMP3CD ? (
						<div style={{ marginBottom: "-20px", padding: 20 }}>
							<ProductionStatus
								isProducer={isProducer}
								isPublisher={isPublisher}
								isReader={isReader}
								publisher={publisher}
								producer={producer}
								production={production}
								user={user}
								handleChange={this.handleChange}
							/>

							{/* Producer accept offer */}
							{isProducer &&
								productionRights.includes("productionStatus.edit") &&
								production.status === "offer" && (
									<>
										{people && (
											<ProductionAuthorContactEmail
												instructions={producer?.authorInstructions}
												people={people}
												production={production}
												productionId={productionId}
											/>
										)}
										{production.producer && publisher && (
											<ProductionOfferFormButton
												production={{
													...production,
													id: productionId,
													ref: db.collection("productions").doc(productionId),
												}}
												afterSubmit={() => this.updateStatus("planning")}
											/>
										)}{" "}
									</>
								)}
						</div>
					) : (
						<span
							style={{
								verticalAlign: "-webkit-baseline-middle",
							}}
						>
							{publisher && publisher.name}
						</span>
					))}
				<Form
					as="div" // avoid submitting on enter
				>
					{tab === "articles" && isAdmin && (
						<>
							<Form.Group widths="equal" style={{ marginBottom: 20 }}>
								<Form.Select
									fluid
									label={t("status")}
									name="status"
									value={production.status}
									options={[
										{ key: "draft", value: "draft", text: t("draft") },
										{ key: "offer", value: "offer", text: t("offer") },
										{ key: "planning", value: "planning", text: t("planning") },
										{ key: "accepted", value: "accepted", text: t("accepted") },
										{ key: "production", value: "production", text: t("producing") },
										{
											key: "done",
											value: "done",
											text: production.scheduled ? t("scheduled") : t("completed"),
										},
									]}
									onChange={(e, data) => {
										this.handleChange(null, data);
									}}
								/>
								<Form.Select
									fluid
									label={t("productionType")}
									name="productionType"
									value={production.productionType || "internal"}
									options={[
										{ key: "internal", value: "internal", text: t("internal") },
										{ key: "backlist", value: "backlist", text: t("backlist") },
										{ key: "external", value: "external", text: t("external") },
										{ key: "subcontract", value: "subcontract", text: t("subcontractor") },
									]}
									onChange={(e, data) => {
										if (data.value === "internal") data.value = null;
										this.handleChange(null, data);
									}}
								/>
							</Form.Group>
							<div>
								<Form.Group widths="2" style={{ marginBottom: 20 }}>
									<Popup
										trigger={
											<Form.Select
												fluid
												label={t("changeProducer")}
												name="changeProducer"
												value={newProducer}
												options={availableProducers.map((producer) => {
													return {
														key: producer.id,
														value: producer.id,
														text: producer.name,
													};
												})}
												onChange={(e, data) => {
													this.setState({ newProducer: data.value });
												}}
											/>
										}
										inverted
										size="mini"
										content={t("onlyProducersWithAcceptedTemplates")}
									/>

									<div style={{ paddingTop: 25 }}>
										<Button
											disabled={!newProducer || newProducer === production.producer}
											onClick={() => {
												if (window.confirm(t("confirmChangeProducer"))) {
													this.handleChange(null, {
														name: "producer",
														value: newProducer,
													});
												}
											}}
										>
											{t("applyChangeProducer")}
										</Button>
									</div>
								</Form.Group>
							</div>
						</>
					)}

					{tab === "articles" && !isAdmin && (isPublisher || isProducerStaff) && (
						<>
							<Form.Group>
								<Form.Select
									fluid
									label={t("status")}
									name="status"
									value={production.status}
									disabled={true}
									options={[
										{ key: "draft", value: "draft", text: t("draft") },
										{ key: "offer", value: "offer", text: t("offer") },
										{ key: "planning", value: "planning", text: t("planning") },
										{ key: "accepted", value: "accepted", text: t("accepted") },
										{ key: "production", value: "production", text: t("producing") },
										{
											key: "done",
											value: "done",
											text: production.scheduled ? t("scheduled") : t("completed"),
										},
									]}
									onChange={(e, data) => {
										this.handleChange(null, data);
									}}
								/>
								<Form.Select
									fluid
									label={t("productionType")}
									name="productionType"
									value={production.productionType || "internal"}
									disabled={!["draft", "offer"].includes(production.status) || !hasExternalUpload}
									options={[
										{ key: "internal", value: "internal", text: t("internal") },
										{ key: "external", value: "external", text: t("external") },
									]}
									onChange={(e, data) => {
										if (data.value === "internal") data.value = null;
										this.handleChange(null, data);
									}}
								/>
								{production.status === "draft" && (
									<div style={{ padding: 20 }}>
										<Button
											primary={!Object.keys(alerts).length}
											content={
												!production.productionType ? t("sendProdRequest") : t("createTitle")
											}
											icon="right arrow"
											labelPosition="right"
											onClick={(e) => {
												e.preventDefault();

												closeMetaGuide();

												if (
													!checkMissing({ production, extraMetaFields, history })?.length &&
													this.confirmInformation()
												) {
													if (!production.productionType && authorContact) {
														// store author contact info
														Object.entries(authorContact).forEach(([id, data]) => {
															if (!data.ignore) {
																const newData = {};
																if (data.phone) newData.phone = data.phone;
																if (data.email) newData.email = data.email;
																base.updateDoc("people/" + id, newData);
															}
														});
													}

													this.updateStatus(
														production.productionType === "external"
															? "accepted"
															: production.productionType === "backlist"
															? "done"
															: "offer",
													);
												}
											}}
										/>
									</div>
								)}
							</Form.Group>
						</>
					)}

					{tab === "articles" && productionRights.includes("createProduction") && (
						<>
							<ProductionArticlesOld
								organizations={store.state.organizations}
								handleChange={this.handleChange}
								production={production}
								distributors={distributors}
								productionRights={productionRights}
								user={user}
								alerts={alerts}
								isReader={isReader}
								isProducer={isProducer}
								isPublisher={isPublisher}
								isSubcontractor={isSubcontractor}
								updateStatus={this.updateStatus}
								addReader={this.addReader}
								addingReader={this.state.addingReader}
								userMap={store.state.users}
								publisher={publisher}
								lockDetails={lockDetails}
								productionId={productionId}
								deliveryDateCheck={this.deliveryDateCheck}
								history={history}
								openExportModal={(article, title) => {
									this.setState({ masterExportModal: article, masterExportModalTitle: title });
								}}
							/>
						</>
					)}
					{tab === "audiobook" && (
						<>
							<Divider />
							<Form.Group widths="equal">
								<ProductionTags
									value={production.productionTags || []}
									onChange={({ value }) => {
										this.updateFirestore("productions/" + this.props.match.params.productionId, {
											productionTags: value,
										});
									}}
									labelAsHeader
									disable={!isAdminOrStaff}
								/>
								{isAdmin ? (
									<ProductionCategory productionId={productionId} production={production} />
								) : (
									<Form.Field />
								)}
							</Form.Group>
						</>
					)}
					{tab === "audiobook" && isSubcontractor && (
						<>
							<ProductionArticlesOld
								handleChange={this.handleChange}
								production={production}
								distributors={distributors}
								productionRights={productionRights}
								user={user}
								alerts={alerts}
								isReader={isReader}
								isProducer={isProducer}
								isPublisher={isPublisher}
								isSubcontractor={isSubcontractor}
								updateStatus={this.updateStatus}
								addReader={this.addReader}
								addingReader={this.state.addingReader}
								userMap={store.state.users}
								publisher={publisher}
								lockDetails={lockDetails}
								productionId={productionId}
								deliveryDateCheck={this.deliveryDateCheck}
								history={history}
								openExportModal={(article, title) => {
									this.setState({ masterExportModal: article, masterExportModalTitle: title });
								}}
							/>
						</>
					)}
					{tab === "audiobook" && (
						<>
							{(isProducer || isReader) && store.state.users && (
								<>
									{isProducer &&
										(isProducer.includes("producerAdmin") ||
											isProducer.includes("producerStaff")) && (
											<>
												{production.status === "planning" && (
													<SettingsPopup>
														<Form
															size="tiny"
															as="div"
															style={{
																marginTop: "1em",
															}}
														>
															<DateTime
																inputProps={{ placeholder: t("snooze") }}
																value={
																	production.statusPlanningSnooze
																		? toDate(production.statusPlanningSnooze)
																		: ""
																}
																timeFormat={false}
																onChange={(dt) => {
																	if (typeof dt === "object") {
																		this.handleChange(null, {
																			name: "statusPlanningSnooze",
																			value: dt.toDate(),
																		});
																	}
																}}
																onBlur={(dt) => {
																	const writtenDate = dt
																		? moment(dt).format("YYYY-MM-DD")
																		: null;
																	// see if typed date is valid and not the same as currently is in the db
																	if (
																		!writtenDate ||
																		(writtenDate !== "Invalid date" &&
																			writtenDate !==
																				moment(
																					toDate(
																						production.statusPlanningSnooze,
																					),
																				).format("YYYY-MM-DD"))
																	) {
																		// valid date, save it
																		this.handleChange(null, {
																			name: "statusPlanningSnooze",
																			value: writtenDate,
																		});
																	}
																}}
															/>
														</Form>
													</SettingsPopup>
												)}
												<DeliveryDatesPopup production={production} t={t} />
											</>
										)}
									<Header as="h4" icon="calendar clock" content={t("productionDate")} />
									<Form.Group widths="equal" style={{ marginBottom: 20 }}>
										{isProducer || isReader ? (
											<div
												className={
													"field " +
													(!isAdminOrStaff ? "disabled locked-field" : "") +
													(!production.productionDate ? " error" : "")
												}
											>
												<DateTime
													value={
														production.productionDate && production.productionDate.toDate
															? moment(production.productionDate.toDate()).format(
																	"YYYY-MM-DD",
															  )
															: ""
													}
													inputProps={{
														disabled: !isAdminOrStaff,
													}}
													isValidDate={
														production.deliveryDate
															? (current) =>
																	!current.isAfter(toDate(production.deliveryDate))
															: () => true
													}
													timeFormat={false}
													onChange={(dt) => {
														if (!dt || typeof dt === "object") {
															// valid date, save it
															this.handleChange(null, {
																name: "productionDate",
																type: "date",
																value: dt ? dt.format("YYYY-MM-DD") : null,
															});
														}
													}}
													onBlur={(dt) => {
														const writtenDate = dt ? moment(dt).format("YYYY-MM-DD") : null;
														// see if typed date is empty OR valid and not the same as currently is in the db
														if (
															!writtenDate ||
															(writtenDate !== "Invalid date" &&
																production.productionDate &&
																production.productionDate.toDate &&
																writtenDate !==
																	moment(production.productionDate.toDate()).format(
																		"YYYY-MM-DD",
																	))
														) {
															// valid date, save it
															this.handleChange(null, {
																name: "productionDate",
																type: "date",
																value: writtenDate,
															});
														} else {
															console.log("Invalid date", dt);
														}
													}}
												/>
											</div>
										) : (
											!production.productionDate && (
												<span>
													<Icon name="exclamation" color="orange" />
													{t("missingProductionDate")}
												</span>
											)
										)}
									</Form.Group>
								</>
							)}
							<ProductionWorkGroup production={production} />
							{this.renderWords()}
						</>
					)}
				</Form>

				{/* Discussion and log */}
				{store.state.users && (
					<>
						<div>
							{/* Publisher send offer to producer */}
							{isPublisher && (typeof production.isbn === "string" || production.deliveryParts) && (
								<div className="clear">
									<Form as="div" style={{ padding: 20 }}>
										{tab === "audiobook" &&
											production.status === "draft" &&
											production.productionType === undefined && (
												<div style={{ marginBottom: "1em" }}>
													{
														<ProductionAuthorContact
															author={production.author}
															translator={production.translator}
															value={authorContact}
															onChange={(data) => {
																this.setState({ authorContact: data });
															}}
														/>
													}
												</div>
											)}
									</Form>
								</div>
							)}
						</div>
						<div>
							{tab === "audiobook" &&
								(isProducer || isReader) &&
								!isSubcontractor &&
								(production.status === "accepted" ||
									production.status === "production" ||
									production.status === "done") &&
								store.state.users && (
									<>
										{production.productionType !== "subcontract" && (
											<ProductionSessions
												productionId={productionId}
												production={production}
												producer={production.producer}
												publisher={production.publisher}
												profile={profile}
												user={user}
												userMap={store.state.users}
												productionRights={productionRights}
												isProducer={isProducer}
												isReader={isReader}
												canEdit={isAdminOrStaff || isManager}
												handleChange={this.handleChange}
												updateProductionsStats={this.updateProductionsStats}
												studios={producer ? producer.studios : []}
												updateStatus={this.updateStatus}
												setHasSessions={(hasSessions) => {
													this.setState({ hasSessions });
												}}
												history={history}
											/>
										)}

										{isProducer &&
											(isProducer.includes("producerAdmin") ||
												isProducer.includes("producerStaff") ||
												isProducer.includes("producerProofer") ||
												isProducer.includes("producerEditor") ||
												isProducer.includes("producerRecorder")) &&
											store.state.users && (
												<ProductionProof
													productionId={productionId}
													production={production}
													hasSessions={hasSessions}
													publisher={publisher}
													producer={producer}
													profile={profile}
													user={user}
													userMap={store.state.users}
													isProducer={isProducer}
													handleChange={this.handleChange}
													history={history}
												/>
											)}

										{isReader && production.proofScriptDone && (
											<Button
												primary
												style={{ verticalAlign: "top" }}
												content={t("fineScript")}
												icon="file text"
												labelPosition="right"
												onClick={(e) => {
													this.setState({ proofScriptModal: true });
												}}
											/>
										)}
										{this.state.proofScriptModal && (
											// proof script view only for reader
											<ProductionProofScript
												context={this}
												production={production}
												productionId={productionId}
												publisher={publisher}
												producer={producer}
												userMap={store.state.users}
												isReader
												handleChange={(e) => {
													console.log("ignore me", e);
												}}
											/>
										)}
									</>
								)}
							<div className="flex-stack half clear" style={{ padding: 20 }}>
								{(productionRights.includes("productionBlurbProofScript.edit") ||
									productionRights.includes("productionBlurbProofScript.view")) &&
									tab === "audiobook" &&
									this.renderBlurbs(
										publisher,
										isSubcontractor ||
											!productionRights.includes("productionBlurbProofScript.edit"),
									)}

								{!production.productionType && tab === "audiobook" && production.status === "draft" && (
									<div>
										<Button
											primary={!Object.keys(alerts).length}
											content={
												!production.productionType ? t("sendProdRequest") : t("createTitle")
											}
											icon="right arrow"
											labelPosition="right"
											onClick={(e) => {
												e.preventDefault();

												closeMetaGuide();

												if (
													!checkMissing({ production, extraMetaFields, history })?.length &&
													this.confirmInformation()
												) {
													if (!production.productionType && authorContact) {
														// store author contact info
														Object.entries(authorContact).forEach(([id, data]) => {
															if (!data.ignore) {
																const newData = {};
																if (data.phone) newData.phone = data.phone;
																if (data.email) newData.email = data.email;
																base.updateDoc("people/" + id, newData);
															}
														});
													}

													this.updateStatus(
														production.productionType === "external"
															? "accepted"
															: production.productionType === "backlist"
															? "done"
															: "offer",
													);
												}
											}}
										/>
									</div>
								)}

								{/* deliver project */}
								{isProducer &&
									distributors &&
									production.status !== "done" &&
									!isSubcontractor &&
									productionRights.includes("productionStatus.edit") &&
									(tab === "audiobook" || (tab === "articles" && production.productionType)) && (
										<ProductionDelivery
											productionId={productionId}
											uid={user.uid}
											profile={this.props.profile}
											production={production}
											totalPartDuration={totalPartDuration}
											deliveryDuration={deliveryDuration}
											publisher={publisher}
											distributors={distributors}
											updateStatus={this.updateStatus}
											setParentState={(data) => {
												this.setState(data);
											}}
											handleChange={this.handleChange}
										/>
									)}
								{tab === "audioboook" &&
									isProducer &&
									distributors &&
									production.status === "done" &&
									production.scheduled && (
										<ProductionScheduled
											production={production}
											publisher={publisher}
											distributors={distributors}
										/>
									)}
							</div>
						</div>
					</>
				)}
				{this.state.masterExportModal && (
					<MasterExportModal
						article={this.state.masterExportModal}
						title={this.state.masterExportModalTitle}
						production={production}
						publisher={publisher}
						productionId={productionId}
						productionRights={productionRights}
						distributors={distributors}
						uid={user.uid}
						profile={this.props.profile}
						onClose={() => {
							this.setState({ masterExportModal: false });
						}}
					/>
				)}
			</>
		) : (
			<h2>
				{this.state.noPermission ? (
					t("noPermission")
				) : (
					<div style={{ textAlign: "center", padding: 40 }}>
						<Loader active inline style={{ fontSize: ".5em" }} /> {t("loadsProduction")}
					</div>
				)}
			</h2>
		);
	}
}

export default withTranslation()(withMetaGuide(withStore(ProductionDetails)));
