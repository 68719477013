import { Input } from "semantic-ui-react";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchBox } from "react-instantsearch";

export default function InstantSearchBox(props) {
	const { t } = useTranslation();

	const { query, refine, clear } = useSearchBox();

	const [searchQuery, setSearchQuery] = useState("");

	useEffect(() => {
		if (query !== searchQuery) {
			refine(searchQuery);
		}
	}, [query, searchQuery, refine]);

	return (
		<Input
			fluid
			clearable
			placeholder={t("search", "Search") + "..."}
			onChange={(e, { value }) => setSearchQuery(value)}
			value={searchQuery}
			{...props}
		/>
	);
}
