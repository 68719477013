import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function useDiscussionChannelDropdownMenuOptions({
	setOpenEditChannelModal,
	setOpenRemoveChannelModal,
	productionId,
}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return [
		{
			key: "toProduction",
			value: "toProduction",
			text: t("toProduction", "To production"),
			onClick: () => navigate(`production/${productionId}`),
		},
		{
			key: "edit",
			value: "edit",
			text: t("edit", "Edit"),
			onClick: () => setOpenEditChannelModal(true),
		},
		{
			key: "delete",
			value: "delete",
			text: t("remove", "Remove"),
			onClick: () => setOpenRemoveChannelModal(true),
		},
	];
}
