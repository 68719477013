import createDocumentData from "../../utils/createDocumentData";
import runTransaction from "../../utils/runTransaction";

import { Imprint } from "../imprints/types/Imprint";
import { Serie } from "../series/types/Serie";

import getArticleDeliveryNeededData from "./data/getArticleDeliveryNeededData";
import { ArticleVersion } from "./types/ArticleVersion";
import validateArticleData from "./validateArticleData";

export default async function updateArticle(firebase, data, { saveVersion = false } = {}) {
	const { ref, imprint, serie, season } = data;

	// Calculate the duration of the article
	if (data.artifact?.files) {
		data.duration = data.artifact.files.reduce((acc, file) => acc + file.duration, 0);
	}

	return runTransaction(firebase, [ref, serie?.ref, imprint?.ref], async (transaction, [article, serie, imprint]) => {
		// If the imprint does not exist, create it
		if (imprint && !imprint?.exists) {
			transaction.set(imprint.ref, Imprint.parse(imprint));
		}

		// Update serie data if serie is changed or season is changed
		if (serie && (serie.id !== article.serie?.id || season?.id !== article.season?.id)) {
			const serieData = {
				articleIds: firebase.firestore.FieldValue.arrayUnion(article.id),
				seasons: serie?.seasons?.find((s) => s.id === season?.id)
					? serie.seasons
					: [...serie?.seasons, season].filter(Boolean),
			};

			if (serie?.exists) {
				transaction.update(serie.ref, Serie.partial().parse(serieData));
			} else {
				transaction.set(
					serie.ref,
					Serie.parse({
						name: data.serie.name,
						publisher: article.publisher,
						...serieData,
					}),
				);
			}
		}

		// Update serie on article. Remove article from previous selected serie
		if (article?.serie?.id && article.serie?.id !== serie?.id) {
			transaction.update(article.serie.ref, {
				articleIds: firebase.firestore.FieldValue.arrayRemove(article.id),
			});
		}

		// Save the updated article in the version history
		if (saveVersion) {
			const version = ArticleVersion.parse(
				createDocumentData(firebase, `articles/${article.id}/versions`, {
					before: article,
					after: {
						...article,
						...data,
					},
				}),
			);

			transaction.set(version.ref, version);
		}

		// Updated delivery needed status if metadata has changed
		const deliveryNeededData = getArticleDeliveryNeededData(article, data);
		if (deliveryNeededData) {
			transaction.update(article.ref, deliveryNeededData);
		}

		// Update the article with the new data
		transaction.set(article.ref, await validateArticleData(data, true), { merge: true });
	});
}
