import firebase from "firebase/app";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";
import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";
import createArticle from "astrid-firestore/src/api/articles/createArticle";
import { Article } from "astrid-firestore/src/api/articles/types/Article";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import useArticleTypeOptions from "../../hooks/useArticleTypeOptions";

export default function CreateArticleForm({ publisher, production, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const articleTypeOptions = useArticleTypeOptions();

	const { form, onSubmit, formState, error } = useSimpleForm({
		schema: Article.passthrough(),
		values: {
			name: "",
			isbn: null,
			type: null,
			language: null,
			status: articleStatuses.DRAFT,
			publisher: publisher || null,
			production: production || null,
		},
		onSubmit: (data) => {
			return createArticle(firebase, data);
		},
		onSuccess: ({ id }) => navigate(id),
	});

	return (
		<Form.Modal
			header={t("createArticle", "Create article")}
			size="tiny"
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			{!publisher && (
				<Form.CollectionSelect
					name="publisher"
					label={t("publisher", "Publisher")}
					query={db.collection("organizations").where("type", "==", organizationTypes.PUBLISHER)}
				/>
			)}

			<Form.Input name="isbn" label={t("isbn", "ISBN")} />

			<Form.Input name="name" label={t("articleName", "Article name")} />

			<Form.Select name="type" label={t("type", "Type")} options={articleTypeOptions} />

			<Form.LanguageSelect name="language" label={t("language", "Language")} />
		</Form.Modal>
	);
}
