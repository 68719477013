import { Header, Label, Segment } from "semantic-ui-react";

import React from "react";

import Flex from "astrid-components/lib/components/Layout/Flex";

import { useProfile } from "../../../../authentication/state/profile";
import truncateString from "../../../../ui/utils/truncateString";

import { setDiscussion } from "../../../state/discussion";

import DiscussionChannelUserImages from "./DiscussionChannelUserImages";

export default function DiscussionChannelListItem({ channel }) {
	const user = useProfile();

	const { users, name, subject, lastComment } = channel;

	const isUnseen = !channel.seenByUserIds.includes(user.id);

	return (
		<Segment
			vertical
			style={{ padding: 15, cursor: "pointer" }}
			onClick={() => setDiscussion(({ productionId }) => ({ isOpen: true, channelId: channel.id, productionId }))}
		>
			<Flex>
				<Flex gap={10}>
					<DiscussionChannelUserImages users={users} />

					<Header as="h4" style={{ margin: 0 }}>
						{`${truncateString(subject.name, 15)}`} / {name}
						{lastComment && (
							<Header.Subheader style={{ overflow: "hidden" }}>{`${
								lastComment.user.firstName
							}: ${truncateString(lastComment.text, 35)}`}</Header.Subheader>
						)}
					</Header>
				</Flex>

				{isUnseen && <Label color="blue" circular style={{ fontSize: 6, marginRight: 10 }} />}
			</Flex>
		</Segment>
	);
}
