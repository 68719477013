import { useTranslation } from "react-i18next";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";

import api from "../../../api";
import themeColors from "../../ui/constants/themeColors";

import useImprintArticleStatistics from "../../imprints/hooks/useImprintArticleStatistics";

export default function useArticlesStatistics({ organization }) {
	const [imprints, loading, error] = api.imprints.useAll({ publisher: organization });

	const { t } = useTranslation();

	const delivered = useImprintArticleStatistics({
		imprints,
		deliveryStatus: "delivered",
		label: t("deliveredPlural", "Delivered"),
		color: "green",
		detailColor: themeColors.GREEN,
		link: "articles/delivered",
		organization,
	});

	const deliveryNeeded = useImprintArticleStatistics({
		imprints,
		deliveryStatus: "deliveryNeeded",
		label: t("deliveryNeeded", "Delivery needed"),
		color: "orange",
		detailColor: themeColors.ORANGE,
		link: "articles/deliveryNeeded",
		organization,
	});

	const scheduled = useImprintArticleStatistics({
		imprints,
		deliveryStatus: "scheduled",
		label: t("scheduled", "Scheduled"),
		color: "blue",
		detailColor: themeColors.BLUE,
		link: "articles/scheduled",
		organization,
	});

	const drafts = useImprintArticleStatistics({
		imprints,
		deliveryStatus: articleStatuses.DRAFT,
		label: t("drafts", "Drafts"),
		color: "grey",
		detailColor: themeColors.GREY,
		link: "articles/drafts",
		organization,
	});

	const notDelivered = useImprintArticleStatistics({
		imprints,
		deliveryStatus: "notDelivered",
		label: t("notDeliveredPlural", "Not delivered"),
		link: "articles/notDelivered",
		organization,
	});

	return [[...delivered, ...scheduled, ...deliveryNeeded, ...drafts, ...notDelivered], loading, error];
}
