import { format } from "date-fns";
import Handlebars from "handlebars";

import getArticle from "./getArticle";

export function getDistributionDates(article, channel) {
	return article.channels?.[channel.id]?.distribution;
}

export function compareDistributionDateWithToday(distributionDate) {
	const distDate = new Date(distributionDate).setHours(0, 0, 0, 0);
	const today = new Date().setHours(0, 0, 0, 0);

	return distDate > today;
}

function getFormatting(formatting, defaultFormatting) {
	// Check if formatting is actually provided or if it's the options object
	if (typeof formatting === "object" && formatting.hash) {
		return defaultFormatting;
	}
	return formatting;
}

Handlebars.registerHelper("hasDate", (article, channel, dateType) => {
	return !!getDistributionDates(article, channel)?.[dateType];
});

Handlebars.registerHelper("getDateByType", (article, channel, dateType, formatting) => {
	const date = getDistributionDates(article, channel)?.[dateType];
	if (!date) {
		return "";
	}

	return format(new Date(date), getFormatting(formatting, "yyyy-MM-dd"));
});

Handlebars.registerHelper("getDateByTypeExcel", (article, channel, dateType, formatting) => {
	const date = getDistributionDates(article, channel)?.[dateType];
	if (!date) {
		return "";
	}
	const format = getFormatting(formatting, "yyyy-MM-dd");
	const finalFormat = article.language === "pl" ? "yyyymmdd" : format; // polish date format
	return format(new Date(date), finalFormat);
});

Handlebars.registerHelper(
	"getDateByTypeWithLinkedArticle",
	(articleType, article, linkedArticle, channel, dateType) => {
		const art = getArticle({ articleType, article, linkedArticle });
		return getDistributionDates(art, channel)?.[dateType];
	},
);

Handlebars.registerHelper("getFullYear", () => {
	return new Date().getFullYear();
});
