import { Button, FormField, Segment } from "semantic-ui-react";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
	defaultProductionCategory,
	productionCategories,
} from "astrid-config/src/productionCategories/productionCategories";
import { db } from "astrid-firebase";
import { Production } from "astrid-firestore/src/api/productions/types/Production";
import { useDocumentDataOnce } from "astrid-firestore/src/hooks";

import api from "../../../../api";
import Flex from "../../../../components/Flex/Flex";
import InstantSearchSelect from "../../../ui/components/InstantSearch/InstantSearchSelect";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";
import usePublisherManagerOptions, { NOT_ASSIGNED } from "../../../users/hooks/usePublisherManagerOptions";

import TitleOccupiedModal from "./components/TitleOccupiedModal";

export default function CreateProductionForm({
	articleId,
	isDraft,
	profile,
	setProducerId,
	setPublisherId,
	defaultProducerId = null,
	defaultPublisherId,
	producerOptions,
	publisherOptions,
	enableProductionCategory,
}) {
	const { t } = useTranslation(["common", "language"]);
	const navigate = useNavigate();

	const [existingProductionId, setExistingProductionId] = useState(null);

	const { form, watch, formState, setValue, error, onSubmit } = useSimpleForm({
		schema: Production,
		values: {
			articleIds: articleId ? [articleId] : [],
			title: "",
			language: "",
			status: isDraft ? "draft" : "offer",
			createdBy: profile.email,
			producerId: defaultProducerId,
			publisherId: defaultPublisherId,
			category: enableProductionCategory ? defaultProductionCategory : null,
			publisherManagers: [],
			skipTitleValidation: false,
		},
		onSubmit: ({
			articleIds,
			title,
			language,
			producerId,
			publisherId,
			publisherManagers,
			category,
			status,
			createdBy,
			skipTitleValidation,
		}) => {
			return api.productions.create({
				articleIds,
				title,
				language,
				producerId,
				publisherId,
				status,
				createdBy,
				category,
				skipTitleValidation,
				publisherManagers: publisherManagerOptions
					?.filter(({ value }) => publisherManagers.includes(value))
					.map(({ data }) => data),
			});
		},
		onSuccess: ({ id }) => navigate(`/production/${id}`),
	});

	const onCreate = () => {
		setValue("skipTitleValidation", true);
		onSubmit();
	};

	const [publisherId, producerId, selectedPublisherManagers] = watch([
		"publisherId",
		"producerId",
		"publisherManagers",
	]);

	const [publisherManagerOptions, loadingPublisherManagers] = usePublisherManagerOptions(publisherId);

	const [publisher] = useDocumentDataOnce(publisherId && db.collection("organizations").doc(publisherId));

	useEffect(() => {
		if (error?.message === "Title already in use") {
			setExistingProductionId(error.productionId);
		}
	}, [error]);

	useEffect(() => {
		setProducerId?.(producerId);
	}, [producerId, setProducerId]);

	useEffect(() => {
		setPublisherId?.(publisherId);
	}, [publisherId, setPublisherId]);

	useEffect(() => {
		setValue("language", "");
	}, [setValue, publisherId]);

	useEffect(() => {
		setValue(
			"publisherManagers",
			publisherManagerOptions?.filter((option) => option.value === profile?.id).map((option) => option.data),
		);
	}, [setValue, publisherManagerOptions, profile]);

	useEffect(() => {
		if (selectedPublisherManagers?.length > 0) {
			const lastElement = selectedPublisherManagers[selectedPublisherManagers.length - 1];

			setValue(
				"publisherManagers",
				lastElement?.id === NOT_ASSIGNED
					? [lastElement]
					: selectedPublisherManagers.filter(({ id }) => id !== NOT_ASSIGNED),
			);
		}
		// eslint-disable-next-line
	}, [selectedPublisherManagers?.length]);

	return (
		<>
			<Segment padded>
				<Form form={form}>
					<Form.Field name="articleIds" label={t("articles", "Articles")}>
						<InstantSearchSelect
							indexName="articles"
							allowEmpty
							multiple
							getOption={(data) => ({
								key: data.id,
								value: data.id,
								text: data.name,
							})}
						/>
					</Form.Field>

					<Form.Select
						name="producerId"
						disabled={producerOptions?.length <= 1}
						label={t("productionCompany", "Production company")}
						search
						deburr
						options={producerOptions}
					/>

					<Form.Select
						name="publisherId"
						disabled={publisherOptions?.length <= 1}
						label={t("publisher", "Publisher")}
						search
						deburr
						options={publisherOptions}
					/>

					<Form.DocumentSelect
						name="publisherManagers"
						label={t("publisherManager", "Publisher manager")}
						disabled={!publisherId}
						multiple
						search
						deburr
						options={publisherManagerOptions}
						loading={loadingPublisherManagers}
					/>

					<Form.Input name="title" label={t("bookTitle", "Book title")} />

					<Form.LanguageSelect
						name="language"
						disabled={!publisherId}
						label={t("language", "Language")}
						limitedOptions={publisher?.languageOptions}
						deburr
					/>

					{enableProductionCategory && (
						<FormField>
							<label>{t("productionCategory", "Production category")}</label>

							<Flex style={{ paddingTop: 5 }}>
								{productionCategories.map((category) => (
									<div key={category}>
										<Form.Radio
											name="category"
											label={category}
											disabled={!producerId}
											value={category}
										/>
									</div>
								))}
							</Flex>
						</FormField>
					)}
				</Form>
			</Segment>

			{existingProductionId && (
				<TitleOccupiedModal
					onCreate={onCreate}
					existingProductionId={existingProductionId}
					onClose={() => setExistingProductionId(null)}
				/>
			)}

			{error && <ErrorMessage error={error} />}

			<Flex justifyContent="center">
				<Button
					loading={formState.isSubmitting}
					disabled={formState.isSubmitting}
					style={{ marginTop: 15, width: 200 }}
					primary
					content={t("create")}
					onClick={onSubmit}
				/>
			</Flex>
		</>
	);
}
