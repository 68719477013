export default function getArticle({ articleType, article, linkedArticle }) {
	if (articleType === "audiobook") {
		if (article.type === "audiobook") {
			return article;
		} else if (linkedArticle?.type === "audiobook") {
			return linkedArticle;
		} else {
			return {};
		}
	} else if (articleType === "ebook") {
		if (article.type === "ebook") {
			return article;
		} else if (linkedArticle?.type === "ebook") {
			return linkedArticle;
		} else {
			return {};
		}
	} else {
		return {};
	}
}
