import runTransaction from "../../utils/runTransaction";

import { createDistributionData } from "./createDistributionData";
import { filterDistributionChannelsByArticles } from "./utils/filterDistributionChannels";

export default function distributeArticles(firebase, { publisher, articles, channels, delivery }) {
	return runTransaction(firebase, [publisher, articles, channels], (transaction, [publisher, articles, channels]) => {
		const filteredData = filterDistributionChannelsByArticles({ publisher, articles, channels });

		for (const { article, filteredChannels } of filteredData) {
			const channelIds = filteredChannels.map(({ id }) => id);

			transaction.update(article.ref, {
				channelIds: firebase.firestore.FieldValue.arrayUnion(...channelIds),
			});

			for (const channel of filteredChannels) {
				if (Object.values(delivery[channel.id]).some(Boolean)) {
					const distribution = createDistributionData(firebase, {
						publisher,
						article,
						channel,
						delivery: delivery[channel.id],
					});

					transaction.set(distribution.ref, distribution);
				}
			}
		}
	});
}
