import { Divider, Header, Icon, Label, Segment } from "semantic-ui-react";

import React from "react";

import Flex from "astrid-components/lib/components/Layout/Flex";
import { db } from "astrid-firebase";
import { useDocumentData } from "astrid-firestore/src/hooks";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import { useProfile } from "../../../../authentication/state/profile";
import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";
import truncateString from "../../../../ui/utils/truncateString";

import { setDiscussion, useDiscussion } from "../../../state/discussion";

import DiscussionChannelUserImages from "./DiscussionChannelUserImages";

export default function DiscussionProduction({ productionId }) {
	const user = useProfile();
	const { channel } = useDiscussion();

	const [production] = useDocumentData(db.collection("productions").doc(productionId));

	const [discussionChannels, loading, error] = useCollectionData(
		db.collection("discussionChannels").where("subject.id", "==", productionId).where("deleted", "==", false),
	);

	return (
		<LoadingContext data={discussionChannels} loading={loading} error={error}>
			<Flex justifyContent="flex-start" gap={10} style={{ padding: 15 }}>
				<div>
					<Icon
						name="list ul"
						fitted
						link
						disabled={channel === null}
						style={{ margin: 0 }}
						onClick={() => {
							setDiscussion({ isOpen: true, channelId: null, productionId: null });
						}}
					/>
				</div>

				<Header as="h4" style={{ margin: 0 }}>
					{production?.title}
				</Header>
			</Flex>

			<Divider style={{ margin: 0 }} />

			{discussionChannels?.map(({ users, id, name, seenByUserIds, lastComment }) => {
				const isUnseen = !seenByUserIds.includes(user.id);

				return (
					<Segment
						key={id}
						vertical
						style={{ padding: 15, cursor: "pointer" }}
						onClick={() => setDiscussion({ isOpen: true, channelId: id, productionId })}
					>
						<Flex>
							<Flex gap={10}>
								<DiscussionChannelUserImages users={users} />

								<Header as="h4" style={{ margin: 0 }}>
									{`${truncateString(name, 15)}`}
									<Header.Subheader style={{ overflow: "hidden" }}>{`${
										lastComment.user.firstName
									}: ${truncateString(lastComment.text, 35)}`}</Header.Subheader>
								</Header>
							</Flex>

							{isUnseen && <Label color="blue" circular style={{ fontSize: 6, marginRight: 10 }} />}
						</Flex>
					</Segment>
				);
			})}
		</LoadingContext>
	);
}
