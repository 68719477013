import { useTranslation } from "react-i18next";

import CreateDistributionArgsButton from "../../../distribution/components/CreateDistributionCredentials/CreateDistributionArgsButton";
import CreateDistributionPresetButton from "../../../distribution/components/CreateDistributionPreset/CreateDistributionPresetButton";
import DistributionArgsDataTable from "../../../distribution/components/DistributionCredentialsDataTable/DistributionArgsDataTable";
import DistributionPresetsDataTable from "../../../distribution/components/DistributionPresetsDataTable/DistributionPresetsDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminDistributionSettingsView({ publisher }) {
	const { t } = useTranslation();

	return (
		<>
			<AdminCard header={t("presets", "Presets")}>
				<DistributionPresetsDataTable publisher={publisher} />
				<CreateDistributionPresetButton publisher={publisher} />
			</AdminCard>

			<AdminCard header={t("overriddenDistributionArgs", "Overridden distribution args")}>
				<DistributionArgsDataTable publisher={publisher} />
				<CreateDistributionArgsButton publisher={publisher} />
			</AdminCard>
		</>
	);
}
