import setDocument from "../../utils/setDocument";
import updateDocumentData from "../../utils/updateDocumentData";

import { DiscussionChannel } from "./types/DiscussionChannel";

export default async function updateDiscussionChannel(firebase, data) {
	const discussionChannel = DiscussionChannel.partial().parse(updateDocumentData(firebase, data));

	return setDocument(discussionChannel);
}
