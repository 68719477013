import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import AdminImprint from "../../admin/pages/AdminImprint/AdminImprint";
import AdminImprintArticles from "../../admin/pages/AdminImprint/AdminImprintArticles";
import AdminImprints from "../../admin/pages/AdminImprints";

export const imprints = (
	<Route path="imprints" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("imprints", "Imprints") }}>
		<Route index element={<AdminImprints />} />

		<Route path=":imprint" element={<AdminImprint />} handle={{ crumb: ({ imprint }) => imprint?.name }}>
			<Route index element={<AdminImprintArticles />} />
		</Route>
	</Route>
);
