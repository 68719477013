import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import InstantSearch from "../../../ui/components/InstantSearch/InstantSearch";
import InstantSearchBox from "../../../ui/components/InstantSearch/InstantSearchBox";
import InstantSearchHitsDataTable from "../../../ui/components/InstantSearch/InstantSearchHitsDataTable";
import InstantSearchPagination from "../../../ui/components/InstantSearch/InstantSearchPagination";
import InstantSearchRefinements from "../../../ui/components/InstantSearch/InstantSearchRefinements";
import Menu from "../../../ui/components/Menu/Menu";
import PageView from "../../../ui/views/PageView/PageView";

import AddUserButton from "../../components/AddUser/AddUserButton";
import useUsersRefinements from "../../hooks/useUsersRefinements";
import useUsersTableColumns from "../../hooks/useUsersTableColumns";

export default function UsersView({ organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const [indexName, setIndexName] = useState("users");

	const columns = useUsersTableColumns({ organization });
	const refinements = useUsersRefinements({ organization });

	const configure = {
		filters: organization && `organizationIds:${organization.id}`,
		hitsPerPage: 10,
	};

	const options = [
		{
			text: t("users", "Users"),
			active: indexName === "users",
			onClick: () => setIndexName("users"),
		},
		{
			text: t("narrators", "Narrators"),
			active: indexName === "readers",
			onClick: () => setIndexName("readers"),
		},
	];

	return (
		<InstantSearch routing indexName={indexName} configure={configure}>
			<PageView
				header={t("users", "Users")}
				extra={<AddUserButton organization={organization} />}
				sidebar={
					<>
						{organization?.type === organizationTypes.PRODUCER && (
							<Menu secondary options={options} style={{ margin: "0 -5px 15px" }} />
						)}

						<InstantSearchBox />
						<InstantSearchRefinements refinements={refinements} />
					</>
				}
			>
				<InstantSearchHitsDataTable columns={columns} onClick={({ objectID }) => navigate(objectID)} />
				<InstantSearchPagination />
			</PageView>
		</InstantSearch>
	);
}
