import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";

import RestartStudioDropdownItem from "./items/RestartStudioDropdownItem";

export default function StudioDropdownMenu({ studio }) {
	return (
		<DropdownMenu style={{ marginLeft: "auto" }}>
			{({ setLoading }) => (
				<>
					<RestartStudioDropdownItem studio={studio} setLoading={setLoading} />
				</>
			)}
		</DropdownMenu>
	);
}
