import updateArtifact from "./updateArtifact";

export default function updateArtifactMarker(firebase, { artifact, marker, position }) {
	return updateArtifact(firebase, {
		...artifact,
		markers: [
			...artifact.markers.filter(({ id }) => id !== marker.id),
			{
				...marker,
				position,
			},
		],
	});
}
