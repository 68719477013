import firebase from "firebase/app";

import { Modal, Popup } from "semantic-ui-react";

import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import distributeArticles from "astrid-firestore/src/api/distribution/distributeArticles";
import {
	canDeliverArticlesToChannels,
	disableDeliveryWithoutMetadata,
} from "astrid-firestore/src/api/distribution/utils/deliverArticlesToChannels";
import stopPropagation from "astrid-helpers/src/stopPropagation";

import Flex from "../../../../components/Flex/Flex";
import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";
import SecondaryButton from "../../../ui/components/Buttons/SecondaryButton";
import DataTable from "../../../ui/components/DataTable/DataTable";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import useArticlesDistributionChannels from "../../hooks/useArticlesDistributionChannels";

import ArticleCoverImage from "../ArticleCoverImage/ArticleCoverImage";

import useDistributeArticlesChannelsTableColumns from "./hooks/useDistributeArticlesChannelsTableColumns";

export default function DistributeArticlesForm({ publisher, articles, channels, onClose }) {
	const { t } = useTranslation();

	const [filteredChannels, loading] = useArticlesDistributionChannels({
		publisher,
		articles,
	});

	const selectedChannels = channels || filteredChannels;
	const selectedChannelIds = selectedChannels.map(({ id }) => id);

	const { form, formState, onSubmit, setValue, error } = useSimpleForm({
		values: {
			delivery: filteredChannels.reduce(
				(acc, channel) => ({
					...acc,
					[channel.id]: {
						artifact:
							selectedChannelIds.includes(channel.id) &&
							canDeliverArticlesToChannels({
								channel,
								articles,
								deliveryType: "artifact",
							}),
						metadata:
							selectedChannelIds.includes(channel.id) &&
							canDeliverArticlesToChannels({
								channel,
								articles,
								deliveryType: "metadata",
							}),
					},
				}),
				{},
			),
		},
		transform: ({ delivery }) => {
			const channelIds = Object.keys(delivery);

			return {
				delivery,
				channels: filteredChannels.filter(({ id }) => channelIds.includes(id)),
			};
		},
		onSubmit: ({ channels, delivery }) => {
			return distributeArticles(firebase, { publisher, articles, channels, delivery });
		},
		onSuccess: onClose,
	});

	const selectOrDeselectAll = useCallback(
		({ checked, articles, channels, deliveryType }) => {
			const allSelectedOrDeselected = Object.entries(form.getValues("delivery")).reduce(
				(acc, [channelId, delivery]) => {
					const channel = channels.find((channel) => channel.id === channelId);
					const isSelectable =
						canDeliverArticlesToChannels({ articles, deliveryType, channel }) &&
						!disableDeliveryWithoutMetadata({ deliveryType, channel });

					return {
						...acc,
						[channelId]: {
							...delivery,
							[deliveryType]: isSelectable ? checked : delivery[deliveryType],
						},
					};
				},
				{},
			);
			setValue("delivery", allSelectedOrDeselected);
		},
		[form, setValue],
	);

	const columns = useDistributeArticlesChannelsTableColumns({ articles, channels, selectOrDeselectAll });

	return (
		<Modal open padded onClose={onClose} onKeyDown={stopPropagation} onClick={stopPropagation}>
			<Modal.Header>{t("distribute", "Distribute")}</Modal.Header>

			<Modal.Content>
				<Form form={form}>
					<DataTable data={filteredChannels} columns={columns} loading={loading} />

					{error && <ErrorMessage error={error} />}
				</Form>
			</Modal.Content>

			<Modal.Actions>
				<Flex gap={3}>
					{articles?.slice(0, 10).map((article) => {
						return (
							<Popup
								key={article.id}
								content={article.name}
								trigger={
									<ArticleCoverImage
										thumb
										rounded
										article={article}
										style={{ height: 35, width: 35 }}
									/>
								}
							/>
						);
					})}

					<strong style={{ marginLeft: 5 }}>{articles.length > 10 && ` (+${articles.length - 10})`}</strong>

					<Flex gap={5} justifyContent="flex-end">
						<SecondaryButton content={t("cancel", "Cancel")} onClick={onClose} />

						<PrimaryButton
							disabled={!formState.isValid || formState.isSubmitting}
							loading={formState.isSubmitting}
							onClick={onSubmit}
						>
							{t("distribute", "Distribute")}
						</PrimaryButton>
					</Flex>
				</Flex>
			</Modal.Actions>
		</Modal>
	);
}
