import { useMemo } from "react";

export default function useSeasonOptions({ serie, seriesOptions }) {
	return useMemo(() => {
		const serieData = seriesOptions.find((option) => option.value === serie?.id)?.data;
		return (
			serieData?.seasons?.map((season) => ({
				key: season.id,
				value: season.id,
				text: season.name,
				data: season,
			})) || []
		);
	}, [serie, seriesOptions]);
}
