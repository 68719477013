import firebase from "firebase/app";

import { Divider } from "semantic-ui-react";

import React, { useEffect } from "react";

import { db } from "astrid-firebase";
import updateDocument from "astrid-firestore/src/helpers/updateDocument";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import api from "../../../../../api";
import { useProfile } from "../../../../authentication/state/profile";
import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";

import Discussion from "../../../components/Discussion/Discussion";

import DiscussionChannelHeader from "./DiscussionChannelHeader";

export default function DiscussionChannel({ channelId }) {
	const user = useProfile();

	const [channel, loading, error] = useDocumentData(db.collection("discussionChannels").doc(channelId));

	const onCommentCreated = async (comment) => {
		await api.discussionChannels.update({
			ref: channel.ref,
			seenByUserIds: [user.id],
			lastComment: comment,
		});
	};

	useEffect(() => {
		if (channelId) {
			updateDocument(db.collection("discussionChannels").doc(channelId), {
				seenByUserIds: firebase.firestore.FieldValue.arrayUnion(user.id),
			});
		}
	}, [channelId, user.id]);

	return (
		<LoadingContext data={channel} loading={loading} error={error}>
			<DiscussionChannelHeader channel={channel} />

			<Divider style={{ margin: 0 }} />

			<div style={{ padding: 15 }}>
				<Discussion
					channel={channelId}
					onCommentCreated={onCommentCreated}
					// notificationRef={production.ref}
				/>
			</div>
		</LoadingContext>
	);
}
