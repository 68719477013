import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../authorization/hooks/useHasRight";
import DistributionsView from "../../distribution/views/DistributionsView/DistributionsView";

export default function SuperAdminDistributions() {
	const hasRight = useHasRight();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature || !hasRight("distributions.admin")) {
		return <Unauthorized />;
	}

	return <DistributionsView />;
}
