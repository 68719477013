import getCollectionQuery from "../../utils/getCollectionQuery";

export default function getArtifactsQuery(firebase, { publisher, article, limit }) {
	return getCollectionQuery(firebase.firestore().collection("artifacts"), {
		fields: {
			publisher,
			article,
		},
		limit,
		softDeletes: true,
	});
}
