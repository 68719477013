import { Statistic } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import formatBytes from "astrid-helpers/src/formatBytes";
import msToTime from "astrid-helpers/src/msToTime";

import Flex from "../../../../../components/Flex/Flex";

function FileStatistic({ label, value }) {
	return (
		<div>
			<Statistic size="tiny">
				<Statistic.Label style={{ marginBottom: 5 }}>{label}</Statistic.Label>
				<Statistic.Value>{value}</Statistic.Value>
			</Statistic>
		</div>
	);
}

export default function ArtifactFilesStatistics({ artifact }) {
	const { t } = useTranslation();

	const { files } = artifact;

	const size = files.reduce((acc, file) => acc + file.size, 0);

	const duration = files.reduce((acc, file) => acc + file.duration, 0);

	const hasAudio = artifact.files.some((file) => file.contentType.includes("audio"));

	return (
		<Flex width="80%" style={{ margin: "0 auto" }}>
			<FileStatistic label={t("files", "Files")} value={files.length} />

			<FileStatistic label={t("size", "Size")} value={formatBytes(size)} />

			{hasAudio && (
				<FileStatistic
					label={t("duration", "Duration")}
					value={duration ? msToTime(duration * 1000, false) : "N/A"}
				/>
			)}
		</Flex>
	);
}
