import { useTranslation } from "react-i18next";

import updateDocument from "astrid-firestore/src/helpers/updateDocument";

import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import featureFlags from "../../../../authorization/constants/featureFlags";
import useHasRole from "../../../../authorization/hooks/useHasRole";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import useUserRoleOptions from "../../../../users/hooks/useUserRoleOptions";

export default function UserPermissions({ user }) {
	const { t } = useTranslation();

	const hasRole = useHasRole();

	const form = useSimpleForm({
		defaultValues: {
			role: user.permissions?.role || "user",
			featureFlags: user.featureFlags || [],
		},
		onSubmit: ({ role, featureFlags }) => {
			return updateDocument(user.ref, {
				featureFlags,
				"permissions.role": role,
			});
		},
	});

	const roleOptions = useUserRoleOptions();

	return (
		<AdminForm header={t("permissions", "Permissions")} {...form}>
			<Form.Group widths="equal">
				<Form.Select
					name="role"
					label={t("role", "Role")}
					options={roleOptions.map((option) => ({ ...option, disabled: option.value === "owner" }))}
					disabled={user.permissions?.role === "owner"}
				/>
				<Form.Select
					name="featureFlags"
					label={t("featureFlags", "Feature flags")}
					options={[
						{
							key: featureFlags.FLATTENING,
							value: featureFlags.FLATTENING,
							text: t("flattening", "Flattening"),
						},
						{
							key: featureFlags.DISCUSSION,
							value: featureFlags.DISCUSSION,
							text: t("discussion", "Discussion"),
						},
					]}
					disabled={!hasRole("owner")}
					rules={{ required: false }}
					multiple
				/>
			</Form.Group>
		</AdminForm>
	);
}
