import { Breadcrumb } from "semantic-ui-react";

import { Link } from "react-router-dom";

export default function Breadcrumbs({ breadcrumbs }) {
	if (!breadcrumbs || breadcrumbs.length === 0) {
		return null;
	}

	return (
		<Breadcrumb
			icon={<span style={{ fontSize: 15 }}>/</span>}
			sections={breadcrumbs?.map(({ text, linkTo }, index) => ({
				key: index,
				as: linkTo ? Link : undefined,
				content: text,
				to: linkTo,
			}))}
		/>
	);
}
