import { Icon, Table } from "semantic-ui-react";

import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import Flex from "../../../../components/Flex/Flex";

export default function FormFieldArrayTable({ header, name, children, onAppend, ...props }) {
	const { watch } = useFormContext();
	const { swap, append, remove } = useFieldArray({
		name,
		...props,
	});

	const data = watch(name);

	return (
		<Table padded {...props}>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>{header}</Table.HeaderCell>
					<Table.HeaderCell collapsing>
						<Flex gap={10} justifyContent="flex-end">
							<Icon
								link
								fitted
								circular
								name="plus outline"
								onClick={() => append(onAppend ? onAppend() : {})}
							/>
						</Flex>
					</Table.HeaderCell>
				</Table.Row>
			</Table.Header>

			<Table.Body>
				{data?.map((row, index) => (
					<Table.Row key={index}>
						<Table.Cell>{children(row, index)}</Table.Cell>
						<Table.Cell>
							<Flex gap={10}>
								<Icon
									name="arrow up outline"
									fitted
									circular
									link={index !== 0}
									disabled={index === 0}
									onClick={() => swap(index, index - 1)}
								/>

								<Icon
									name="arrow down outline"
									fitted
									circular
									link={index !== data.length - 1}
									disabled={index === data.length - 1}
									onClick={() => swap(index, index + 1)}
								/>

								<Icon link fitted circular name="minus outline" onClick={() => remove(index)} />
							</Flex>
						</Table.Cell>
					</Table.Row>
				))}
			</Table.Body>
		</Table>
	);
}
