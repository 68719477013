import { useTranslation } from "react-i18next";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";

import ArticleBundlesCollectionDataTable from "../../components/ArticleBundlesCollectionDataTable/ArticleBundlesCollectionDataTable";

export default function AdminArticleArticleBundlesView({ article }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("bundles", "Bundles")}>
			<ArticleBundlesCollectionDataTable
				getQuery={(query) => query.where("articleIds", "array-contains", article.id)}
			/>
		</AdminCard>
	);
}
