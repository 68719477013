import { useTranslation } from "react-i18next";

export default function useSuperAdminSettingsMenuOptions() {
	const { t } = useTranslation();

	return [
		{
			path: "/admin/settings/channels",
			name: t("channels", "Channels"),
		},
		{
			path: "/admin/settings/appendixes",
			name: t("appendixes", "Appendixes"),
		},
	];
}
