import { Navigate } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import NotFound from "../../../components/NotFound";

import { useOrganizationId } from "../../organizations/state/organizationId";
import { useOrganizationType } from "../../organizations/state/organizationType";

export default function HomeRedirect() {
	const id = useOrganizationId();
	const type = useOrganizationType();

	if (type === organizationTypes.ADMIN) {
		return <Navigate to={`/${organizationTypes.ADMIN}`} />;
	}

	if (id) {
		return <Navigate to={`/${type}/${id}`} />;
	}

	return <NotFound />;
}
