import { Message, Segment } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useOutletContext } from "react-router-dom";

import hasNotification from "astrid-firestore/src/api/notifications/hasNotification";
import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";
import offerHasNotification from "astrid-firestore/src/api/offers/offerHasNotification";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import VerticalMenu from "../../ui/components/VerticalMenu/VerticalMenu";
import useDocTitle from "../../ui/hooks/useDocTitle";
import PageView from "../../ui/views/PageView/PageView";

import { useProfile } from "../../authentication/state/profile";
import useHasRight from "../../authorization/hooks/useHasRight";
import useOffersQuery from "../../offers/hooks/useOffersQuery";

import useMyOffersQuery from "../hooks/useMyOffersQuery";
import useOpenPositionsQuery from "../hooks/useOpenPositionsQuery";
import useProductionRequests from "../hooks/useProductionRequests";
import filterOffers from "../utils/filterOffers";
import mapProductionRequests from "../utils/mapProductionRequest";
import sortOffers from "../utils/sortOffers";

const awaitingAnswerStatuses = [offerStatuses.OFFERED, offerStatuses.SIGNING];

function countOffers({ filteredOffers, userId }) {
	return filteredOffers?.filter(
		(offer) =>
			(awaitingAnswerStatuses.includes(offer.status) && !offer.declinedUserIds?.includes?.(userId)) ||
			offerHasNotification(offer, userId),
	).length;
}

export default function MyProductions({ type }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();
	useDocTitle(t("myProductions"));

	const { organization } = useOutletContext() || {};
	const profile = useProfile();
	const orgId = organization?.id;

	const [offers = [], loadingOffers, errorOffers] = useCollectionData(
		useMyOffersQuery({ userId: profile?.id, type, orgId }),
	);

	const [sentOffers = []] = useCollectionData(useOffersQuery({ createdBy: profile }));

	const sentOffersWithNotification = sentOffers?.filter((offer) => hasNotification(offer, profile?.id)).length;

	const [openPositions = [], loadingOpenPositions, errorOpenPositions] = useCollectionData(
		useOpenPositionsQuery({
			type,
			orgId,
			user: profile,
		}),
	);
	const [productionRequests, loadingProductionRequests, errorProductionRequests] = useProductionRequests({
		orgId,
	});

	const filteredOffers = sortOffers(filterOffers({ offers: [...offers, ...openPositions], user: profile }));

	const mappedProductionRequests = mapProductionRequests({ productionRequests, orgId, profile });

	const errors = errorOffers || errorOpenPositions || errorProductionRequests;
	const loading = (loadingOffers || loadingOpenPositions || loadingProductionRequests) && !errors;

	if (errors) {
		return (
			<Segment padded="very">
				<Message error>{t("errorMessageHeader")} </Message>
			</Segment>
		);
	}

	const offerCount = countOffers({ filteredOffers, userId: profile.id });

	const options = [
		{
			path: "current",
			text: t("current"),
		},
		{
			path: "completed",
			text: t("completed"),
		},
		{
			path: "offers",
			text: t("myProductionsOffer"),
			loading,
			label: offerCount || undefined,
			hidden: type === organizationTypes.PUBLISHER,
		},
		{
			path: "sent",
			text: t("sentOffers", "Sent offers"),
			hidden: type !== organizationTypes.PRODUCER || !hasRight("offers.send"),
			label: sentOffersWithNotification || undefined,
		},
		{
			path: "requests",
			text: t("myProductionsRequests"),
			loading,
			label: mappedProductionRequests?.length || undefined,
			hidden: type !== organizationTypes.PRODUCER || !hasRight("myProductions.productionRequests"),
		},
	];

	return (
		<PageView header={t("myProductions")} sidebar={<VerticalMenu options={options} />}>
			<Outlet
				context={{
					organization,
					filteredOffers,
					mappedProductionRequests,
					loading,
				}}
			/>
		</PageView>

		// <TabMenuLayout
		// 	context={{
		// 		organization,
		// 		filteredOffers,
		// 		mappedProductionRequests,
		// 		loading,
		// 	}}
		// 	panes={panes}
		// />
	);
}
