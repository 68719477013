import { Outlet, useOutletContext, useParams } from "react-router-dom";

import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

export default function FirestoreDocumentElement({ param, collection }) {
	const params = useParams();
	const context = useOutletContext();

	const [data, loading, error] = useDocumentData(collection.doc(params[param]));

	const nextContext = {
		...context,
		[param || "data"]: data,
	};

	return (
		<LoadingContext data={data} loading={loading} error={error}>
			<Outlet context={{ ...nextContext }} />
		</LoadingContext>
	);
}
