import { Icon, Portal, Segment } from "semantic-ui-react";

import React from "react";
import { useParams } from "react-router-dom";

import { setDiscussion, useDiscussion } from "../../../../discussion/state/discussion";
import DiscussionsView from "../../../../discussion/views/DiscussionsView/DiscussionsView";

export default function DiscussionPortal() {
	const { isOpen } = useDiscussion();
	const { productionId } = useParams();

	return (
		<Portal
			closeOnTriggerClick
			openOnTriggerClick
			open={isOpen}
			trigger={
				<div>
					<Icon
						link
						fitted
						name="comment outline"
						onClick={() =>
							setDiscussion(({ isOpen, channelId }) => ({ isOpen: !isOpen, channelId, productionId }))
						}
						style={{ width: 20 }}
					/>
				</div>
			}
		>
			<Segment
				raised
				style={{
					position: "fixed",
					right: 15,
					top: 32,
					zIndex: 1000,
					padding: 0,
				}}
			>
				<DiscussionsView />
			</Segment>
		</Portal>
	);
}
