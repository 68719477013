import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";
import { OrganizationRef } from "astrid-firestore/src/api/organizations/types/OrganizationRef";
import { ProductionRef } from "astrid-firestore/src/api/productions/types/Production";

import getDocumentData from "../../src/utils/getDocumentData";

import createChannels from "./createChannels";
import createCover from "./createCover";
import createDocumentIdAndRef from "./createDocumentIdAndRef";
import createStorageFile from "./createStorageFile";

async function createEbookArtifact({ api, env }, { production, article }) {
	const bucketName = env === "live" ? "earselect-static" : "stage-earselect-static";
	const [metadata] = await api[env].getStorageMetadata(bucketName, production.deliveryEbook.path);

	const epub = await createStorageFile({
		file: {
			name: `${production.deliveryEbook.isbn || production.title}.epub`,
			fullPath: production.deliveryEbook.path,
			url: production.deliveryEbook.url?.length ? production.deliveryEbook.url[0] : production.deliveryEbook.url, // for some reason the url is sometimes in an array
			size: Number(metadata.size),
		},
		metadata,
	});

	const { id, ref } = createDocumentIdAndRef({ api, env }, { collection: "artifacts" });

	return {
		id,
		ref,
		article,
		files: [epub],
		name: production.title,
		producer: OrganizationRef.parse(article.producer),
		production: ProductionRef.parse(article.production),
		publisher: OrganizationRef.parse(article.publisher),
		size: epub.size,
	};
}

export default async function createEbook({ api, env }, { production, publisher, commonArticleData }) {
	const { id, ref } = createDocumentIdAndRef({ api, env }, { collection: "articles" });
	const article = {
		id,
		ref,
		type: articleTypes.EBOOK,
		name: production.title,
		isbn: production.deliveryEbook.isbn,
		synopsis: production.deliveryEbook?.synopsis || production.synopsis || null,
		...commonArticleData,
		pages: null, // no support for pages for ebook in old system
	};
	const cover = await createCover(
		{ api, env },
		{
			img: production.deliveryEbook.img,
			imgThumb: production.deliveryEbook.imgThumb,
		},
	);
	const fullPublisherDoc = await getDocumentData(api[env].db.collection("organizations").doc(publisher.id));
	const artifact = await createEbookArtifact({ api, env }, { production, article });
	const channels = await createChannels(
		{ api, env },
		{ article, fullPublisherDoc, production, articleType: "ebook" },
	);
	return {
		...article,
		artifact,
		cover,
		channels,
		channelIds: Object.keys(channels),
	};
}
