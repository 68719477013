import React from "react";
import { useTranslation } from "react-i18next";

import DistributionCountriesCheckboxTable from "../../../../distribution/components/DistributionCountriesCheckboxTable/DistributionCountriesCheckboxTable";
import DistributionPriceForm from "../../../../distribution/components/DistributionPrice/DistributionPriceForm";
import Form from "../../../../forms/components/Form/Form";

export default function AdminArticleDistributionTargetDataForm({ name, channel, publisher }) {
	const { t } = useTranslation();

	const key = name ? `${name}.` : "";

	return (
		<>
			{channel?.subChannels?.length > 0 && (
				<Form.DocumentSelect
					multiple
					name={`${key}subChannels`}
					label={t("subChannels", "Sub channels")}
					options={channel.subChannels.map((subChannel) => ({
						key: subChannel.id,
						value: subChannel.id,
						text: subChannel.name,
						data: subChannel,
					}))}
				/>
			)}

			<DistributionPriceForm name={`${key}prices`} publisher={publisher} />

			<DistributionCountriesCheckboxTable name={`${key}countries`} />
		</>
	);
}
