import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import AdminDistribution from "../../admin/pages/AdminDistribution/AdminDistribution";
import AdminDistributionPipeline from "../../admin/pages/AdminDistribution/AdminDistributionPipeline";
import Distributions from "../../distribution/pages/Distributions";

export const distribution = (
	<Route
		path=":distribution"
		element={<AdminDistribution />}
		handle={{ crumb: ({ distribution }) => distribution?.id }}
	>
		<Route index element={<AdminDistributionPipeline />} />
	</Route>
);

export const distributions = (
	<Route
		path="distributions"
		element={<ForwardContext />}
		handle={{ crumb: ({ t }) => t("distributions", "Distributions") }}
	>
		<Route index element={<Distributions />} />

		{distribution}
	</Route>
);
