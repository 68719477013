import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { array, nullable, string, timestamp } from "../../../types/_types";

import { UserRef } from "../../users/types/User";

export const DiscussionChannel = FirestoreDocument.extend({
	name: string(3),
	userIds: array(string()),
	users: array(UserRef),
	teamRoles: nullable(array(string(3))),
	subject: nullable(
		FirestoreDocument.extend({
			name: string(3),
		}),
	),
	seenByUserIds: array(string(3)),
	lastComment: nullable(
		FirestoreDocument.extend({
			text: string(),
			user: UserRef,
			created: timestamp(),
		}),
	),
}).merge(FirestoreLifecycle);
