import { Header, Label } from "semantic-ui-react";

import React from "react";

import Flex from "astrid-components/lib/components/Layout/Flex";

import { useProfile } from "../../../../authentication/state/profile";
import DiscussionChannelDropdownMenu from "../../../../discussion/components/DiscussionChannelDropdownMenu/DiscussionChannelDropdownMenu";
import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";
import truncateString from "../../../../ui/utils/truncateString";

export default function useProductionDiscussionsTableColumns() {
	const user = useProfile();

	return [
		{
			id: "name",
			Cell: ({ row }) => {
				return (
					<Flex justifyContent="flex-start" gap={10}>
						{row.original.users?.slice(0, 2).map(({ id, img }, index, users) => (
							<ImageAvatar
								src={img}
								key={id}
								style={{
									height: users.length === 1 ? 40 : 30,
									width: users.length === 1 ? 40 : 30,
									marginLeft: index === 0 ? 0 : -30,
									marginTop: users.length > 1 && (index === 0 ? -10 : 10),
									border: "2px solid #fff",
								}}
							/>
						))}

						<Header as="h4" style={{ margin: 0 }}>
							{row.original.name}

							{row.original.lastComment && (
								<Header.Subheader style={{ overflow: "hidden" }}>{`${
									row.original.lastComment.user.firstName
								}: ${truncateString(row.original.lastComment.text, 35)}`}</Header.Subheader>
							)}
						</Header>
					</Flex>
				);
			},
		},
		{
			id: "seenByUserIds",
			Cell: ({ value }) => (value.includes(user.id) ? null : <Label circular empty color="blue" size="tiny" />),
			collapsing: true,
		},
		{
			id: "createdAt",
			accessor: ({ created }) => created?.toDate() || "",
			Cell: ({ value }) => new Date(value).toLocaleDateString(),
			collapsing: true,
		},
		{
			id: "actions",
			collapsing: true,
			disableSortBy: true,
			disableClick: true,
			Cell: ({ row }) => {
				return <DiscussionChannelDropdownMenu channel={row.original} />;
			},
		},
	];
}
