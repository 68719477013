import { useState } from "react";
import { useTranslation } from "react-i18next";

import { db } from "astrid-firebase";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";
import DataTable from "../../../ui/components/DataTable/DataTable";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import DiscussionChannelForm from "../../../discussion/components/DiscussionChannelForm/DiscussionChannelForm";
import { setDiscussion } from "../../../discussion/state/discussion";

import useProductionDiscussionsTableColumns from "./hooks/useProductionDiscussionsTableColumns";

export default function ProductionDiscussions({ production }) {
	const { t } = useTranslation();

	const [openCreateDiscussionChannelModal, setOpenCreateDiscussionChannelModal] = useState(false);

	const [discussionChannels, loading, error] = useCollectionData(
		db.collection("discussionChannels").where("subject.id", "==", production.id).where("deleted", "==", false),
	);

	const columns = useProductionDiscussionsTableColumns();

	return (
		<>
			<LoadingContext data={discussionChannels} loading={loading} error={error}>
				<DataTable
					header={false}
					columns={columns}
					data={discussionChannels}
					onClick={(channel) =>
						setDiscussion({ isOpen: true, channelId: channel.id, productionId: production.id })
					}
					footer={
						<PrimaryButton onClick={() => setOpenCreateDiscussionChannelModal(true)}>
							{t("createChannel", "Create channel")}
						</PrimaryButton>
					}
				/>
			</LoadingContext>

			{openCreateDiscussionChannelModal && (
				<DiscussionChannelForm
					production={production}
					onClose={() => setOpenCreateDiscussionChannelModal(false)}
				/>
			)}
		</>
	);
}
