import setDocument from "../../utils/setDocument";
import updateDocumentData from "../../utils/updateDocumentData";

import { Artifact } from "./types/Artifact";

export default function updateArtifact(firebase, data) {
	if (data.files) {
		data.size = data.files.reduce((sum, file) => sum + (file.size || 0), 0);
	}

	const artifact = Artifact.partial().parse(updateDocumentData(firebase, data));

	return setDocument(artifact);
}
