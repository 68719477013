import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import useTemp from "astrid-hooks/src/useTemp";

export default function ArtifactAudioPlayerMarker({ marker, index, onChange }) {
	const { t } = useTranslation();

	const [position, setPosition] = useTemp(marker.position);

	// const onClick = useCallback(() => {
	// 	selectMarker(marker.id, true);
	// 	jumpToMarker(marker.id);
	// }, [marker.id]);

	const onMove = useCallback(
		(position) => {
			setPosition(position);
		},
		[setPosition],
	);
	return (
		<Timeline.Marker
			active
			color="positive"
			position={position}
			onMove={onMove}
			// onClick={onClick}
			onChange={() => onChange(position)}
		>
			{t("chapter", "Chapter")} {index + 1}
		</Timeline.Marker>
	);
}
