import { Children, cloneElement } from "react";

export default function renderChildren(children, props) {
	if (!children) {
		return null;
	}

	if (Children.count(children) > 0) {
		return Children.map(children, (child) => {
			return cloneElement(child, props);
		});
	}

	if (typeof children === "function") {
		return children(props);
	}

	return null;
}
