import { canDeliverArticleToChannel } from "./deliverArticlesToChannels";
import getArticlePresets from "./getArticlePresets";

export function filterDistributionChannelsByArticle({ publisher, article, channels = [] }) {
	const presets = getArticlePresets({ article, publisher });

	const presetChannelIds = [...new Set(presets?.flatMap(({ channel }) => channel.id) || [])];

	return channels.filter(
		(channel) => presetChannelIds.includes(channel.id) && canDeliverArticleToChannel({ article, channel }),
	);
}

export function filterDistributionChannelsByArticles({ publisher, articles, channels }) {
	return articles.map((article) => ({
		article,
		filteredChannels: filterDistributionChannelsByArticle({ publisher, article, channels }),
	}));
}
