import { useTranslation } from "react-i18next";

import artifactStatuses from "astrid-firestore/src/api/artifacts/constants/artifactStatuses";

export default function useArtifactStatusTranslations() {
	const { t } = useTranslation();

	return {
		[artifactStatuses.PENDING]: t("pending", "Pending"),
		[artifactStatuses.FAILED]: t("failed", "Failed"),
		[artifactStatuses.VALID]: t("valid", "Valid"),
		[artifactStatuses.INVALID]: t("invalid", "Invalid"),
	};
}
