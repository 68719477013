import { useTranslation } from "react-i18next";

import ArticlesCollectionDataTable from "../../../articles/components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminSerieArticlesView({ serie }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("articles", "Articles")}>
			<ArticlesCollectionDataTable serie={serie} sortable sortBy={{ id: "serieNumber", desc: false }} />
		</AdminCard>
	);
}
