const articleDeliveryStatuses = {
	DELIVERY_UNSUPPORTED: "delivery_unsupported",
	DELIVERY_NOT_VALID: "delivery_not_valid",
	DELIVERY_NOT_STARTED: "delivery_not_started",
	DELIVERY_PENDING: "delivery_pending",
	DELIVERY_IN_PROGRESS: "delivery_in_progress",
	DELIVERY_COMPLETED: "delivery_completed",
	DELIVERY_FAILED: "delivery_failed",
	DELIVERY_NEEDED: "delivery_needed",
};

export default articleDeliveryStatuses;
