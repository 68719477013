import firebase from "firebase";

import { useTranslation } from "react-i18next";

import artifactStatuses from "astrid-firestore/src/api/artifacts/constants/artifactStatuses";
import softDeleteDocument from "astrid-firestore/src/api/documents/softDeleteDocument";

import { useProfile } from "../../authentication/state/profile";

export default function useArtifactDropdownMenuOptions({ article }) {
	const { t } = useTranslation();

	const user = useProfile();

	return (artifact) => [
		{
			text: t("validate", "validate"),
			disabled: artifact.status === artifactStatuses.PENDING,
			action: () => {
				return artifact.ref.update({
					status: artifactStatuses.PENDING,
				});
			},
		},
		{
			text: t("delete", "Delete"),
			disabled: article?.artifact?.id === artifact.id,
			confirmText: t("deleteArtifactConfirmation", "Are you sure you want to delete this artifact?"),
			action: () => {
				return softDeleteDocument(firebase, artifact, user);
			},
		},
	];
}
