import mapDocument from "../utils/mapDocument";

export default function runTransaction(firebase, refsOrDocuments = [], callback, transaction) {
	const runCallback = async (transaction) => {
		transaction.getAll = (refsOrDocuments) => {
			return Promise.all(
				refsOrDocuments.map((refOrDocument) => {
					if (Array.isArray(refOrDocument)) {
						return transaction.getAll(refOrDocument);
					}

					if (refOrDocument) {
						return transaction.get(refOrDocument?.ref || refOrDocument).then(mapDocument);
					}
				}),
			);
		};

		const documents = await transaction.getAll(refsOrDocuments);

		return callback(transaction, documents);
	};

	if (transaction) {
		return runCallback(transaction);
	}

	return firebase.firestore().runTransaction(runCallback);
}
