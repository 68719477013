import { Icon } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import Popup from "../../../../ui/components/Popup/Popup";
import { dropdownMenuColumn } from "../../../../ui/utils/columns";

import ArticleDeliveryStatus from "../../ArticleDeliveryStatus/ArticleDeliveryStatus";

import useArticleDistributionChannelDropdownMenuOptions from "./useArticleDistributionChannelDropdownMenuOptions";

export default function useArticleDistributionChannelsTableColumns({ article, presetChannels, setSelectedChannel }) {
	const { t } = useTranslation();

	const dropdownMenuOptions = useArticleDistributionChannelDropdownMenuOptions({ article, setSelectedChannel });

	const presetChannelIds = presetChannels.map(({ id }) => id);

	return [
		{
			id: "name",
			Header: t("channel", "Channel"),
			Cell: ({ row, value }) => {
				if (presetChannelIds.includes(row.original.id)) {
					return value;
				}

				return (
					<span style={{ opacity: 0.5 }}>
						{value}

						<Popup
							trigger={<Icon name="info circle" style={{ marginLeft: 5 }} />}
							content={t("channelDisabled", "This channel is disabled for this article")}
						/>
					</span>
				);
			},
		},
		{
			id: "distribution.announcementDate",
			Header: t("announceAt", "Announce"),
			accessor: ({ id }) =>
				article.channels[id]?.distribution?.announcementDate || article.distribution?.announcementDate,
		},
		{
			id: "distribution.releaseDate",
			Header: t("release", "Release"),
			accessor: ({ id }) => article.channels[id]?.distribution?.releaseDate || article.distribution?.releaseDate,
		},
		{
			id: "distribution.takedownDate",
			Header: t("takedownAt", "Unpublish"),
			accessor: ({ id }) =>
				article.channels[id]?.distribution?.takedownDate || article.distribution?.takedownDate,
		},
		{
			id: "status",
			collapsing: true,
			disableSortBy: true,
			Header: t("status", "Status"),
			Cell: ({ row }) => {
				return <ArticleDeliveryStatus article={article} channel={row.original} />;
			},
		},
		dropdownMenuColumn(dropdownMenuOptions),
	];
}
