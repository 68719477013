import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRole from "../../authorization/hooks/useHasRole";

import HomeAdminView from "../views/HomeAdminView";

export default function HomeAdmin() {
	const hasRole = useHasRole();

	if (!hasRole("owner", "admin")) {
		return <Unauthorized />;
	}

	return <HomeAdminView />;
}
