import React from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import { db } from "astrid-firebase";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import featureFlags from "../../../authorization/constants/featureFlags";

import AdminArtifactView from "../../views/AdminArtifactView/AdminArtifactView";

export default function AdminArtifact() {
	const params = useParams();
	const { organization, article } = useOutletContext();

	const [artifact, loading, error] = useDocumentData(db.collection("artifacts").doc(params.artifact));

	return (
		<LoadingContext data={artifact} loading={loading} error={error} featureFlag={featureFlags.FLATTENING}>
			<AdminArtifactView organization={organization} article={article} artifact={artifact}>
				<Outlet context={{ organization, article, artifact }} />
			</AdminArtifactView>
		</LoadingContext>
	);
}
