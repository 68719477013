import createDocument from "../../utils/createDocument";
import createDocumentData from "../../utils/createDocumentData";

import { DiscussionChannel } from "./types/DiscussionChannel";

export default function createDiscussionChannel(firebase, data) {
	const discussionChannel = DiscussionChannel.parse(createDocumentData(firebase, "discussionChannels", data));

	return createDocument(discussionChannel);
}
