import React from "react";
import { useTranslation } from "react-i18next";

import api from "../../../../api";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";
import { timestampColumn } from "../../../ui/utils/columns";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";

export default function AdminArticleExpiredExportsView({ article }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("expiredExports", "Expired Exports")}>
			<CollectionDataTable
				columns={[
					timestampColumn({ id: "created", Header: t("created", "Created") }),
					timestampColumn({ id: "deletedAt", Header: t("deleted", "Deleted") }),
				]}
				sortable={false}
				hoverRow={false}
				query={api.exports.getQuery({ article, deleted: true })}
			/>
		</AdminCard>
	);
}
