import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import AdminSerie from "../../admin/pages/AdminSerie/AdminSerie";
import AdminSerieArticles from "../../admin/pages/AdminSerie/AdminSerieArticles";
import AdminSeriesMetadata from "../../admin/pages/AdminSerie/AdminSeriesMetadata";
import AdminSeries from "../../admin/pages/AdminSeries";

export const series = (
	<Route path="series" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("series", "Series") }}>
		<Route index element={<AdminSeries />} />

		<Route path=":serie" element={<AdminSerie />} handle={{ crumb: ({ serie }) => serie?.name }}>
			<Route index element={<AdminSerieArticles />} />

			<Route path="metadata" element={<AdminSeriesMetadata />} />
		</Route>
	</Route>
);
