import { useTranslation } from "react-i18next";

import Flex from "../../../../../components/Flex/Flex";
import renderChildren from "../../../../../utils/renderChildren";

export default function TableFooter({ table, selectable, children }) {
	const { t } = useTranslation();

	const { selectedFlatRows } = table;

	const selectedRows = selectedFlatRows?.map(({ original }) => original);

	return (
		<Flex justifyContent="flex-start" gap={10} wrap>
			{renderChildren(children, {
				...table,
				...children?.props,
				selectedRows,
			})}

			{selectable && (
				<div style={{ marginLeft: "auto" }}>
					{t("amountOfRowsSelected", "{{amount}} row(s) selected", {
						amount: selectedRows?.length,
					})}
				</div>
			)}
		</Flex>
	);
}
