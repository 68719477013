import { Dropdown, Icon } from "semantic-ui-react";

import React, { useState } from "react";

import AlertModal from "../AlertModal/AlertModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

import DropdownMenuItem from "./DropdownMenuItem";

export default function DropdownMenu({
	icon = "ellipsis vertical",
	direction = "left",
	loading: initialLoading = false,
	children,
	text,
	options,
	size,
	fitted,
	iconStyle,
	...props
}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [confirm, setConfirm] = useState(null);

	const isLoading = loading || initialLoading;

	return (
		<>
			<Dropdown
				floating
				scrolling
				icon={text ? icon : false}
				direction={direction}
				trigger={
					!text &&
					(typeof icon === "string" ? (
						<Icon
							style={iconStyle}
							size={size}
							fitted={fitted}
							loading={isLoading}
							name={isLoading ? "spinner" : icon}
							onClick={(e) => e.preventDefault()}
						/>
					) : (
						icon
					))
				}
				text={
					text && (
						<div
							style={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{text}
						</div>
					)
				}
				{...props}
			>
				<Dropdown.Menu style={{ maxHeight: "80vh" }}>
					{typeof children === "function" ? children({ setLoading, setError }) : children}

					{options
						?.filter((option) => option && !option.hidden)
						.map(({ type, action, onClick, confirmText, ...props }, index) => {
							if (type === "divider") {
								return <Dropdown.Divider key={index} />;
							}

							const clickHandler = action
								? async () => {
										setLoading(true);
										setError(null);

										try {
											await action();
										} catch (error) {
											console.error(error);
											setError(error);
										}

										setLoading(false);
								  }
								: onClick;

							return (
								<DropdownMenuItem
									key={index}
									onClick={
										confirmText
											? () =>
													setConfirm({
														text: confirmText,
														onConfirm: clickHandler,
													})
											: clickHandler
									}
									{...props}
								/>
							);
						})}
				</Dropdown.Menu>
			</Dropdown>

			{error && <AlertModal text={error.message || error} onClose={() => setError(null)} />}

			{confirm && <ConfirmationModal {...confirm} onClose={() => setConfirm(null)} />}
		</>
	);
}
