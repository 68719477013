import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { AudioMp3StorageFile, AudioWavStorageFile, EpubStorageFile } from "../../../types/StorageFile";
import {
	array,
	arrayMinMax,
	boolean,
	enumValues,
	nullable,
	number,
	object,
	string,
	union,
} from "../../../types/_types";

import { ArticleRef } from "../../articles/types/ArticleRef";
import { OrganizationRef } from "../../organizations/types/OrganizationRef";
import { ProductionRef } from "../../productions/types/Production";

import artifactMarkerTypes from "../constants/artifactMarkerTypes";
import artifactStatuses from "../constants/artifactStatuses";

export const artifactStatus = enumValues(artifactStatuses);
export const artifactMarkerType = enumValues(artifactMarkerTypes);

export const artifactMarker = object({
	id: number(),
	position: number(),
	text: string(),
	type: artifactMarkerType,
});

export const Artifact = FirestoreDocument.extend({
	article: ArticleRef,
	files: arrayMinMax(union([AudioWavStorageFile, AudioMp3StorageFile, EpubStorageFile]), 1),
	readFormat: boolean(true),
	markers: array(artifactMarker),
	name: string(3),
	producer: nullable(OrganizationRef),
	production: nullable(ProductionRef),
	publisher: nullable(OrganizationRef),
	size: number().default(0),
	status: artifactStatus.default(artifactStatuses.PENDING),
}).merge(FirestoreLifecycle);
