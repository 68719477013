import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { db } from "astrid-firebase";
import { useDocumentData } from "astrid-firestore/src/hooks";

import api from "../../../../api";
import ConfirmationModal from "../../../ui/components/ConfirmationModal/ConfirmationModal";
import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";

import useDiscussionChannelDropdownMenuOptions from "../../hooks/useDiscussionChannelDropdownMenuOptions";

import DiscussionChannelForm from "../DiscussionChannelForm/DiscussionChannelForm";

export default function DiscussionChannelDropdownMenu({ channel }) {
	const { t } = useTranslation();

	const [production] = useDocumentData(db.collection("productions").doc(channel.subject.id));

	const [loadingRemove, setLoadingRemove] = useState(false);
	const [openEditChannelModal, setOpenEditChannelModal] = useState(false);
	const [openRemoveChannelModal, setOpenRemoveChannelModal] = useState(false);

	const options = useDiscussionChannelDropdownMenuOptions({
		setOpenEditChannelModal,
		setOpenRemoveChannelModal,
		loadingRemove,
		productionId: channel.subject.id,
	});

	const onRemoveChannel = async () => {
		setLoadingRemove(true);
		try {
			await api.discussionChannels.delete(channel);
		} catch (error) {
			console.error(error);
		}
		setLoadingRemove(false);
	};

	return (
		<>
			<DropdownMenu options={options} />

			{openEditChannelModal && (
				<DiscussionChannelForm
					open={openEditChannelModal}
					onClose={() => setOpenEditChannelModal(false)}
					channel={channel}
					production={production}
				/>
			)}

			{openRemoveChannelModal && (
				<ConfirmationModal
					text={t("removeDiscussioChannelConfirmation", "Are you sure you want to remove {{channelName}}?", {
						channelName: channel.name,
					})}
					onConfirm={onRemoveChannel}
					onClose={() => setOpenRemoveChannelModal(false)}
					loading={loadingRemove}
				/>
			)}
		</>
	);
}
