import { Image as SemanticImage } from "semantic-ui-react";

import { useEffect, useState } from "react";

export default function ImageAvatar({ src, style, size = "mini", ...props }) {
	const [internalSrc, setInternalSrc] = useState("/img/avatar.png");

	useEffect(() => {
		if (src) {
			const img = new Image();
			img.onload = () => setInternalSrc(src);
			img.src = src;
		} else {
			setInternalSrc("/img/avatar.png");
		}
	}, [src]);

	return <SemanticImage src={internalSrc} style={{ margin: 0, ...style }} size={size} avatar {...props} />;
}
