import { Icon, Menu } from "semantic-ui-react";

import { useState } from "react";
import { useClearRefinements } from "react-instantsearch";

import Flex from "../../../../components/Flex/Flex";

import InstantSearchRefinementDateRange from "./InstantSearchRefinementDateRange";
import InstantSearchRefinementList from "./InstantSearchRefinementList";

function RefinementSwitch({ type, ...props }) {
	switch (type) {
		case "dateRange":
			return <InstantSearchRefinementDateRange {...props} />;
		case "list":
		default:
			return <InstantSearchRefinementList {...props} />;
	}
}

export default function InstantSearchRefinement({ header, attribute, collapsed = false, ...props }) {
	const [isCollapsed, setIsCollapsed] = useState(collapsed);

	const { refine: clear, canRefine: canClear } = useClearRefinements({
		includedAttributes: [attribute],
	});

	const active = !isCollapsed || canClear;

	const onClickHeader = () => {
		if (canClear) {
			clear();
			setIsCollapsed(true);
		} else {
			setIsCollapsed((isCollapsed) => !isCollapsed);
		}
	};

	return (
		<>
			<Menu.Item active={active} onClick={onClickHeader}>
				<Flex gap={5}>
					{header}

					{canClear && <Icon fitted name="x" onClick={clear} />}
				</Flex>
			</Menu.Item>

			{active && (
				<Menu.Menu
					style={{
						paddingLeft: 15,
						borderLeft: "1px solid rgba(0, 0, 0, 0.08)",
					}}
				>
					<RefinementSwitch attribute={attribute} {...props} />
				</Menu.Menu>
			)}
		</>
	);
}
