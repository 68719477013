import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Serie } from "astrid-firestore/src/api/series/types/Serie";

import api from "../../../../api";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateSerieForm({ organization, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { form, onSubmit, error } = useSimpleForm({
		schema: Serie,
		defaultValues: {
			name: "",
			description: "",
			publisher: organization,
		},
		onSubmit: async (data) => {
			return api.series.create(data);
		},
		onSuccess: ({ id }) => navigate(id),
	});

	return (
		<ModalPrompt
			size="tiny"
			header={t("createSeries", "Create series")}
			error={error}
			onSave={onSubmit}
			onClose={onClose}
			closeOnConfirm={false}
		>
			<Form form={form}>
				<Form.Input name="name" label={t("name", "Name")} />
				<Form.TextArea name="description" label={t("description", "Description")} />
			</Form>
		</ModalPrompt>
	);
}
