import { useTranslation } from "react-i18next";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";

import ArticleVersionsCollectionDataTable from "../../components/ArticleVersionsCollectionDataTable/ArticleVersionsCollectionDataTable";

export default function AdminArticleVersionsView({ article }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("versions", "Versions")}>
			<ArticleVersionsCollectionDataTable article={article} />
		</AdminCard>
	);
}
