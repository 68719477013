import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import ArtifactsCollectionDataTable from "../../../artifacts/components/ArtifactsCollectionDataTable/ArtifactsCollectionDataTable";
import CreateArtifactButton from "../../../artifacts/components/CreateArtifactForm/CreateArtifactButton";

export default function AdminArticleArtifactsView({ article, organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const defaultName = `${article.name}${
		article.artifactIds?.length > 0 ? ` (${article.artifactIds.length + 1})` : ""
	}`;

	return (
		<AdminCard
			header={t("artifacts", "Artifacts")}
			extra={<CreateArtifactButton article={article} publisher={organization} defaultName={defaultName} />}
		>
			<ArtifactsCollectionDataTable article={article} onClick={({ id }) => navigate(id)} />
		</AdminCard>
	);
}
