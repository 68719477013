import { useNavigate } from "react-router-dom";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";

import useSeriesTableColumns from "../../hooks/useSeriesTableColumns";

export default function SeriesInstantSearchDataTable({ publisher, ...props }) {
	const navigate = useNavigate();

	const columns = useSeriesTableColumns();

	return (
		<InstantSearchDataTable
			indexName="series"
			columns={columns}
			configure={{
				filters: publisher && `publisher.id:${publisher.id}`,
			}}
			onClick={({ id }) => navigate(id)}
			{...props}
		/>
	);
}
