import { Outlet, useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import OrganizationSettingsView from "../views/OrganizationSettingsView/OrganizationSettingsView";

export default function OrganizationSettings() {
	const hasRight = useHasRight();

	const { organization } = useOutletContext();

	if (!hasRight("admin")) {
		return <Unauthorized />;
	}

	return (
		<OrganizationSettingsView organization={organization}>
			<Outlet context={{ organization }} />
		</OrganizationSettingsView>
	);
}
