import React from "react";

import Select from "../../../ui/components/Select/Select";

import useTeamRoleOptions from "../../hooks/useTeamRoleOptions";

export default function TeamRoleSelect({ includeReader, ...props }) {
	const options = useTeamRoleOptions({ includeReader });

	return <Select options={options} {...props} />;
}
