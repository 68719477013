export default function getCellStyle(column, style = {}) {
	if (column.sticky) {
		return {
			position: "sticky",
			right: 0,
			left: 0,
			backgroundColor: "#fff",
			zIndex: 999,
			borderRight: column.totalLeft === 0 ? "1px solid rgba(0,0,0,0.08)" : "none",
			borderLeft: column.totalLeft !== 0 ? "1px solid rgba(0,0,0,0.08)" : "none",
			...style,
		};
	}

	return style;
}
