import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import AdminExport from "../../admin/pages/AdminExport/AdminExport";
import AdminExportPipeline from "../../admin/pages/AdminExport/AdminExportPipeline";
import ArticleExports from "../../articles/pages/ArticleExports";

export const exports = (
	<>
		<Route path="exports" element={<ArticleExports />} handle={{ crumb: ({ t }) => t("exports", "Exports") }} />

		<Route path="exports" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("exports", "Exports") }}>
			<Route
				path=":export"
				element={<AdminExport />}
				handle={{ crumb: ({ export: theExport }) => theExport?.id }}
			>
				<Route index element={<AdminExportPipeline />} />
			</Route>
		</Route>
	</>
);
