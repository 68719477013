import React from "react";

import Section from "../../ui/components/Section/Section";

import Dashboard from "../../dashboard/components/Dashboard/Dashboard";
import useSuperAdminDashboardStatistics from "../../superadmin/hooks/useSuperAdminDashboardStatistics";

const maxWidth = 1000;

export default function HomeAdminView() {
	const statistics = useSuperAdminDashboardStatistics();

	return (
		<>
			<Section maxWidth={maxWidth}>
				<Dashboard statistics={statistics} />
			</Section>
		</>
	);
}
