import DistributeArticlesButton from "../../DistributeArticles/DistributeArticlesButton";

import ArticleDistributionChannelsEditButton from "./ArticleDistributionChannelsEditButton";

export default function ArticleDistributionFooter({
	article,
	channels,
	publisher,
	rows,
	selectedFlatRows,
	toggleAllRowsSelected,
}) {
	const selectedRows = selectedFlatRows.length > 0 ? selectedFlatRows : rows;
	const selectedChannels = selectedRows.map(({ original }) => original);

	const onSuccess = () => {
		toggleAllRowsSelected(false);
	};

	return (
		<>
			<ArticleDistributionChannelsEditButton article={article} channels={channels} publisher={publisher} />

			<DistributeArticlesButton
				publisher={publisher}
				articles={[article]}
				channels={selectedChannels}
				onSuccess={onSuccess}
			/>
		</>
	);
}
