import { useMemo } from "react";

import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import useTeamRoleTranslations from "./useTeamRoleTranslations";

export default function useTeamRoleOptions({ includeReader }) {
	const roles = useTeamRoleTranslations();

	return useMemo(
		() =>
			Object.entries(roles)
				.filter(([key]) => includeReader || key !== teamRoles.READER)
				.map(([key, text]) => ({
					key,
					text,
					value: key,
				})),
		[includeReader, roles],
	);
}
