import firebase from "firebase";

import { Segment } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";

import { db } from "astrid-firebase";
import { useCollectionData, useDocumentData, useLoadingValues } from "astrid-firestore/src/hooks";

import DocTitle from "../../../../components/DocTitle";
import AlertToggle from "../../../../components/production/AlertToggle";
import StarToggle from "../../../../components/production/StarToggle";
import MigrateProduction from "../../../../components/production/parts/MigrateProduction";
import HistoryModal from "../../../../components/production/parts/ProductionHistory";
import ProductionInformationEdit from "../../../../components/production/parts/ProductionInformationEdit";
import { deleteProduction } from "../../../../helpers/production";
import ConfirmationModal from "../../../ui/components/ConfirmationModal/ConfirmationModal";
import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import PageView from "../../../ui/views/PageView/PageView";

import { useProfile } from "../../../authentication/state/profile";
import useOffersQuery from "../../../offers/hooks/useOffersQuery";

import useProductionPermissions from "../../hooks/useProductionPermissions";
import updateProduction from "../../utils/updateProduction";

import ProductionViewContext from "./context/ProductionViewContext";
import useProductionDropdownMenuOptions from "./hooks/useProductionDropdownMenuOptions";
import useProductionMenuOptions from "./hooks/useProductionMenuOptions";
import useProductionMetaGuide from "./hooks/useProductionMetaGuide";

export default function ProductionView({ modal, history, production, children }) {
	const user = useProfile();
	const navigate = useNavigate();

	const { t } = useTranslation();
	const { width } = useWindowSize();

	const [isDeleting, setIsDeleting] = useState(false);
	const [historyOpen, setHistoryOpen] = useState(false);

	const isDesktop = width > 1000;

	const [[producer, publisher, offers, discussionChannels], loading, error] = useLoadingValues(
		useDocumentData(production?.producer && db.collection("organizations").doc(production.producer)),
		useDocumentData(production?.publisher && db.collection("organizations").doc(production.publisher)),
		useCollectionData(useOffersQuery({ production })),
		useCollectionData(
			db.collection("discussionChannels").where("subject.id", "==", production.id).where("deleted", "==", false),
		),
	);

	const permissions = useProductionPermissions(production);

	const handleChange = (e, data, options) => updateProduction(e, production, data, options);

	const alerts = useProductionMetaGuide({ history, production });
	const menuOptions = useProductionMenuOptions({ production, offers, alerts, discussionChannels });
	const dropdownMenuOptions = useProductionDropdownMenuOptions({ production, setHistoryOpen, setIsDeleting });

	return (
		<LoadingContext data={production} loading={loading} error={error}>
			{production && (
				<PageView
					header={production.title}
					subheader={`${production.publisherName} + ${production.producerName}`}
					extra={
						<>
							<StarToggle production={production.id} profile={user} uid={user?.id} />

							<AlertToggle production={production} profile={user} uid={user?.id} />

							<DropdownMenu
								style={{ marginRight: "-10px" }}
								loading={loading}
								options={dropdownMenuOptions}
							/>
						</>
					}
					sidebar={<VerticalMenu options={menuOptions} />}
				>
					<DocTitle title={production.title} />

					<div style={{ maxWidth: 1200, margin: "0 auto" }}>
						<ProductionViewContext.Provider
							value={{
								alerts,
								offers,
								producer,
								publisher,
								isDesktop,
								permissions,
								handleChange,
								fullProduction: production,
							}}
						>
							{children}
						</ProductionViewContext.Provider>

						<Segment>
							<MigrateProduction production={production} />
						</Segment>

						{modal === "edit" && (
							<ProductionInformationEdit
								alerts={alerts}
								producer={producer}
								publisher={publisher}
								production={production}
								permissions={permissions}
								handleChange={handleChange}
								onClose={() => navigate(`/production/${production.id}`)}
							/>
						)}
					</div>
				</PageView>
			)}

			{isDeleting && (
				<ConfirmationModal
					header={t("deleteProductionHeader", "Delete production")}
					content={t("deleteProduction", "Are you sure you wish to delete this production?")}
					onClose={() => setIsDeleting(false)}
					onConfirm={() => {
						deleteProduction(production.id, firebase.auth().currentUser.uid);
						setIsDeleting(false);
					}}
				/>
			)}

			{historyOpen && (
				<HistoryModal
					productionId={production.id}
					onClose={() => {
						setHistoryOpen(false);
					}}
				/>
			)}
		</LoadingContext>
	);
}
