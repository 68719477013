import React from "react";

import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import useBreadcrumbs from "../../../ui/hooks/useBreadcrumbs";
import PageView from "../../../ui/views/PageView/PageView";

import AdminPageDropdownMenu from "../../../admin/components/AdminPage/AdminPageDropdownMenu";

import ArticleCoverImage from "../../components/ArticleCoverImage/ArticleCoverImage";
import ArticleDeliveryStatus from "../../components/ArticleDeliveryStatus/ArticleDeliveryStatus";
import useArticleTypeTranslations from "../../hooks/useArticleTypeTranslations";

import useAdminArticleDropdownMenuOptions from "./hooks/useAdminArticleDropdownMenuOptions";
import useAdminArticleMenuOptions from "./hooks/useAdminArticleMenuOptions";

export default function AdminArticleView({ article, children }) {
	const breadcrumbs = useBreadcrumbs({ article });
	const options = useAdminArticleMenuOptions({ article });
	const articleTypeTranslations = useArticleTypeTranslations();

	const { input, loading, options: dropdownMenuOptions } = useAdminArticleDropdownMenuOptions({ article });

	return (
		<PageView
			breadcrumbs={breadcrumbs}
			image={
				<ArticleCoverImage
					thumb
					rounded
					article={article}
					style={{ width: 80, height: 80 }}
					loading={loading}
				/>
			}
			header={article.name}
			subheader={`${article.publisher.name} - ${articleTypeTranslations[article.type]} (${article.isbn})`}
			sidebar={<VerticalMenu options={options} />}
			extra={
				<>
					<ArticleDeliveryStatus article={article} />
					<AdminPageDropdownMenu options={dropdownMenuOptions} />
				</>
			}
		>
			{children}

			{input}
		</PageView>
	);
}
