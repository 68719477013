import { parsePhoneNumber } from "awesome-phonenumber";

import { useTranslation } from "react-i18next";

import ImageAvatar from "../../ui/components/ImageAvatar/ImageAvatar";

import useUserRoles from "./useUserRoles";

export default function useUsersTableColumns({ organization } = {}) {
	const { t } = useTranslation();

	const roles = useUserRoles();

	return [
		{
			id: "name",
			Header: t("name"),
			accessor: ({ name }) => name,
			Cell: ({ value, row }) => (
				<>
					<ImageAvatar src={row.original.avatarImg} style={{ marginRight: "1em" }} />
					<span>{value}</span>
				</>
			),
		},
		{
			id: "email",
			Header: t("email"),
		},
		{
			id: "phone",
			Header: t("telephone"),
			accessor: ({ phone }) => phone && parsePhoneNumber(phone).getNumber("international"),
		},
		{
			id: "roles",
			Header: t("role", "Role"),
			accessor: ({ permissions }) => {
				if (organization) {
					return (
						(permissions?.[organization.type]?.[organization.id] || [])
							.filter((role) => role !== "any")
							.map((role) => roles[role])
							.join(", ") || t("none", "None")
					);
				}

				return roles[permissions?.role];
			},
		},
	];
}
