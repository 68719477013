import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { Organization } from "astrid-firestore/src/api/organizations/types/Organization";
import { updateDocument } from "astrid-firestore/src/helpers/index";

import AdminForm from "../../../admin/components/AdminForm/AdminForm";
import publisherFeatureFlags from "../../../authorization/constants/publisherFeatureFlags";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function PublisherSettings({ organization }) {
	const { t } = useTranslation();
	const form = useSimpleForm({
		schema: Organization,
		defaultValues: {
			featureFlags: organization.featureFlags || [],
		},
		onSubmit: async ({ featureFlags }) => {
			await updateDocument(organization.ref, { featureFlags });
		},
	});

	return (
		<AdminForm header={t("settings", "Settings")} {...form}>
			<Form.Select
				name="featureFlags"
				label="Feature flags"
				options={[
					{
						key: publisherFeatureFlags.PUBLISHER_MIGRATED,
						value: publisherFeatureFlags.PUBLISHER_MIGRATED,
						text: "Publisher migrated",
					},
				]}
				rules={{ required: false }}
				multiple
			/>
		</AdminForm>
	);
}
