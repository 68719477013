import { Card } from "semantic-ui-react";

import React from "react";

import ArtifactAudioPlayer from "../../../artifacts/components/ArtifactAudioPlayer/ArtifactAudioPlayer";
import ManageArtifactFiles from "../../../artifacts/components/ManageArtifactFiles/ManageArtifactFiles";

import ArtifactFilesStatistics from "./components/ArtifactFilesStatistics";

export default function AdminArtifactFilesView({ artifact }) {
	const hasAudio = artifact.files.some((file) => file.contentType.includes("audio"));

	return (
		<Card fluid>
			<Card.Content>
				<ArtifactFilesStatistics artifact={artifact} />
			</Card.Content>

			{hasAudio && (
				<Card.Content style={{ padding: 0 }}>
					<ArtifactAudioPlayer artifact={artifact} />
				</Card.Content>
			)}

			<Card.Content>
				<ManageArtifactFiles artifact={artifact} />
			</Card.Content>
		</Card>
	);
}
