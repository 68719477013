import { db } from "astrid-firebase";

import getCollectionData from "../../utils/getCollectionData";
import runTransaction from "../../utils/runTransaction";

import { Serie } from "./types/Serie";

export default async function updateSerie(firebase, { ref, name, description, seasons }) {
	return runTransaction(firebase, [ref], async (transaction, [serie]) => {
		transaction.update(serie.ref, Serie.partial().parse({ name, description, seasons }), { merge: true });

		if (serie.name !== name) {
			for (const articleId of serie.articleIds) {
				transaction.update(db.collection("articles").doc(articleId), { ["serie.name"]: name });
			}
		}

		const existingSeasons = serie.seasons;

		for (const existingSeason of existingSeasons) {
			const updatedSeasonName = seasons.find(
				(s) => s.id === existingSeason.id && s.name !== existingSeason.name,
			)?.name;
			const removedSeason = !seasons.find((s) => s.id === existingSeason.id);

			if (updatedSeasonName || removedSeason) {
				const articlesToUpdate = await getCollectionData(
					db.collection("articles").where("season.id", "==", existingSeason.id),
				);

				if (updatedSeasonName) {
					for (const article of articlesToUpdate) {
						transaction.update(article.ref, { ["season.name"]: updatedSeasonName });
					}
				}

				if (removedSeason) {
					for (const article of articlesToUpdate) {
						transaction.update(article.ref, { season: firebase.firestore.FieldValue.delete() });
					}
				}
			}
		}
	});
}
