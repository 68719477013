import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateStudioForm from "../CreateStudio/CreateStudioForm";

export default function CreateStudioButton({ organization, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("createStudio", "Create studio")} primary {...props}>
			{({ setOpen }) => <CreateStudioForm organization={organization} onClose={() => setOpen(false)} />}
		</OpenButton>
	);
}
