import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import db from "astrid-firebase/src/db";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";
import useSearchInput from "../../../ui/hooks/useSearchInput";
import PageView from "../../../ui/views/PageView/PageView";

import CreateStudioButton from "../../../studios/components/CreateStudio/CreateStudioButton";

import useAdminStudiosColumns from "./hooks/useAdminStudiosColumns";

export default function AdminStudiosView({ organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = db.collection("studios").where("producerId", "==", organization.id);
	const columns = useAdminStudiosColumns();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<PageView
			header={t("studios", "Studios")}
			extra={<CreateStudioButton organization={organization} />}
			sidebar={searchInput}
		>
			<CollectionDataTable
				persist
				filterable={false}
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				searchQuery={searchQuery}
				searchableKeys={["name"]}
				sortBy={{
					id: "name",
					desc: false,
				}}
			/>
		</PageView>
	);
}
