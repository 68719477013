import { Route } from "react-router-dom";

import AdminDistributionSettings from "../../admin/pages/AdminDistributionSettings";
import OrganizationSettingsFinance from "../../admin/pages/OrganizationSettingsFinance";
import OrganizationSettingsLanguage from "../../admin/pages/OrganizationSettingsLanguage";
import OrganizationSettingsVendors from "../../admin/pages/OrganizationSettingsVendors";
import PublisherSettings from "../../distribution/components/DistributionSettings/PublisherSettings";

import OrganizationSettings from "../pages/OrganizationSettings";

export const organizationSettings = (
	<Route path="settings" element={<OrganizationSettings />}>
		<Route index element={<PublisherSettings />} />
		<Route path="distribution" element={<AdminDistributionSettings />} />
		<Route path="vendors" element={<OrganizationSettingsVendors />} />
		<Route path="language" element={<OrganizationSettingsLanguage />} />
		<Route path="finance" element={<OrganizationSettingsFinance />} />
	</Route>
);
