import firebase from "firebase/app";

import { useMemo } from "react";

import getDistributionChannelsQuery from "astrid-firestore/src/api/distribution/getDistributionChannelsQuery";
import { filterDistributionChannelsByArticle } from "astrid-firestore/src/api/distribution/utils/filterDistributionChannels";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";
import useLoadingValues from "astrid-firestore/src/hooks/useLoadingValues";

export default function useArticleDistributionChannels({ article }) {
	const [[publisher, channels], loading, error] = useLoadingValues(
		useDocumentData(article.publisher.ref),
		useCollectionData(getDistributionChannelsQuery(firebase)),
	);

	const presetChannels = useMemo(
		() => filterDistributionChannelsByArticle({ publisher, article, channels }),
		[article, channels, publisher],
	);

	const articleChannels = channels?.filter((channel) => article.channelIds.includes(channel.id));

	return [presetChannels, articleChannels, loading, error];
}
