import React from "react";
import { useOutletContext, useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import ProductionFinanceView from "../views/ProductionFinanceView/ProductionFinanceView";

export default function ProductionFinance() {
	const { partyId } = useParams();
	const { production } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("finance.editProductionPrices")) {
		return <Unauthorized />;
	}

	return <ProductionFinanceView production={production} partyId={partyId} />;
}
