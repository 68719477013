import { Table } from "semantic-ui-react";

import getCellStyle from "../utils/getCellStyle";

export default function TableCell({ cell, onClick, ...props }) {
	const { disableClick } = cell.column;

	return (
		<Table.Cell
			{...props}
			onClick={!disableClick ? onClick : undefined}
			textAlign={cell.column.textAlign || "left"}
			verticalAlign={cell.column.verticalAlign || "middle"}
			collapsing={cell.column.collapsing}
			style={getCellStyle(cell.column)}
		>
			{cell.render("Cell")}
		</Table.Cell>
	);
}
