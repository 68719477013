import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../forms/components/Form/Form";

import useDistributionPriceTypeOptions from "../../hooks/useDistributionPriceTypeOptions";

export default function DistributionPriceForm({ name, publisher }) {
	const { t } = useTranslation();

	const currencyOptions = useMemo(() => {
		return publisher.currencyOptions?.map((option) => ({ key: option, value: option, text: option })) || [];
	}, [publisher.currencyOptions]);

	const priceTypeOptions = useDistributionPriceTypeOptions();

	return (
		<Form.FormFieldArrayTable
			name={name}
			header={t("prices", "Prices")}
			onAppend={() => ({ currency: publisher.currencyDefault })}
		>
			{({ type, currency, amount }, index) => {
				return (
					<Form.Group widths="equal" style={{ margin: 0 }}>
						<Form.Select
							name={`${name}.${index}.type`}
							value={type}
							label={t("priceType", "Price type")}
							options={priceTypeOptions}
						/>
						<Form.Select
							name={`${name}.${index}.currency`}
							value={currency}
							label={t("currency", "Currency")}
							options={currencyOptions}
						/>
						<Form.NumberInput
							name={`${name}.${index}.amount`}
							value={amount}
							label={t("amount", "Amount")}
						/>
					</Form.Group>
				);
			}}
		</Form.FormFieldArrayTable>
	);
}
