import React from "react";
import { useTranslation } from "react-i18next";

import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import useBreadcrumbs from "../../../ui/hooks/useBreadcrumbs";
import PageView from "../../../ui/views/PageView/PageView";

import ArtifactStatusLabel from "../../../artifacts/components/ArtifactStatus/ArtifactStatusLabel";
import useArtifactDropdownMenuOptions from "../../../artifacts/hooks/useArtifactDropdownMenuOptions";

export default function AdminArtifactView({ article, artifact, children }) {
	const { t } = useTranslation();

	const breadcrumbs = useBreadcrumbs({ article, artifact });

	const dropdownMenuOptions = useArtifactDropdownMenuOptions({ article });

	return (
		<PageView
			breadcrumbs={breadcrumbs}
			header={artifact.name}
			subheader={t("amountFiles", "{{amount}} files", { amount: artifact.files.length })}
			extra={
				<>
					<ArtifactStatusLabel artifact={artifact} />

					<DropdownMenu options={dropdownMenuOptions(artifact)} />
				</>
			}
		>
			{artifact.error && <ErrorMessage>{artifact.error}</ErrorMessage>}

			{children}
		</PageView>
	);
}
